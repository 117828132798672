<template lang="pug">
two-columns-layout
  //- Notice
  div.mb-10
    heading Notice
    rich-text-editor(
      v-model="notice"
      @change="formDirty = true")
    primary-button(:isWaiting="isUpdatingNotice" @click="updateNotice") Update
  //- List header
  heading Discussion
  //- List body
  div.text-center(v-if="isLoading")
    loading
  card-list(v-else :items="discussions")
    template(v-slot:default="{item}")
      manage-discussion-card(
        :discussion="item"
        @change="sortPosts()")
  //- Menu panel
  template(v-slot:aside)
    manage-menu
</template>

<script>
import axios from '@/plugins/axios.js'
import { mapMutations } from 'vuex'
import TwoColumnsLayout from '@/layouts/TwoColumnsLayout.vue'
import Heading from '@/components/common/Heading.vue'
import Loading from '@/components/common/Loading.vue'
import ManageMenu from '@/components/menus/ManageMenu.vue'
import CardList from '@/components/lists/CardList.vue'
import ManageDiscussionCard from '@/components/cards/ManageDiscussionCard.vue'
import RichTextEditor from '@/components/form/RichTextEditor.vue'
import PrimaryButton from '@/components/buttons/PrimaryButton.vue'

export default {
  components: {
    TwoColumnsLayout,
    Heading,
    Loading,
    ManageMenu,
    CardList,
    ManageDiscussionCard,
    RichTextEditor,
    PrimaryButton,
  },
  data () {
    return {
      isLoading:        true,
      isUpdatingNotice: false,
      notice:           '',
      discussions:      [],
      formDirty:        false,
    }
  },
  methods: {
    ...mapMutations('feature', ['setNotification']),
    updateNotice () {
      this.isUpdatingNotice = true
      axios.put('/static/discussion', {
        content: this.notice,
      })
        .then(() => {
          this.formDirty = false
          this.setNotification({
            isOpen:  true,
            type:    'success',
            message: 'Notice is updated',
          })
        })
        // If there is any error other than 404, show notification
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
        })
        .finally(() => {
          // Clear loading state
          this.isUpdatingNotice = false
        })
    },
    sortPosts () {
      this.discussions = this.discussions.sort((a, b) => {
        if(a.pinned !== b.pinned) {
          return b.pinned - a.pinned
        }
        else {
          return b.createdAt - a.createdAt
        }
      })
    },
  },
  mounted () {
    // Fetch discussions
    axios.get('/discussions')
      .then(res => {
        this.discussions = res.data.discussions
        this.discussions.forEach(post => {
          post.createdAt = new Date(post.createdAt)
        })
        this.sortPosts()
      })
      // If there is any error other than 404, show notification
      .catch(error => {
        if(error.response.status !== 404) {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
        }
      })
      .finally(() => {
        // Clear loading state
        this.isLoading = false
      })

    axios.get('/static/discussion')
      .then(res => {
        this.notice = res.data
        this.formDirty = false
      })
      // If there is any error other than 404, show notification
      .catch(error => {
        this.setNotification({
          isOpen:  true,
          type:    'error',
          message: error.response.data,
        })
      })
  },
  beforeRouteLeave (to, from, next) {
    if(this.formDirty && !this.confirmRouteChange()) {
      next(false)
    }
    else {
      next()
    }
  },
}
</script>
