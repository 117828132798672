import * as PIXI from 'pixi.js'

class TextButton {
  constructor ({ name = null, text = '', style = {}, handler = () => {} }) {
    const button = new PIXI.Text(text, new PIXI.TextStyle(style))
    button.name = name
    button.interactive = true
    button.buttonMode = true
    button.on('pointerdown', handler)
    return button
  }
}

export default TextButton
