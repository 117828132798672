<template lang="pug">
v-btn.primary-button(
  rounded
  large
  color="#FFD06F"
  :outlined="hollow"
  :style="{'color': hollow ? '#FFD06F' : '#30333F'}"
  :to="to"
  :loading="isWaiting"
  :disabled="isWaiting || disabled"
  @click="$emit('click', $event)")
  slot
</template>

<script>
export default {
  name:  'PrimaryButton',
  props: {
    hollow: {
      type:     Boolean,
      required: false,
      default:  false,
    },
    isWaiting: {
      type:     Boolean,
      required: false,
      default:  false,
    },
    disabled: {
      type:     Boolean,
      required: false,
      default:  false,
    },
    to: {
      type:     String,
      required: false,
      default:  null,
    },
  },
}
</script>

<style lang="scss" scoped>
.primary-button {
  font-size: 16px;
  font-weight: 500;
}
</style>
