<template lang="pug">
one-column-layout
  heading {{ exercise.title }}
  v-form(ref="form")
    template(v-for="(MCQ, index) in exercise.MCQs")
      v-divider.mx-3.my-5(v-if="index > 0")
      MCQ(
        v-model="submission[index]"
        :readonly="answered"
        :mcq="MCQ")
  v-tooltip(v-if="answered" right)
    template(v-slot:activator="{ on, attrs }")
      div.d-inline-block.mt-5(v-bind="attrs" v-on="on")
        primary-button(disabled) Submit
    span You can only submit to this exercise once.
  primary-button.mt-5(
    v-else
    :isWaiting="isJudging"
    @click="submit()") Submit
</template>

<script>
import axios from '@/plugins/axios.js'
import store from '@/store'
import { mapMutations } from 'vuex'
import OneColumnLayout from '@/layouts/OneColumnLayout.vue'
import Loading from '@/components/common/Loading.vue'
import Heading from '@/components/common/Heading.vue'
import MCQ from '@/components/common/MCQ.vue'
import PrimaryButton from '@/components/buttons/PrimaryButton.vue'

export default {
  components: {
    OneColumnLayout,
    Loading,
    Heading,
    MCQ,
    PrimaryButton,
  },
  data () {
    return {
      isJudging: false,
      answered:  false,
      exercise:  {
        id:      '',
        title:   '',
        hidden:  false,
        MCQs:    [],
        chapter: '',
      },
      submission: [],
    }
  },
  methods: {
    ...mapMutations('feature', ['setPageLoading', 'setNotification']),
    setExercise (exercise, error) {
      if (error) {
        this.setNotification({
          isOpen:  true,
          type:    'error',
          message: error,
        })
        return
      }

      this.exercise = exercise
      this.exercise.chapter = this.exercise.chapter.index
      this.exercise.MCQs = this.exercise.MCQs.sort((a, b) => a.index - b.index)

      this.setTitle(`${this.exercise.chapter} - ${this.exercise.title}`)

      axios.get(`/user/exerciseInfo/${this.exercise.id}`)
        .then(res => {
          this.submission = res.data.exerciseInfo.sort((a, b) => a.index - b.index)
          this.answered = true
        })
        // If there is any error, show notification and clear loading state
        .catch(error => {
          if (error.response.data === 'Exercise Info not found') {
            return
          }
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
        })
        .finally(() => {
          this.setPageLoading(false)
        })
    },
    submit () {
      if(!this.$refs.form.validate()) {
        this.setNotification({
          isOpen:  true,
          type:    'error',
          message: 'Please answer all the questions.',
        })
        return
      }

      this.isJudging = true
      // Submit answer
      axios.post(`/exercises/${this.exercise.id}/submit`, {
        answer: this.submission,
      })
        // And request for answer
        .then(() => axios.get(`/exercises/${this.exercise.id}`))
        .then(res => {
          this.exercise = res.data
          this.exercise.chapter = this.exercise.chapter.index
          this.exercise.MCQs = this.exercise.MCQs.sort((a, b) => a.index - b.index)

          this.answered = true
          this.setNotification({
            isOpen:  true,
            type:    'success',
            message: 'Your answer has been submitted.',
          })
        })
        // If there is any error, show notification and clear loading state
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
        })
        .finally(() => {
          this.isJudging = false
        })
    },
  },
  beforeRouteEnter (to, _, next) {
    store.commit('feature/setPageLoading', true)
    // TODO: ask backend to put homework title into exercise
    Promise.all([
      axios.get(`/homework/${to.params.index}`),
      axios.get(`/exercises/${to.params.exerciseId}`),
    ])
      .then(results => {
        const homework = results[0].data
        const exercise = results[1].data

        to.meta.breadcrumbs = to.meta._breadcrumbs.concat([
          {
            text:     `${homework.index} - ${homework.title}`,
            to:       `/homework/${homework.index}`,
            disabled: false,
          },
          {
            text:     exercise.title,
            disabled: true,
          },
        ])
        next(vm => vm.setExercise(exercise, null))
      })
      .catch(error => {
        if(error.response.status === 404) {
          next('/notFound')
        }
        else {
          next(vm => vm.setExercise(null, error.response.data))
        }
      })
  },
  beforeRouteUpdate (to, _, next) {
    this.setPageLoading(true)
    this.answered = false
    this.submission = []

    Promise.all([
      axios.get(`/homework/${to.params.index}`),
      axios.get(`/exercises/${to.params.exerciseId}`),
    ])
      .then(results => {
        const homework = results[0].data
        const exercise = results[1].data

        to.meta.breadcrumbs = to.meta._breadcrumbs.concat([
          {
            text:     `${homework.index} - ${homework.title}`,
            to:       `/homework/${homework.index}`,
            disabled: false,
          },
          {
            text:     exercise.title,
            disabled: true,
          },
        ])
        this.setExercise(exercise, null)
        next()
      })
      .catch(error => {
        if(error.response.status === 404) {
          next('/notFound')
        }
        else {
          this.setExercise(null, error.response.data)
          next()
        }
      })
  },
}
</script>
