<template lang="pug">
floating-card(:to="`/manage/bonus/post/${bonus.id}`")
  v-container.pa-0(fluid)
    v-row(align="center")
      v-col(cols="12" sm="8")
        //- Title
        p.mb-0.text-h6.text-truncate {{ bonus.title }}
      v-spacer
      v-col(cols="12" sm="auto")
        //- Announced date
        span.text-h6 {{ dateToDateString(bonus.createdAt) }}
</template>

<script>
import FloatingCard from '@/components/cards/FloatingCard.vue'

export default {
  name:  'ManageBonusCard',
  props: {
    bonus: {
      type:     Object,
      required: true,
      default:  () => {},
    },
  },
  components: {
    FloatingCard,
  },
}
</script>
