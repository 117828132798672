<template lang="pug">
authentication-layout
  v-form.d-flex.flex-column
    //- Form title
    h2.text-h5.text-md-h4.font-weight-bold.text-center Please enter your new password
    //- Password field
    div.mt-9.d-flex.align-start.align-self-center
      v-icon.mr-5(size="36" color="#B5B5B5") mdi-lock
      v-text-field.mt-0.pt-0(
        v-model="password"
        type="password"
        label="password"
        required
        rounded
        light
        single-line
        height="36"
        background-color="#B5B5B5"
        :error-messages="passwordErrors")
    //- Comfirm password field
    div.mt-5.d-flex.align-start.align-self-center
      v-icon.mr-5(size="36" color="#B5B5B5") mdi-lock-check
      v-text-field.mt-0.pt-0(
        v-model="confirmPassword"
        type="password"
        label="Password Confirmation"
        required
        rounded
        light
        single-line
        height="36"
        background-color="#B5B5B5"
        :error-messages="confirmPasswordErrors"
        @keyup.enter.prevent="resetPassword()")
    //- Sign up Button
    primary-button.mt-8.align-self-center(
      :isWaiting="isWaiting"
      @click="resetPassword()") Reset
</template>

<script>
import axios from '@/plugins/axios.js'
import { mapMutations } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import AuthenticationLayout from '@/layouts/AuthenticationLayout.vue'
import PrimaryButton from '@/components/buttons/PrimaryButton.vue'

export default {
  title:      'Reset your password',
  components: {
    AuthenticationLayout,
    PrimaryButton,
  },
  data () {
    return {
      isWaiting:       false,
      password:        '',
      confirmPassword: '',
    }
  },
  validations: {
    password:        { required },
    confirmPassword: {
      required,
      confirmed (val) {
        return val === this.password
      },
    },
  },
  computed: {
    // Password field error message handling
    passwordErrors () {
      const errors = []
      if (!this.$v.password.$dirty) {
        return errors
      }
      !this.$v.password.required && errors.push('Password is required.')
      return errors
    },
    // Comfirm password field error message handling
    confirmPasswordErrors () {
      const errors = []
      if (!this.$v.confirmPassword.$dirty) {
        return errors
      }
      !this.$v.confirmPassword.confirmed && errors.push('New password confirmation failed.')
      !this.$v.confirmPassword.required && errors.push('Please enter your new password again.')
      return errors
    },
  },
  methods: {
    ...mapMutations('feature', ['setNotification', 'setPopup']),
    async resetPassword () {
      // Ensure there is no invalid field before sending request
      this.$v.$touch()
      if (!this.$v.$anyError) {
        // Disable reset password button
        this.isWaiting = true

        // Send reset password request
        await axios.post('/user/password/reset', {
          token:    this.$route.query.token,
          password: this.password,
        })
          // If successed, show notification and redirect to signin page
          .then(() => {
            this.setNotification({
              isOpen:  true,
              type:    'success',
              message: 'Password reseted! Now please sign in...',
            })
            this.$router.push('/signin').catch(()=>{})
          })
          // If there is any error, show message popup to display error message
          .catch(error => {
            this.setPopup({
              isOpen:  true,
              type:    'error',
              title:   'Error',
              content: error.response.data,
            })
          })
          // Re-enable reset password button
          .finally(() => {
            this.isWaiting = false
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.v-form {
  max-width: 400px;
}
.v-text-field {
  max-width: 300px;
}
</style>
