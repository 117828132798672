<template lang="pug">
div.progress-ring(:style="dimension")
  //- Gradient color background representing finished part
  div.base(:style="{ 'background-image': `linear-gradient(317deg, ${colorSet[color % 3]})`, ...dimension }")
  //- Grey color ring representing unfinished part
  svg.progress(:viewBox="`0 0 ${size} ${size}`" :style="dimension")
    //- Use <path> to draw a circle,
    //- (Reference: https://stackoverflow.com/questions/5737975/circle-drawing-with-svgs-arc-path)
    //- and control visible fraction by strokeDashoffset
    //- (Reference: https://css-tricks.com/svg-line-animation-works/)
    path(
      :d="ringPath"
      :style="{ 'stroke-dashoffset': strokeDashoffset, 'stroke-width': strokeWidth }")
  //- Progress text representing in [0% - 100%]
  div.cover(:style="coverDimension") {{ progressText }}%
</template>

<script>
export default {
  name:  'ProgressRing',
  props: {
    // An integer number indicating which gradient color background to use
    // This number will be convert to a modulo of `colorSet.length` as an index
    // of the array.
    color: {
      type:     Number,
      required: false,
      default:  0,
    },
    // Diameter of the ring
    size: {
      type:     Number,
      required: false,
      default:  140,
    },
    // Accomplishment progress in a number ranging in [0, 1]
    progress: {
      type:     Number,
      required: true,
      default:  0,
    },
  },
  data () {
    return {
      // Current progress of animation in a number ranging in [0, 1]
      animationProgress: 0,
      // Gradient color backgrounds
      colorSet:          [
        '#FF7FC3 0%, #FFC94E 100%',
        '#FFFF65 0%, #A749EF 100%',
        '#FFFF65 0%, #7DED78 50%, #00DC8A 100%',
      ],
      // Increment amount in once animation
      increment:   0,
      // Old value of progress (as animation starting point)
      oldProgress: 0,
      // Stroke width of the ring (in pixel)
      strokeWidth: 8,
      // `setTimeout` handler
      timer:       0,
    }
  },
  computed: {
    // Progress text display at the center of the ring
    // with animation to increase number until match progress
    progressText () {
      return Math.round(100 * (this.oldProgress + (this.increment * this.easeOut())))
    },
    // `stroke-dashoffset` value on 0%, this value is linearly related with ring size.
    // the linear function for this relation is `stroke-dashoffset = (-94 * size + 30710) / 30`.
    startingOffset () {
      return Math.round((-94 * this.size + 30710) / 30)
    },
    // StrokeDashoffset to control visible fraction of a path (read reference above)
    // with animation to increase the fraction until match progress
    strokeDashoffset () {
      // 1000 = 100%, this.startingOffset = 0%
      const range = 1000 - this.startingOffset
      return this.startingOffset + range * this.oldProgress + Math.round(range * this.increment * this.easeOut())
    },
    // The svg path string for the circle with diameter = this.size (including stroke width)
    ringPath () {
      const outerRadius = (this.size >> 1)
      const innerRadius = outerRadius - (this.strokeWidth >> 1)
      return `M ${outerRadius},${outerRadius} ` +
      `m -${innerRadius},0 ` +
      `a ${innerRadius},${innerRadius} 0 1,0 ${innerRadius << 1},0 ` +
      `a ${innerRadius},${innerRadius} 0 1,0 -${innerRadius << 1},0`
    },
    // Width and height of this component
    dimension () {
      return {
        width:  `${this.size}px`,
        height: `${this.size}px`,
      }
    },
    // Width and height and the position of the gray cover to make circle looks like a ring
    // The radius of this cover is slightly larger than the innerRadius in `ringPath`,
    // this is aimed to fully cover the border of dashes (unfinished progress).
    coverDimension () {
      return {
        top:    `${this.strokeWidth - 1}px`,
        left:   `${this.strokeWidth - 1}px`,
        width:  `${this.size - (this.strokeWidth - 1) * 2}px`,
        height: `${this.size - (this.strokeWidth - 1) * 2}px`,
      }
    },
  },
  watch: {
    // Restart animation when progress is updated
    progress (newValue, oldValue) {
      // Reset animation progress
      this.animationProgress = 0

      // Initialize new animation argument
      this.oldProgress = oldValue
      this.increment = newValue - oldValue

      // Cancel old animation and start a new one
      clearTimeout(this.timer)
      this.timer = setTimeout(this.tick, 20)
    },
  },
  methods: {
    // Ease out time function for animation
    easeOut () {
      return (1 - Math.cos(this.animationProgress * Math.PI)) / 2
    },
    // Function to acquire new frame until animation finish (animationProgress = 1)
    tick () {
      if (this.animationProgress < 1) {
        this.animationProgress += 0.01
        this.timer = setTimeout(this.tick, 20)
      }
    },
  },
  mounted () {
    // Start animation on component mounted
    this.increment = this.progress
    this.timer = setTimeout(this.tick, 20)
  },
}
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Acme&display=swap");

.progress-ring {
  position: relative;
  .base {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
  }

  .cover {
    display: grid;
    justify-content: center;
    align-content: center;
    font-family: "Acme", sans-serif;
    font-size: 30px;
    letter-spacing: 2.4px;
    position: absolute;
    border-radius: 50%;
    background-color: #30333f;
  }

  .progress {
    transform: rotate(90deg);
    position: absolute;
    top: 0;
    left: 0;

    path {
      fill: none;
      stroke: #b5b5b5;
      stroke-dasharray: 1000;
    }
  }
}
</style>
