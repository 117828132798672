import axios from '@/plugins/axios.js'

const state = {
  username:   '',
  email:      '',
  avatar:     '',
  studentId:  '',
  permission: -1,
  progress:   null,
  games:      [],
}

const getters = {
  isSignin (state) {
    return !!state.username
  },
  isAdmin (state) {
    return state.permission === 0
  },
  score () {
    // TODO: implement a score calculating logic
    return 0
  },
  chapterProgress (_p1, _p2, _p3, rootGetters) {
    return chapterIndex => {
      const chapterProblems = rootGetters['problem/chapterProblems'](chapterIndex)
      if(chapterProblems.length === 0) {
        return 1
      }
      return (chapterProblems.reduce((sum, problem) => sum + problem.score, 0)
        / (chapterProblems.length * 100)) || 0
    }
  },
}

const mutations = {
  setUserInfo (state, data) {
    state.username = data.username
    state.studentId = data.studentId
    state.avatar = data.avatar || ''
    state.email = data.email
    state.permission = data.permission
  },
  clearUserInfo (state) {
    state.username = ''
    state.studentId = ''
    state.avatar = ''
    state.email = ''
    state.permission = -1
  },
  setUserProgress (state, data) {
    state.progress = {}
    data.progress.forEach(problem => {
      state.progress[problem.id] = problem.score
    })
  },
  setUserProgressByProblem (state, data) {
    state.progress[data.id] = data.score
    state.progress = { ...state.progress }
  },
  clearUserProgress (state) {
    state.progress = null
  },
  addGame (state, id) {
    if (state.games.includes(id)) {
      return
    }
    state.games.push(id)
  },
}

const actions = {
  fetchUserInfo ({ commit }) {
    return axios.get('/user')
      .then(res => {
        commit('setUserInfo', res.data)
      })
      .catch(error => {
        throw error
      })
  },
  fetchUserProgress ({ commit }) {
    return axios.get('/user/progress')
      .then(res => {
        commit('setUserProgress', res.data)
      })
      .catch(error => {
        throw error
      })
  },
  fetchUserProgressByProblem ({ commit }, problemId) {
    return axios.get(`/user/progress?problemId=${problemId}`)
      .then(res => {
        commit('setUserProgressByProblem', res.data.progress[0])
      })
      .catch(error => {
        throw error
      })
  },
  updateUserInfo ({ dispatch }, data) {
    return axios.patch('/user/profile', data)
      .then(() => dispatch('fetchUserInfo'))
      .catch(error => {
        throw error
      })
  },
  signIn ({ dispatch }, data) {
    return axios.post('/user/signin', data)
      .then(() => dispatch('fetchUserInfo'))
      .then(() => dispatch('fetchUserProgress'))
      .then(() => dispatch('chapter/fetchChapters', {}, { root: true }))
      .then(() => dispatch('problem/fetchProblems', {}, { root: true }))
      .then(() => dispatch('announcement/fetchAnnouncements', {}, { root: true }))
      .catch(error => {
        throw error
      })
  },
  signUp (_, data) {
    return axios.post('/user/signup', data)
      .catch(error => {
        throw error
      })
  },
  signOut ({ commit }) {
    return axios.post('/user/signout')
      .then(() => {
        commit('clearUserInfo')
        commit('clearUserProgress')
      })
      .catch(error => {
        throw error
      })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
