<template lang="pug">
two-columns-layout
  //- Title and create button of homework section
  heading Homework
    template(v-slot:action)
      white-button(to="/manage/chapter/homework") Create
  tile-list(:items="homeworkList")
    template(v-slot:default="{item}")
      //- Each chapter card
      manage-chapter-card(
        type="homework"
        :key="`homework-card-${item.index}`"
        :index="item.index"
        :hidden="item.hidden"
        :path="`/manage/chapter/homework/${item.index}`") {{ item.title }}
  //- Title and create button of exam section
  heading Exam
    template(v-slot:action)
      white-button(to="/manage/chapter/exams") Create
  //- Exam card list
  tile-list(:items="examList")
    template(v-slot:default="{item}")
      //- Each chapter card
      manage-chapter-card(
        type="exams"
        :key="`exam-card-${item.index}`"
        :index="item.index"
        :hidden="item.hidden"
        :path="`/manage/chapter/exams/${item.index}`") {{ deadlineStatus(item) }}
  //- Menu panel
  template(v-slot:aside)
    manage-menu
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import TwoColumnsLayout from '@/layouts/TwoColumnsLayout.vue'
import Heading from '@/components/common/Heading.vue'
import WhiteButton from '@/components/buttons/WhiteButton.vue'
import TileList from '@/components/lists/TileList.vue'
import ManageChapterCard from '@/components/cards/ManageChapterCard.vue'
import ManageMenu from '@/components/menus/ManageMenu.vue'

export default {
  components: {
    TwoColumnsLayout,
    Heading,
    WhiteButton,
    TileList,
    ManageChapterCard,
    ManageMenu,
  },
  computed: {
    // The chapter information is prefetched
    // in the landing step in `router/index.js`
    ...mapState('chapter', ['homeworkList', 'examList']),
    ...mapGetters('chapter', ['deadlineStatus']),
  },
}
</script>
