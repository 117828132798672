<template lang="pug">
two-columns-layout
  //- Filters, they work at the same time
  heading
    //- Search bar, filter problems whose title includes the keyword
    //- TODO: add query to url
    search-bar(v-model="keyword")
    template(v-slot:action)
      white-button(to="/manage/problems/problem") Create
  //- Problems list
  card-list(:items="visibleProblems")
    template(v-slot:default="{item}")
      manage-problem-card(:problem="item" @search-tag="searchTag")
  template(v-slot:aside)
    manage-menu
</template>

<script>
import { mapState } from 'vuex'
import TwoColumnsLayout from '@/layouts/TwoColumnsLayout.vue'
import Heading from '@/components/common/Heading.vue'
import SearchBar from '@/components/common/SearchBar.vue'
import WhiteButton from '@/components/buttons/WhiteButton.vue'
import CardList from '@/components/lists/CardList.vue'
import ManageProblemCard from '@/components/cards/ManageProblemCard.vue'
import ManageMenu from '@/components/menus/ManageMenu.vue'

export default {
  components: {
    TwoColumnsLayout,
    Heading,
    SearchBar,
    CardList,
    ManageProblemCard,
    WhiteButton,
    ManageMenu,
  },
  data () {
    return {
      keyword: '',
    }
  },
  computed: {
    // All problems with user's score on them
    ...mapState('problem', ['problems']),
    // Total number of problems
    problemsCount () {
      return this.problems.length
    },
    // Problems filtered by status and keyword
    visibleProblems () {
      let tmp = this.problems.slice()

      if(this.keyword !== '') {
        if(this.keyword[0] === '#') {
          const filterTag = this.keyword.substr(1).toLowerCase()
          if(filterTag) {
            tmp = tmp.filter(problem => problem.tags.some(tag => tag.toLowerCase().indexOf(filterTag) > -1))
          }
        }
        else {
          tmp = tmp.filter(problem => {
            return problem.title.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1
          })
        }
      }

      return tmp
    },
  },
  methods: {
    searchTag (tag) {
      this.keyword = `#${tag}`
    },
  },
}
</script>
