import Vue from 'vue'
import VueRouter from 'vue-router'

import About from '../views/About.vue'
import Signin from '@/views/Signin.vue'
import ForgotPwd from '@/views/ForgotPwd.vue'
import ResetPwd from '@/views/ResetPwd.vue'
import Home from '@/views/Home.vue'
import Chapters from '@/views/Chapters.vue'
import Homework from '@/views/Homework.vue'
import Exam from '@/views/Exam.vue'
import Exercise from '@/views/Exercise.vue'
import Problems from '@/views/Problems.vue'
import Problem from '@/views/Problem.vue'
import Submissions from '@/views/Submissions'
import Discussions from '@/views/Discussions.vue'
import AskQuestion from '@/views/AskQuestion.vue'
import DiscussionPost from '@/views/DiscussionPost.vue'
import Bonus from '@/views/Bonus.vue'
import BonusPost from '@/views/BonusPost.vue'
import Profile from '@/views/Profile.vue'
import SettingProfile from '@/views/setting/Profile.vue'
import SettingPassword from '@/views/setting/Password.vue'
import SettingAuthenticate from '@/views/setting/Authenticate.vue'
import ManageChapter from '@/views/manage/Chapter.vue'
import HomeworkForm from '@/views/manage/Homework.vue'
import ExamForm from '@/views/manage/Exam.vue'
import ManageProblem from '@/views/manage/ProblemList.vue'
import ProblemForm from '@/views/manage/Problem.vue'
import ExerciseForm from '@/views/manage/Exercise.vue'
import ManageAnnouncement from '@/views/manage/AnnouncementList.vue'
import AnnouncementForm from '@/views/manage/Announcement.vue'
import ManageBonus from '@/views/manage/BonusList.vue'
import BonusForm from '@/views/manage/Bonus.vue'
import ManageDiscussion from '@/views/manage/DiscussionList.vue'
import ManageFAQ from '@/views/manage/FAQ.vue'
import ManageDashboard from '@/views/manage/Dashboard.vue'
import FAQ from '@/views/FAQ.vue'
import Story from '@/views/Story.vue'
import GameList from '@/views/GameList.vue'
import GameDetail from '@/views/GameDetail.vue'
import NotFound from '@/views/NotFound.vue'
import Tests from '../views/Tests.vue'

import store from '@/store'

Vue.use(VueRouter)

// Define route list.
// If a route is `authorizedView`, then it is only accessable for signed in user.
// If a route is `unauthorizedView`, then it is unavailable for signed in user.
const routes = [
  {
    path:       '/test',
    name:       '/test',
    component:  Tests,
  },
  {
    path:      '/about',
    name:      'about',
    component: About,
    meta:      {
      title:            'Welcome to CK Judge!',
      unauthorizedView: true,
    },
  },
  {
    path:      '/signin',
    name:      'signin',
    component: Signin,
    meta:      {
      title:            'Sign in',
      unauthorizedView: true,
    },
  },
  {
    path:      '/forgotPwd',
    name:      'forgotPwd',
    component: ForgotPwd,
    meta:      {
      title:            'Forgot your password?',
      unauthorizedView: true,
    },
  },
  {
    path:      '/resetPwd',
    name:      'resetPwd',
    component: ResetPwd,
    meta:      {
      title:            'Reset your password',
      unauthorizedView: true,
      tokenNeeded:      true,
    },
  },
  {
    path:      '/',
    name:      'home',
    component: Home,
    meta:      {
      title:          'Home',
      authorizedView: true,
    },
  },
  {
    path:      '/chapters',
    name:      'chapters',
    component: Chapters,
    meta:      {
      title:          'Chapters',
      authorizedView: true,
      breadcrumbs:    [
        {
          text:     'Home',
          to:       '/',
          disabled: false,
        },
        {
          text:     'Chapters',
          to:       '/chapters',
          disabled: true,
        },
      ],
    },
  },
  {
    path:      '/homework/:index',
    name:      'homework',
    component: Homework,
    meta:      {
      // The title will be dynamically set by page component.
      authorizedView: true,
      // The breadcrumbs will be dynamically set by page component.
      // The `_breadcrumbs` property provides static common prefix breadcrumbs.
      _breadcrumbs:   [
        {
          text:     'Home',
          to:       '/',
          disabled: false,
        },
        {
          text:     'Chapters',
          to:       '/chapters',
          disabled: false,
        },
      ],
    },
  },
  {
    path:      '/homework/:index/exercises/:exerciseId',
    name:      'exercise',
    component: Exercise,
    meta:      {
      // The title will be dynamically set by page component.
      authorizedView: true,
      // The breadcrumbs will be dynamically set by page component.
      // The `_breadcrumbs` property provides static common prefix breadcrumbs.
      _breadcrumbs:   [
        {
          text:     'Home',
          to:       '/',
          disabled: false,
        },
        {
          text:     'Chapters',
          to:       '/chapters',
          disabled: false,
        },
      ],
    },
  },
  {
    path:      '/exam/:index',
    name:      'exam',
    component: Exam,
    meta:      {
      // The title will be dynamically set by page component.
      authorizedView: true,
      // The breadcrumbs will be dynamically set by page component.
      // The `_breadcrumbs` property provides static common prefix breadcrumbs.
      _breadcrumbs:   [
        {
          text:     'Home',
          to:       '/',
          disabled: false,
        },
        {
          text:     'Chapters',
          to:       '/chapters',
          disabled: false,
        },
      ],
    },
  },
  {
    path:      '/problems',
    name:      'problems',
    component: Problems,
    meta:      {
      title:          'Problems',
      authorizedView: true,
      breadcrumbs:    [
        {
          text:     'Home',
          to:       '/',
          disabled: false,
        },
        {
          text:     'Problems',
          to:       '/problems',
          disabled: true,
        },
      ],
    },
  },
  {
    path:      '/problems/:id',
    name:      'problem',
    component: Problem,
    meta:      {
      // The title will be dynamically set by page component.
      authorizedView: true,
      // The breadcrumbs will be dynamically set by page component.
      // The `_breadcrumbs` property provides static common prefix breadcrumbs.
      _breadcrumbs:    [
        {
          text:     'Home',
          to:       '/',
          disabled: false,
        },
        {
          text:     'Problems',
          to:       '/problems',
          disabled: false,
        },
      ],
    },
  },
  {
    path:      '/problems/:id/submissions',
    name:      'submissions',
    component: Submissions,
    meta:      {
      // The title will be dynamically set by page component.
      authorizedView: true,
      // The breadcrumbs will be dynamically set by page component.
      // The `_breadcrumbs` property provides static common prefix breadcrumbs.
      _breadcrumbs:    [
        {
          text:     'Home',
          to:       '/',
          disabled: false,
        },
        {
          text:     'Problems',
          to:       '/problems',
          disabled: false,
        },
      ],
    },
  },
  {
    path:      '/discussion',
    name:      'discussion',
    component: Discussions,
    meta:      {
      title:          'Discussion',
      authorizedView: true,
      breadcrumbs:    [
        {
          text:     'Home',
          to:       '/',
          disabled: false,
        },
        {
          text:     'Discussion',
          to:       '/discussion',
          disabled: true,
        },
      ],
    },
  },
  {
    path:      '/discussion/ask',
    name:      'ask-question',
    component: AskQuestion,
    meta:      {
      title:          'Ask a question',
      authorizedView: true,
      breadcrumbs:    [
        {
          text:     'Home',
          to:       '/',
          disabled: false,
        },
        {
          text:     'Discussion',
          to:       '/discussion',
          disabled: false,
        },
        {
          text:     'Ask Question',
          to:       '/discussion/ask',
          disabled: true,
        },
      ],
    },
  },
  {
    path:      '/discussion/:id',
    name:      'discussion-post',
    component: DiscussionPost,
    meta:      {
      // The title will be dynamically set by page component.
      authorizedView: true,
      // The breadcrumbs will be dynamically set by page component.
      // The `_breadcrumbs` property provides static common prefix breadcrumbs.
      _breadcrumbs:    [
        {
          text:     'Home',
          to:       '/',
          disabled: false,
        },
        {
          text:     'Discussion',
          to:       '/discussion',
          disabled: false,
        },
      ],
    },
  },
  {
    path:      '/discussion/:id/edit',
    name:      'discussion-post-edit',
    component: AskQuestion,
    meta:      {
      title:          'Ask a question',
      authorizedView: true,
      breadcrumbs:    [
        {
          text:     'Home',
          to:       '/',
          disabled: false,
        },
        {
          text:     'Discussion',
          to:       '/discussion',
          disabled: false,
        },
        {
          text:     'Ask Question',
          to:       '/discussion/ask',
          disabled: true,
        },
      ],
    },
  },
  {
    path:      '/bonus',
    name:      'bonus',
    component: Bonus,
    meta:      {
      title:          'Bonus',
      authorizedView: true,
      breadcrumbs:    [
        {
          text:     'Home',
          to:       '/',
          disabled: false,
        },
        {
          text:     'Bonus',
          to:       '/bonus',
          disabled: true,
        },
      ],
    },
  },
  {
    path:      '/bonus/:id',
    name:      'bonus-post',
    component: BonusPost,
    meta:      {
      // The title will be dynamically set by page component.
      authorizedView: true,
      // The breadcrumbs will be dynamically set by page component.
      // The `_breadcrumbs` property provides static common prefix breadcrumbs.
      _breadcrumbs:    [
        {
          text:     'Home',
          to:       '/',
          disabled: false,
        },
        {
          text:     'Bonus',
          to:       '/bonus',
          disabled: false,
        },
      ],
    },
  },
  {
    path:      '/faq',
    name:      'faq',
    component: FAQ,
    meta:      {
      title:          'Frequently asked questions',
      authorizedView: true,
      breadcrumbs:    [
        {
          text:     'Home',
          to:       '/',
          disabled: false,
        },
        {
          text:     'FAQ',
          to:       '/faq',
          disabled: true,
        },
      ],
    },
  },
  {
    path:      '/story',
    name:      'story',
    component: Story,
    meta:      { unauthorizedView: true },
  },
  {
    path:      '/game',
    name:      'game',
    component: GameList,
  },
  {
    path:      '/game/:id',
    name:      'gameDetail',
    component: GameDetail,
  },
  {
    path:      '/profile/:username',
    name:      'proile',
    component: Profile,
    meta:      {
      // The title will be dynamically set by page component.
      authorizedView: true,
    },
  },
  {
    path:      '/setting/profile',
    name:      'setting-profile',
    component: SettingProfile,
    meta:      {
      title:          'Profile - Setting',
      authorizedView: true,
      breadcrumbs:    [
        {
          text:     'Home',
          to:       '/',
          disabled: false,
        },
        {
          text:     'Setting',
          to:       '/setting/profile',
          disabled: true,
        },
        {
          text:     'Profile',
          to:       '/setting/profile',
          disabled: true,
        },
      ],
    },
  },
  {
    path:      '/setting/password',
    name:      'setting-password',
    component: SettingPassword,
    meta:      {
      title:          'Update password - Setting',
      authorizedView: true,
      breadcrumbs:    [
        {
          text:     'Home',
          to:       '/',
          disabled: false,
        },
        {
          text:     'Setting',
          to:       '/setting/password',
          disabled: true,
        },
        {
          text:     'Password',
          to:       '/setting/password',
          disabled: true,
        },
      ],
    },
  },
  {
    path:      '/setting/authenticate',
    name:      'setting-authenticate',
    component: SettingAuthenticate,
    meta:      {
      title:          'Third Party Account Authentication - Setting',
      authorizedView: true,
      breadcrumbs:    [
        {
          text:     'Home',
          to:       '/',
          disabled: false,
        },
        {
          text:     'Setting',
          to:       '/setting/authenticate',
          disabled: true,
        },
        {
          text:     'Authenticate',
          to:       '/setting/authenticate',
          disabled: true,
        },
      ],
    },
  },
  {
    path:     '/setting',
    redirect: '/setting/profile',
  },
  {
    path:      '/manage/chapter',
    name:      'manage-chapter',
    component: ManageChapter,
    meta:      {
      title:          'Chapters - Manage',
      authorizedView: true,
      adminView:      true,
    },
  },
  {
    path:      '/manage/chapter/homework',
    name:      'manage-create-homework',
    component: HomeworkForm,
    meta:      {
      title:          'Editing homework - Manage',
      authorizedView: true,
      adminView:      true,
    },
  },
  {
    path:      '/manage/chapter/homework/:id',
    name:      'manage-update-homework',
    component: HomeworkForm,
    meta:      {
      title:          'Editing homework - Manage',
      authorizedView: true,
      adminView:      true,
    },
  },
  {
    path:      '/manage/chapter/homework/:homeworkId/exercises',
    name:      'manage-create-exercise',
    component: ExerciseForm,
    meta:      {
      title:          'Editing an exercise - Manage',
      authorizedView: true,
      adminView:      true,
    },
  },
  {
    path:      '/manage/chapter/homework/:homeworkId/exercises/:id',
    name:      'manage-update-exercise',
    component: ExerciseForm,
    meta:      {
      title:          'Editing an exercise - Manage',
      authorizedView: true,
      adminView:      true,
    },
  },
  {
    path:      '/manage/chapter/exams',
    name:      'manage-create-exams',
    component: ExamForm,
    meta:      {
      title:          'Editing an exam - Manage',
      authorizedView: true,
      adminView:      true,
    },
  },
  {
    path:      '/manage/chapter/exams/:id',
    name:      'manage-update-exams',
    component: ExamForm,
    meta:      {
      title:          'Editing an exam - Manage',
      authorizedView: true,
      adminView:      true,
    },
  },
  {
    path:      '/manage/problems',
    name:      'manage-problems',
    component: ManageProblem,
    meta:      {
      title:          'Problems - Manage',
      authorizedView: true,
      adminView:      true,
    },
  },
  {
    path:      '/manage/problems/problem',
    name:      'manage-create-problem',
    component: ProblemForm,
    meta:      {
      title:          'Editing a problem - Manage',
      authorizedView: true,
      adminView:      true,
    },
  },
  {
    path:      '/manage/problems/problem/:id',
    name:      'manage-update-problem',
    component: ProblemForm,
    meta:      {
      title:          'Editing a problem - Manage',
      authorizedView: true,
      adminView:      true,
    },
  },
  {
    path:      '/manage/announcements',
    name:      'manage-announcements',
    component: ManageAnnouncement,
    meta:      {
      title:          'Announcements - Manage',
      authorizedView: true,
      adminView:      true,
    },
  },
  {
    path:      '/manage/announcements/announcement',
    name:      'manage-create-announcement',
    component: AnnouncementForm,
    meta:      {
      title:          'Editing an announcement - Manage',
      authorizedView: true,
      adminView:      true,
    },
  },
  {
    path:      '/manage/announcements/announcement/:id',
    name:      'manage-update-announcement',
    component: AnnouncementForm,
    meta:      {
      title:          'Editing an announcement - Manage',
      authorizedView: true,
      adminView:      true,
    },
  },
  {
    path:      '/manage/discussion',
    name:      'manage-discussion',
    component: ManageDiscussion,
    meta:      {
      title:          'Discussion - Manage',
      authorizedView: true,
      adminView:      true,
    },
  },
  {
    path:      '/manage/bonus',
    name:      'manage-bonus',
    component: ManageBonus,
    meta:      {
      title:          'Bonus - Manage',
      authorizedView: true,
      adminView:      true,
    },
  },
  {
    path:      '/manage/bonus/post',
    name:      'manage-create-bonus',
    component: BonusForm,
    meta:      {
      title:          'Editing a bonus post - Manage',
      authorizedView: true,
      adminView:      true,
    },
  },
  {
    path:      '/manage/bonus/post/:id',
    name:      'manage-update-bonus',
    component: BonusForm,
    meta:      {
      title:          'Editing a bonus post - Manage',
      authorizedView: true,
      adminView:      true,
    },
  },
  {
    path:      '/manage/faq',
    name:      'manage-faq',
    component: ManageFAQ,
    meta:      {
      title:          'FAQ - Manage',
      authorizedView: true,
      adminView:      true,
    },
  },
  {
    path:      '/manage/dashboard',
    name:      'manage-dashboard',
    component: ManageDashboard,
    meta:      {
      title:          'Dashboard - Manage',
      authorizedView: true,
      adminView:      true,
    },
  },
  {
    path:     '/manage',
    redirect: '/manage/chapter',
  },
  {
    path:      '/notFound',
    name:      'notFound',
    component: NotFound,
    meta:      {
      title: 'Page not found',
    },
  },
  {
    path:     '*',
    redirect: '/notFound',
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
})

// Check user status to decide if he / she can go to next page.
router.beforeEach(async (to, from, next) => {
  // If landing to this site, initialize global variables first
  if(from.name === null) {
    await store.dispatch('chapter/fetchChapters')
      .then(() => store.dispatch('user/fetchUserInfo'))
      .then(() => store.dispatch('user/fetchUserProgress'))
      .then(() => store.dispatch('problem/fetchProblems'))
      .then(() => store.dispatch('announcement/fetchAnnouncements'))
      .catch(error => {
        store.commit('feature/setNotification', error)
      })
      .finally(() => {
        store.commit('feature/setPageLoading', false)
      })
  }
  // If username is empty string, it indicates that there is no session
  const isSignin = store.getters['user/isSignin']

  // If user is going to authorizedView
  if (to.matched.some(record => record.meta.authorizedView)) {
    // If he / she is not signed, redirect to landing page
    if(!isSignin) {
      next('/about')
    }
    // If he / she is signed in and trying to go to the admin, but he / she is not an admin, then go to 404 page
    else if(to.matched.some(record => record.meta.adminView) && !store.getters['user/isAdmin']) {
      next('/notFound')
    }
    // Else, just go to next page
    else {
      next()
    }
  }
  // Else if user is going to unauthorizedView but is logged-in, then go to home page
  else if (to.matched.some(record => record.meta.unauthorizedView)) {
    if (isSignin) {
      next('/')
    }
    else if (to.matched.some(record => record.meta.tokenNeeded) && !to.query.token) {
      next('/notFound')
    }
    else {
      next()
    }
  }
  // Else, go to next page.
  else {
    next()
  }
})

export default router
