<template lang="pug">
two-columns-layout
  heading Bonus Information
    template(v-slot:action)
      //- Delete announcement button, only clickable in update page
      v-btn(
        icon
        :loading="isDeleting"
        :disabled="isDeleting || action === 'create'"
        @click.prevent="isDeleting = true")
        v-icon(
          size="32"
          color="#FF6E6E") $trashcan
  v-form(ref="form")
    //- Bonus title
    text-field(
      v-model="bonus.title"
      label="Title"
      required)
    //- Bonus content
    rich-text-editor(
      v-model="bonus.content"
      label="Content"
      @change="formDirty = true")
  div.d-flex.mt-10
    //- Submit button
    primary-button.mr-9(
      :isWaiting="isLoading"
      @click="submitForm()") {{ action }}
    //- Cancel button
    primary-button(
      hollow
      :isWaiting="isLoading"
      @click="goToBonusList()") Cancel
  //- Confirm delete modal
  modal(:isOpen="isDeleting")
    template(v-slot:title)
      span Delete "{{ bonus.title }}"
    template(v-slot:content)
      p Do you really want to delete "{{ bonus.title }}"?
    template(v-slot:actions)
      modal-button.mr-5(@click="deleteBonusPost()") Delete
      modal-button(hollow @click="isDeleting = false") Cancel
  //- Menu panel
  template(v-slot:aside)
    manage-menu
</template>

<script>
import axios from '@/plugins/axios.js'
import { mapMutations } from 'vuex'
import TwoColumnsLayout from '@/layouts/TwoColumnsLayout.vue'
import Heading from '@/components/common/Heading.vue'
import ManageMenu from '@/components/menus/ManageMenu.vue'
import PrimaryButton from '@/components/buttons/PrimaryButton.vue'
import TextField from '@/components/form/TextField.vue'
import RichTextEditor from '@/components/form/RichTextEditor.vue'
import Modal from '@/components/common/Modal.vue'
import ModalButton from '@/components/buttons/ModalButton.vue'

export default {
  components: {
    TwoColumnsLayout,
    Heading,
    ManageMenu,
    PrimaryButton,
    TextField,
    RichTextEditor,
    Modal,
    ModalButton,
  },
  data () {
    return {
      action:     'update',
      isLoading:  true,
      isDeleting: false,
      bonus:      {
        // Index of this bonus post
        id:      this.$route.params.id || '',
        // Title of this bonus post
        title:   '',
        // Content of this bonus post
        content: '',
      },
      formDirty: false,
    }
  },
  methods: {
    ...mapMutations('feature', ['setNotification']),
    // Return to bonus list page
    goToBonusList () {
      this.$router.push('/manage/bonus').catch(()=>{})
    },
    // Submit form
    submitForm () {
      // Validate each field before submitting
      if(!this.$refs.form.validate()) {
        this.setNotification({
          isOpen:  true,
          type:    'warning',
          message: 'Please ensure you filled in every field correctly',
        })
        return
      }

      // Decide which action to do
      this[`${this.action}BonusPost`]()
    },
    // Create bonus post
    createBonusPost () {
      // Set loading state
      this.isLoading = true

      // Create bonus post
      axios.post('/bonuses', {
        title:   this.bonus.title,
        content: this.bonus.content,
      })
        // If success, show success message and return to bonus list
        .then(() => {
          this.formDirty = false
          this.setNotification({
            isOpen:  true,
            type:    'success',
            message: 'New bonus post created!',
          })
          this.goToBonusList()
        })
        // If there is any error, show notification and clear loading state
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
          this.isLoading = false
        })
    },
    // Update bonus post
    updateBonusPost () {
      // Set loading state
      this.isLoading = true

      // Update bonus post
      axios.patch(`/bonuses/${this.bonus.id}`, {
        title:   this.bonus.title,
        content: this.bonus.content,
      })
        // If success, show success message
        .then(() => {
          this.formDirty = false
          this.setNotification({
            isOpen:  true,
            type:    'success',
            message: 'Bonus post updated!',
          })
        })
        // If there is any error, show notification
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
        })
        // Clear loading state
        .finally(() => {
          this.isLoading = false
        })
    },
    // Delete bonus post
    deleteBonusPost () {
      // Delete bonus post
      axios.delete(`/bonuses/${this.bonus.id}`)
        // If success, show success message and return to bonus list
        .then(() => {
          this.formDirty = false
          this.setNotification({
            isOpen:  true,
            type:    'success',
            message: 'Bonus post deleted!',
          })
          this.goToBonusList()
        })
        // If there is any error, show notification and clear deleting state
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
          this.isDeleting = false
        })
    },
  },
  mounted () {
    // If bonus id is not empty string,
    // which means user is updating bonus, need to fetch data first
    if(this.bonus.id) {
      axios.get(`/bonuses/${this.bonus.id}`)
        .then(res => {
          this.bonus.title = res.data.bonus.title
          this.bonus.content = res.data.bonus.content
        })
        // If there is any error, show notification
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
        })
        .finally(() => {
        // Clear loading state
          this.isLoading = false
        })
    }
    // Else, it means user is creating announcement
    else {
      this.action = 'create'
      this.isLoading = false
    }
  },
  beforeRouteLeave (to, from, next) {
    if(this.formDirty && !this.confirmRouteChange()) {
      next(false)
    }
    else {
      next()
    }
  },
}
</script>
