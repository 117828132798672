<template lang="pug">
two-columns-layout
  //- Form heading and buttons
  heading Homework Information
    template(v-slot:action)
      div.flex-center.flex-nowrap
        //- Hide / unhide button
        v-btn.mr-5(icon @click.prevent="homework.hidden = !homework.hidden")
          v-icon(
            v-if="homework.hidden"
            size="32"
            color="#F9F9F9B3") $eye-close
          v-icon(v-else size="32") $eye-open
        //- Delete chapter button, only clickable in update page
        v-btn(
          icon
          :loading="isDeleting"
          :disabled="isDeleting || action === 'create'"
          @click.prevent="isDeleting = true")
          v-icon(size="32" color="#FF6E6E") $trashcan
  v-form(ref="form")
    div.d-flex
      //- Index, e.g. "Lec01"
      text-field.flex-grow-0.mr-6(
        v-model="homework.index"
        label="Index"
        required)
      //- Chapter title, e.g. "Introduction to C"
      text-field(
        v-model="homework.title"
        label="Title"
        required)
    div.d-flex
      //- Course video url, need to satisfy youtube embed url format
      text-field.mr-6(
        v-model="newVideoUrl"
        label="Course Video"
        :rules="[isYoutubeEmbed]")
      white-button.mt-3(@click="addVideoUrl()") Add
    div.mb-5
      div.existing-video-header
        span Course videos
      div.existing-video-body
        p.ma-0(v-if="videoUrl.length === 0") No video url added
        template(v-else)
          div.d-flex.justify-space-between(v-for="(url, index) in videoUrl")
            div.flex-center
              span.mr-6 # {{ index + 1 }}
              a(:href="url" target="_blank") {{ url }}
            v-btn(
                icon
                color="#FF6E6E"
                @click="removeVideoUrl(url)")
                v-icon $trashcan
    //- Quick review
    rich-text-editor(
      v-model="homework.review"
      label="Quick Review")
    //- Exercises list
    div.mb-5
      div.existing-video-header
        span Exercises
      div.existing-video-body
        p.ma-0(v-if="exercises.length === 0") No exercise
        v-list(v-else color="transparent")
          v-list-item(
            v-for="exercise in exercises"
            :key="`exercise-${exercise.id}`"
            :to="`/manage/chapter/homework/${homework.index}/exercises/${exercise.id}`")
            v-list-item-content
              v-list-item-title {{ exercise.title }}
            v-list-item-action
              v-btn(icon)
                v-icon mdi-pencil
      white-button.my-5(:to="`/manage/chapter/homework/${homework.index}/exercises`") Create exercise
    //- Problem Picker
    problem-picker(
      v-model="problems"
      label="Problems")
    div.d-flex
      //- Start time of the chapter
      date-time-picker.flex-grow-1.mr-6(
        v-model="homework.startTime"
        label="Start Time"
        :events="timeHighlight"
        :allowed-dates="isValidStartTime")
      //- Makeup time of the chapter
      date-time-picker.flex-grow-1.mr-6(
        v-model="homework.makeUpTime"
        label="Makeup Time"
        :events="timeHighlight"
        :allowed-dates="isValidMakeUpTime")
      //- Due time of the chapter
      date-time-picker.flex-grow-1(
        v-model="homework.dueTime"
        label="Due Time"
        :events="timeHighlight"
        :allowed-dates="isValidDueTime")
  div.d-flex.mt-10
    //- Submit button
    primary-button.mr-9(
      :isWaiting="isLoading"
      @click="submitForm()") {{ action }}
    //- Cancel button
    primary-button(
      hollow
      :isWaiting="isLoading"
      @click="goToChapterList()") Cancel
  //- Confirm delete modal
  modal(:isOpen="isDeleting")
    template(v-slot:title)
      span Delete {{ homework.index }}
    template(v-slot:content)
      p Do you really want to delete {{ homework.index }}?
    template(v-slot:actions)
      modal-button.mr-5(@click="deleteHomework()") Delete
      modal-button(hollow @click="isDeleting = false") Cancel
  //- Menu panel
  template(v-slot:aside)
    manage-menu
</template>

<script>
import axios from '@/plugins/axios.js'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import TwoColumnsLayout from '@/layouts/TwoColumnsLayout.vue'
import Heading from '@/components/common/Heading.vue'
import ManageMenu from '@/components/menus/ManageMenu.vue'
import WhiteButton from '@/components/buttons/WhiteButton.vue'
import PrimaryButton from '@/components/buttons/PrimaryButton.vue'
import TextField from '@/components/form/TextField.vue'
import RichTextEditor from '@/components/form/RichTextEditor.vue'
import DateTimePicker from '@/components/form/DateTimePicker.vue'
import ProblemPicker from '@/components/form/ProblemPicker.vue'
import Modal from '@/components/common/Modal.vue'
import ModalButton from '@/components/buttons/ModalButton.vue'

export default {
  components: {
    TwoColumnsLayout,
    Heading,
    ManageMenu,
    WhiteButton,
    PrimaryButton,
    TextField,
    RichTextEditor,
    DateTimePicker,
    ProblemPicker,
    Modal,
    ModalButton,
  },
  data () {
    return {
      action:     'update',
      isLoading:  true,
      isDeleting: false,
      homework:   {
        // Index of this chapter ('Lec01' for example)
        index:      this.$route.params.id || '',
        // Title of this chapter ('Introducing C' for example)
        title:      '',
        // Is this chapter hidden from non-admin user
        hidden:     true,
        // The starting time of this chapter
        startTime:  null,
        // The starting time of make-up period
        makeUpTime: null,
        // The actual deadline of this chapter
        dueTime:    null,
        // Quick reveiw note
        review:     '',
        // Url of the youtube video of this chapter
        videoUrl:   [],
      },
      videoUrl:    [],
      newVideoUrl: '',
      problems:    [],
      exercises:   [],
      formDirty:   false,
    }
  },
  computed: {
    ...mapState('chapter', ['homeworkList']),
    ...mapGetters('problem', ['chapterProblems']),
  },
  watch: {
    homework: {
      handler () {
        this.formDirty = true
      },
      deep: true,
    },
    videoUrl: {
      handler () {
        this.formDirty = true
      },
      deep: true,
    },
    newVideoUrl () {
      this.formDirty = true
    },
    problems: {
      handler () {
        this.formDirty = true
      },
      deep: true,
    },
    exercises: {
      handler () {
        this.formDirty = true
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations('feature', ['setNotification']),
    ...mapActions('chapter', ['createChapter', 'updateChapter', 'deleteChapter']),
    ...mapActions('problem', ['bindProblemsOnChapter']),
    // Set homework information
    setHomework () {
      // If this chapter has index, it means that this is an existing chapter
      // and user wnat to update it. Set form data to the chapter's data.
      if(this.homework.index) {
        axios.get(`/exercises?chapter=${this.homework.index}`)
          .then(res => {
            this.homework = {
              ...this.homework,
              ...this.homeworkList[this.homework.index],
            }

            this.exercises = res.data.exercises.sort((a, b) => (a.title > b.title ? 1 : -1))

            this.videoUrl = this.homework.videoUrl.slice()
            this.problems = this.chapterProblems(this.homework.index).map(problem => problem.id)
          })
          // If there is any error, show notification and clear loading state
          .catch(error => {
            this.setNotification({
              isOpen:  true,
              type:    'error',
              message: error.response.data,
            })
          })
          .finally(() => {
            this.isLoading = false
            // Clear formDirty flag
            this.$nextTick(() => {
              this.formDirty = false
            })
          })
      }
      // Else, it means that user is in create chapter page,
      // set action to 'create'
      else {
        this.action = 'create'
        this.isLoading = false
      }
    },
    // Return to chapter list page
    goToChapterList () {
      this.formDirty = false
      this.$router.push('/manage/chapter').catch(()=>{})
    },
    addVideoUrl () {
      if(this.newVideoUrl && (typeof this.isYoutubeEmbed(this.newVideoUrl) === 'boolean')) {
        this.videoUrl.push(this.newVideoUrl)
        this.newVideoUrl = ''
      }
    },
    removeVideoUrl (url) {
      const index = this.videoUrl.findIndex(videoUrl => videoUrl === url)
      this.videoUrl.splice(index, 1)
    },
    // Validate if the course video url field satisfy youtube embed url format
    isYoutubeEmbed (url) {
      // Empty string is allowed
      if(!url) {
        return true
      }
      return /https:\/\/www\.youtube\.com\/embed\/\w+/.test(url) ||
        'Invalid Youtube embed url format (https://www.youtube.com/embed/VIDEO_ID)'
    },
    // Validate on start time
    isValidStartTime (value) {
      const date = new Date(value)
      const afterToday = date > Date.now()
      const beforeMakeup = this.homework.makeUpTime ? date < this.homework.makeUpTime : true
      const beforeDue = this.homework.dueTime ? date < this.homework.dueTime : true
      return afterToday && beforeMakeup && beforeDue
    },
    // Validate on make up time
    isValidMakeUpTime (value) {
      const date = new Date(value)
      const afterToday = date > Date.now()
      const afterStart = this.homework.startTime ? date > this.homework.startTime : true
      const beforeDue = this.homework.dueTime ? date < this.homework.dueTime : true
      return afterToday && afterStart && beforeDue
    },
    // Validate on due time
    isValidDueTime (value) {
      const date = new Date(value)
      const afterToday = date > Date.now()
      const afterStart = this.homework.startTime ? date > this.homework.startTime : true
      const afterMakeup = this.homework.makeUpTime ? date > this.homework.makeUpTime : true
      return afterToday && afterStart && afterMakeup
    },
    // Color highlight on above -time
    timeHighlight (value) {
      const date = new Date(value).toDateString()
      const colors = []

      // Start time is green
      if(this.homework.startTime && date === this.homework.startTime.toDateString()) {
        colors.push('#52CC75')
      }
      // Make up time is yellow
      if(this.homework.makeUpTime && date === this.homework.makeUpTime.toDateString()) {
        colors.push('#F3C15A')
      }
      // Due time is red
      if(this.homework.dueTime && date === this.homework.dueTime.toDateString()) {
        colors.push('#FF6E6E')
      }

      return colors
    },
    // Submit form
    submitForm () {
      // Validate each field before submitting
      if(!this.$refs.form.validate()) {
        this.setNotification({
          isOpen:  true,
          type:    'warning',
          message: 'Please ensure you filled in every field correctly',
        })
        return
      }

      // Decide which action to do
      this[`${this.action}Homework`]()
    },
    // Create homework
    createHomework () {
      // Set loading state
      this.isLoading = true

      // Create homework
      this.createChapter({
        type:    'homework',
        payload: {
          ...this.homework,
          videoUrl: this.videoUrl.join(','),
        },
      })
        .then(() => this.bindProblemsOnChapter({
          ids:     this.problems,
          chapter: this.homework.index,
        }))
        // If success, show success message and return to chapter list
        .then(() => {
          this.formDirty = false
          this.setNotification({
            isOpen:  true,
            type:    'success',
            message: 'New chapter created!',
          })
          this.goToChapterList()
        })
        // If there is any error, show notification and clear loading state
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
          this.isLoading = false
        })
    },
    // Update homework
    updateHomework () {
      // Set loading state
      this.isLoading = true

      // Update homework
      this.updateChapter({
        type:    'homework',
        index:   this.$route.params.id,
        payload: {
          ...this.homework,
          videoUrl: this.videoUrl.join(','),
        },
      })
        .then(() => this.bindProblemsOnChapter({
          ids:     this.problems,
          chapter: this.homework.index,
        }))
        // If success, show success message
        .then(() => {
          this.setNotification({
            isOpen:  true,
            type:    'success',
            message: 'Chapter information updated!',
          })
          this.setHomework()
        })
        // If there is any error, show notification
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
        })
        // Clear loading state
        .finally(() => {
          this.isLoading = false
        })
    },
    // Delete homework
    deleteHomework () {
      // Delete homework
      this.deleteChapter({
        type:  'homework',
        index: this.homework.index,
      })
        // If success, show success message and return to chapter list
        .then(() => {
          this.formDirty = false
          this.setNotification({
            isOpen:  true,
            type:    'success',
            message: 'Chapter deleted!',
          })
          this.goToChapterList()
        })
        // If there is any error, show notification and clear deleting state
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
          this.isDeleting = false
        })
    },
  },
  mounted () {
    this.setHomework()
  },
  beforeRouteLeave (to, from, next) {
    if(this.formDirty && !this.confirmRouteChange()) {
      next(false)
    }
    else {
      next()
    }
  },
}
</script>

<style lang="scss" scoped>
.existing-video-header {
  background-color: #F9F9F9;
  color: #30333F;
  font-weight: 500;
  border-radius: 5px 5px 0px 0px;
  padding: 8px 16px;
}

.existing-video-body {
  border: 1px solid #F9F9F9;
  border-radius: 0px 0px 5px 5px;
  padding: 16px;

  & a {
    color: #FFD06F;
  }
}
</style>
