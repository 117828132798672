<template lang="pug">
v-menu(
  :close-on-content-click="false"
  :z-index="9"
  offset-y
  v-bind="menuPosition")
  template(v-slot:activator="{ on, attrs, value }")
    v-badge.mr-3.mr-md-0(
      overlay
      color="#EAA734"
      :content="unreadCount"
      :value="unreadCount"
      :offset-y="15"
      :offset-x="15")
      v-btn(
        v-bind="attrs"
        v-on="on"
        v-blur
        fab
        depressed
        color="#1F2327")
        v-icon(v-if="value" size="28") $bell
        v-icon(v-else size="28") $bell-outlined
  floating-card.notification-menu
    heading Notification
      template(v-slot:action)
        white-button(@click="readAll()") Read All
    scroll-bar(max-height="400")
      v-list.clickable-list(
        flat
        expand
        color="#30333F"
        v-bind="menuSize")
        v-list-item.pl-3(
          v-if="notifications.length"
          v-for="notification in notifications"
          :key="`notification-${notification.id}`"
          :ripple="false"
          @click="clickNotification(notification)")
          v-list-item-content
            v-list-item-title(v-text="notification.title")
            v-list-item-subtitle
              span.notification-date(v-text="relativeDate(notification.timestamp)")
          v-list-item-action
            v-icon(
              v-show="$vuetify.breakpoint.mdAndUp && !notification.read"
              size="16"
              color="#E8A723") mdi-circle
        v-list-item(v-else)
          v-list-item-title No notification
</template>

<script>
import axios from '@/plugins/axios.js'
import { mapMutations } from 'vuex'
import relativeDate from 'relative-date'
import FloatingCard from '@/components/cards/FloatingCard.vue'
import ScrollBar from '@/components/common/ScrollBar.vue'
import Heading from '@/components/common/Heading.vue'
import WhiteButton from '@/components/buttons/WhiteButton.vue'

export default {
  name:       'Notifications',
  components: {
    FloatingCard,
    ScrollBar,
    Heading,
    WhiteButton,
  },
  data () {
    return {
      notifications: [],
    }
  },
  computed: {
    unreadCount () {
      return this.notifications.filter(notification => !notification.read).length
    },
    menuPosition () {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return {
          'left':         true,
          'nudge-bottom': 26,
          'nudge-right':  70,
        }
      }
      else {
        return {
          'left':         true,
          'nudge-bottom': 8,
          'nudge-right':  87,
        }
      }
    },
    menuSize () {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return {
          'min-width':    400,
          'max-height':   600,
        }
      }
      else {
        return {
          'min-width':    300,
          'max-height':   600,
        }
      }
    },
  },
  methods: {
    ...mapMutations('feature', ['setNotification']),
    relativeDate,
    setNotificationList () {
      // Fetch user's notification
      axios.get('/message')
        // If request success, set notification list
        .then(res => {
          this.notifications = res.data
          this.notifications.forEach(notification => {
            notification.timestamp = new Date(notification.timestamp)
          })
          this.notifications = this.notifications.sort((a, b) => b.timestamp - a.timestamp)
        })
        // If there is any error other than 404, show notification
        .catch(error => {
          if(error.response.status !== 404) {
            this.setNotification({
              isOpen:  true,
              type:    'error',
              message: error.response.data,
            })
          }
        })
    },
    clickNotification (notification) {
      // If the notification hasn't read yet, mark it as read
      if(!notification.read) {
        axios.post('/message/mark', {
          toMark: [notification.id],
        })
          // If request success, set notification's read to true
          .then(() => {
            notification.read = true
          })
          // If there is any error, show notification
          .catch(error => {
            this.setNotification({
              isOpen:  true,
              type:    'error',
              message: error.response.data,
            })
          })
      }

      // Go to the notification's target page if the notification has `relatedPath`
      if (notification.relatedPath) {
        this.$router.push(`/${notification.type}/${notification.relatedPath}`).catch(()=>{})
      }
    },
    readAll () {
      axios.post('/message/mark', {
        toMark: this.notifications.filter(notification => !notification.read).map(notification => notification.id),
      })
        // If request success, set notification's read to true
        .then(() => {
          this.notifications.forEach(notification => {
            notification.read = true
          })
        })
        // If there is any error, show notification
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
        })
    },
  },
  created () {
    this.$socket.on('newMsg', () => {
      // Update notification list
      this.setNotificationList()
    })
  },
  mounted () {
    this.setNotificationList()
  },
}
</script>
<style lang="scss">
.notification-menu {
  width: 450px;
}

.notification-date {
  color: #FFD06F !important;
}
</style>
