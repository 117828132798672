<template lang="pug">
v-btn.modal-button(
  rounded
  :color="color"
  :style="{'color': hollow ? color : '#F9F9F9'}"
  :outlined="hollow"
  :loading="loading"
  :disabled="disabled"
  @click="$emit('click', $event)")
  slot
</template>

<script>
export default {
  name:  'ModalButton',
  props: {
    color: {
      type:     String,
      required: false,
      default:  '#30333F',
    },
    hollow: {
      type:     Boolean,
      required: false,
      default:  false,
    },
    loading: {
      type:     Boolean,
      required: false,
      default:  false,
    },
    disabled: {
      type:     Boolean,
      required: false,
      default:  false,
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-button {
  box-shadow: 4px 4px 6px #13141629;
}
</style>
