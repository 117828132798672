import axios from '@/plugins/axios.js'

const state = {
  problems:    [],
  levelOption: {
    Easy: {
      name:  'easy',
      color: '#6EADFF',
    },
    Medium: {
      name:  'medium',
      color: '#E8A723',
    },
    Hard: {
      name:  'hard',
      color: '#FF6E6E',
    },
    undefined: {
      name:  '???',
      color: '#F9F9F9',
    },
  },
  submissionStatusOption: {
    'Accepted': {
      acronym: 'AC',
      color:   '#52CC75',
    },
    'Wrong Answer': {
      acronym: 'WA',
      color:   '#E8A723',
    },
    'Presentation Error': {
      acronym: 'PE',
      color:   '#E8A723',
    },
    'Compile Error': {
      acronym: 'CE',
      color:   '#FF6E6E',
    },
    'Time Limit Exceeded': {
      acronym: 'TLE',
      color:   '#FF6E6E',
    },
    'Memory Limit Exceeded': {
      acronym: 'MLE',
      color:   '#FF6E6E',
    },
    'Runtime Error': {
      acronym: 'RE',
      color:   '#FF6E6E',
    },
    'Others': {
      acronym: 'Others',
      color:   '#FF6E6E',
    },
    'undefined': {
      name:  '???',
      color: '#F9F9F9',
    },
  },

}

const getters = {
  tags () {
    return Array.from(new Set(state.problems.map(problem => problem.tags).flat()))
  },
  chapterProblems (_, getters) {
    return chapterIndex => getters.userProblems.filter(
      problem => problem.chapter && problem.chapter.index === chapterIndex)
  },
  userProblems (state, _, rootState) {
    if(!rootState.user.progress) {
      return []
    }
    else {
      return state.problems.map(problem => ({
        ...problem,
        score: rootState.user.progress[problem.id] || 0,
      }))
    }
  },
}

const mutations = {
  setProblems (state, data) {
    state.problems = data
  },
  setProblemById (state, data) {
    const index = state.problems.findIndex(problem => problem.id === data.id)
    if(index > 0) {
      state.problems.splice(index, 1, data)
    }
    else {
      state.problems.push(data)
    }
  },
  removeProblemById (state, id) {
    const index = state.problems.findIndex(problem => problem.id === id)
    state.problems.splice(index, 1)
  },
  sortProblemsByChapter (state) {
    // TODO: come up a better sorting rule
    state.problems = state.problems.sort((a, b) => {
      if(!a.chapter) {
        return 1
      }
      else if(!b.chapter) {
        return -1
      }
      else if(a.chapter.index === 'Final') {
        return 1
      }
      else if(a.chapter.index === 'Midterm') {
        return 1
      }
      else {
        return a.chapter.index < b.chapter.index ? -1 : 1
      }
    })
  },
}

const actions = {
  fetchProblems ({ commit }) {
    return axios.get('/problems')
      .then(res => {
        // Set problem list
        commit('setProblems', res.data.problems)
        // And sort it by chapter by default
        commit('sortProblemsByChapter')
      })
      .catch(error => {
        throw error
      })
  },
  createProblem ({ dispatch }, data) {
    return axios.post('/problems', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(res => {
        dispatch('fetchProblems')
        return res.data
      })
      .catch(error => {
        throw error
      })
  },
  updateProblem ({ dispatch }, data) {
    return axios.patch(`/problems/${data.id}`, data.payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(res => {
        dispatch('fetchProblems')
        return res.data
      })
      .catch(error => {
        throw error
      })
  },
  deleteProblem ({ dispatch }, data) {
    return axios.delete(`/problems/${data.id}`)
      .then(() => {
        dispatch('fetchProblems')
      })
      .catch(error => {
        throw error
      })
  },
  bindProblemsOnChapter ({ dispatch }, data) {
    return axios.put('/problems/chapter', data)
      .then(() => dispatch('fetchProblems'))
      .catch(error => {
        throw error
      })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
