<template lang="pug">
floating-card.manage-chapter-card(:to="path")
  //- Chapter name, compatible for chapter index or exam name
  span.card-index {{ index }}
  //- Title text, compatible for chapter title or exam status
  //- This text should be inject from parent
  span.card-title
    slot
  div.card-actions
    v-btn(
      icon
      large
      :loading="isHiding"
      :disabled="isHiding"
      @click.prevent="toggleHidden")
      v-icon(
        v-if="hidden"
        size="32"
        color="#F9F9F9B3") $eye-close
      v-icon(v-else size="32") $eye-open
    v-btn(
      icon
      large
      :loading="isDeleting"
      :disabled="isDeleting"
      @click.prevent="isDeleting = true")
      v-icon(size="32" color="#FF6E6E") $trashcan
  //- Confirm delete modal
  modal(:isOpen="isDeleting")
    template(v-slot:title)
      span Delete {{ index }}
    template(v-slot:content)
      p Do you really want to delete {{ index }}?
    template(v-slot:actions)
      modal-button.mr-5(@click="remove()") Delete
      modal-button(hollow @click="isDeleting = false") Cancel
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import FloatingCard from '@/components/cards/FloatingCard.vue'
import Modal from '@/components/common/Modal.vue'
import ModalButton from '@/components/buttons/ModalButton.vue'

export default {
  name:  'ManageChapterCard',
  props: {
    type: {
      type:     String,
      required: true,
    },
    // Index of this chapter
    index: {
      type:     String,
      required: true,
      default:  '',
    },
    // Route to chapter page
    path: {
      type:     String,
      required: true,
    },
    // Whether this chpater is hidden
    hidden: {
      type:     Boolean,
      required: true,
    },
  },
  components: {
    FloatingCard,
    Modal,
    ModalButton,
  },
  data () {
    return {
      isHiding:   false,
      isDeleting: false,
    }
  },
  methods: {
    ...mapMutations('feature', ['setNotification']),
    ...mapActions('chapter', ['updateChapter', 'deleteChapter']),
    toggleHidden () {
      this.isHiding = true
      this.updateChapter({
        type:    this.type,
        index:   this.index,
        payload: {
          hidden: !this.hidden,
        },
      })
        .then(() => {
          this.setNotification({
            isOpen:  true,
            type:    'success',
            message: `Chapter is ${this.hidden ? 'hidden' : 'open'}!`,
          })
        })
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
        })
        .finally(() => {
          this.isHiding = false
        })
    },
    remove () {
      this.deleteChapter({
        type:  this.type,
        index: this.index,
      })
        // If success, show success message and return to chapter list
        .then(() => {
          this.setNotification({
            isOpen:  true,
            type:    'success',
            message: 'Chapter deleted!',
          })
        })
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
        })
        .finally(() => {
          this.isDeleting = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.manage-chapter-card {
  display: grid;
  grid-template-rows: min-content min-content 1fr;
  align-items: center;
  width: 274px;
  border-radius: 5px;

  .card-index {
    font-size: 24px;
    font-weight: 500;
  }

  .card-title {
    font-size: 20px;
  }

  .card-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }
}
</style>
