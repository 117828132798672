<template lang='pug'>
two-columns-layout
  //- Dashboard selector
  v-container(fluid)
    v-row(align="end")
      v-col(cols="auto")
        span Select a dashboard:
      v-col
        v-select(
          v-model="currentDashboard"
          :items="dashboards"
          hide-details)
  //- Overview dashboard
  v-data-table(
    v-if="currentDashboard === 'Overview'"
    dense
    show-expand
    single-expand
    item-key="username"
    loading-text="Fetching user data..."
    :loading="isLoading"
    :headers="overviewHeader"
    :items="users"
    :search="keyword"
    :items-per-page="-1")
    //- Utils
    template(v-slot:top)
      v-container.py-0(fluid)
        v-row(dense align="center")
          //- Search bar (search for username)
          v-col(cols="auto")
            span Search for user:
          v-col
            v-text-field(
              v-model="keyword"
              label="Username")
          //- Refresh exam token button
          v-col(cols="auto")
            v-btn(
              light
              :loading="refreshTokenModal.isOpen"
              :disabled="refreshTokenModal.isOpen || isLoading"
              @click="refreshTokenModal.isOpen = true")
              v-icon.mr-1 mdi-refresh
              span Refresh token
          //- Download dashboard button
          v-col(cols="auto")
            v-btn(
              light
              :loading="isDownloading"
              :disabled="isDownloading || isLoading"
              @click="downloadTable()")
              v-icon.mr-1 mdi-download
              span Download
    //- Column indicating whether the user is an administrator
    //- Toggle checkbox to change the permission
    template(v-slot:item.isAdmin="{ item }")
      v-btn(
        icon
        @click="confirmUpdatePermission(item.username, item.permission)")
        v-icon(v-if="item.isAdmin") $checkboxOn
        v-icon(v-else) $checkboxOff
    //- Exam token column, need to be display in monospace font
    template(v-slot:item.examToken="{ item }")
      span(style="font-family: monospace") {{ item.examToken }}
    //- User progress of each problem.
    //- Displayed using subtable
    template(v-slot:expanded-item="{ headers, item }")
      td(:colspan="headers.length")
        v-data-table(
          dense
          calculate-widths
          hide-default-footer
          style="background-color: #30333F"
          item-key="problemId"
          sort-by="chapter"
          :headers="progressHeader"
          :items="item.progressInfo"
          :items-per-page="-1")
          template(v-slot:item.title="{ item }")
            span(style="white-space: nowrap") {{ item.title }}
          template(v-slot:item.chapter="{ item }")
            span(style="white-space: nowrap") {{ item.chapter }}
  //- Dashboard for specific chapter
  v-data-table(
    v-else
    dense
    calculate-widths
    item-key="username"
    :headers="chapterHeader"
    :items="chapterData"
    :search="keyword"
    :items-per-page="-1")
    //- Utils
    template(v-slot:top)
      v-container.py-0(fluid)
        v-row(dense align="center")
          v-col(cols="auto")
            span Search for user:
          v-col
            v-text-field(
              v-model="keyword"
              label="Username")
    //- Column indicating whether a user manually submitted this chapter
    template(v-slot:item.locked="{ item }")
      v-simple-checkbox(
        v-model="item.locked"
        disabled)
  //- Manage menu
  template(v-slot:aside)
    manage-menu
  //- Confirm whether to refresh the exam token
  modal(:isOpen="refreshTokenModal.isOpen")
    template(v-slot:title)
      span Refresh exam tokens?
    template(v-slot:content)
      p.mb-0 Do you sure you want to refresh exam token for all the users?
      strong WARNING: If you do this, the old token will become invalid immediately.
    template(v-slot:actions)
      modal-button.mr-5(
        :loading="refreshTokenModal.isLoading"
        @click="refreshToken()") Refresh
      modal-button(hollow @click="refreshTokenModal.isOpen = false") Cancel
  //- Confirm whether to update a user's permission
  modal(:isOpen="updatePermissionModal.isOpen")
    template(v-slot:title)
      span Update {{ updatePermissionModal.username }}'s permission?
    template(v-slot:content)
      p {{ updatePermissionMessage }}
    template(v-slot:actions)
      modal-button.mr-5(
        :loading="updatePermissionModal.isLoading"
        @click="updatePermission()") Update
      modal-button(hollow @click="updatePermissionModal.isOpen = false") Cancel
</template>

<script>
import axios from '@/plugins/axios.js'
import { mapGetters, mapMutations, mapState } from 'vuex'
import TwoColumnsLayout from '@/layouts/TwoColumnsLayout.vue'
import TextField from '@/components/form/TextField.vue'
import PrimaryButton from '@/components/buttons/PrimaryButton.vue'
import ManageMenu from '@/components/menus/ManageMenu.vue'
import Modal from '@/components/common/Modal.vue'
import ModalButton from '@/components/buttons/ModalButton.vue'

export default {
  components: {
    TwoColumnsLayout,
    TextField,
    PrimaryButton,
    ManageMenu,
    Modal,
    ModalButton,
  },
  data () {
    return {
      currentDashboard:  'Overview',
      isLoading:         false,
      isUpdating:        false,
      isDownloading:     false,
      refreshTokenModal: {
        isOpen:    false,
        isLoading: false,
      },
      updatePermissionModal: {
        isOpen:            false,
        username:          '',
        currentPermission: -1,
        isLoading:         false,
      },
      users:    [],
      keyword:  '',
      timer:    0,
    }
  },
  computed: {
    ...mapState('problem', ['problems']),
    ...mapGetters('problem', ['chapterProblems']),
    ...mapGetters('chapter', ['chapters']),
    dashboards () {
      return ['Overview'].concat(this.chapters)
    },
    problemsByChapter () {
      return this.chapters.map(index => ({
        index,
        problems: this.chapterProblems(index),
      }))
    },
    overviewHeader () {
      return [
        {
          text:   '',
          value: 'data-table-expand',
        },
        {
          text:  'Admin',
          align: 'center',
          value: 'isAdmin',
        },
        {
          text:  'User',
          align: 'center',
          value: 'username',
        },
        {
          text:  'Student ID',
          align: 'center',
          value: 'studentId',
        },
        {
          text:  'Exam Token',
          align: 'end',
          value: 'examToken',
        },
        ...this.chapters.map(chapter => ({
          text:  chapter,
          align: 'end',
          value: chapter,
        })),
      ]
    },
    progressHeader () {
      return [
        {
          text:  'Chapter',
          align: 'start',
          value: 'chapter',
        },
        {
          text:  'Problem',
          align: 'start',
          value: 'title',
        },
        {
          text:  'Score',
          align: 'start',
          value: 'score',
        },
        {
          width: '100%',
        },
      ]
    },
    chapterHeader () {
      return [
        {
          text:  'Submitted',
          align: 'center',
          value: 'locked',
        },
        {
          text:  'User',
          align: 'center',
          value: 'username',
        },
        {
          text:  'Student ID',
          align: 'center',
          value: 'studentId',
        },
        {
          text:  'Average',
          align: 'center',
          value: 'average',
        },
        ...this.problemsByChapter
          .find(chapter => chapter.index === this.currentDashboard).problems
          .map(problem => ({
            text:  problem.title,
            align: 'center',
            value: problem.id,
          })),
        {
          width: '100%',
        },
      ]
    },
    chapterData () {
      return this.users.map(user => ({
        locked:    !!user.lockChapters.find(index => index === this.currentDashboard),
        username:  user.username,
        studentId: user.studentId,
        average:   user[this.currentDashboard],
        ...this.problemsByChapter
          .find(chapter => chapter.index === this.currentDashboard).problems
          .reduce((problemList, problem) => {
            problemList[problem.id] = user.scoreByProblem[problem.id] || 0
            return problemList
          }, {}),
      }))
    },
    updatePermissionMessage () {
      if (this.updatePermissionModal.currentPermission == 0) {
        return `Do you sure you want to remove ${this.updatePermissionModal.username}'s admin permission?`
      }
      else if (this.updatePermissionModal.currentPermission == 1) {
        return `Do you sure you want to grant ${this.updatePermissionModal.username} an admin permission?`
      }
      return ''
    },
  },
  methods: {
    ...mapMutations('feature', ['setNotification']),
    fetchUsers () {
      axios.get('/users')
        .then(res => {
          this.users = res.data.users
          this.users.forEach(user => {
            // If user's permission is 0, it means he/she is an administrater
            user.isAdmin = !user.permission

            // Add problem information to user's progress
            user.progressInfo.forEach(problem => {
              const problemInfo = this.problems.find(_problem => _problem.id === problem.problemId)
              problem.chapter = problemInfo.chapter ? problemInfo.chapter.index : ''
              problem.title = problemInfo.title
            })

            // Create a score look-up table, where key is the problemId
            // and value is the score of that problem.
            user.scoreByProblem = user.progressInfo.reduce((problemList, problem) => {
              problemList[problem.problemId] = problem.score
              return problemList
            }, {})

            // Calculate average score of each chapter
            this.problemsByChapter.forEach(chapter => {
              user[chapter.index] = Math.round(
                chapter.problems.reduce(
                  (scoreSum, problem) => (user.scoreByProblem[problem.id] || 0) + scoreSum,
                  0,
                ) / chapter.problems.length,
              )
            })
          })
        })
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    refreshToken () {
      this.refreshTokenModal.isLoading = true
      axios.post('/manage/generate/examToken')
        .then(() => this.fetchUsers())
        .then(() => {
          this.setNotification({
            isOpen:  true,
            type:    'success',
            message: 'Exam token is updated.',
          })
        })
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
        })
        .finally(() => {
          this.refreshTokenModal.isLoading = false
          this.refreshTokenModal.isOpen = false
        })
    },
    downloadTable () {
      this.isDownloading = true
      let data = 'username, studentId, examToken, '
      const gradedProblems = this.problemsByChapter.reduce(
        (problemList, chapter) => problemList.concat(chapter.problems),
        [],
      )

      gradedProblems.forEach(problem => {
        data += `"${problem.chapter.index} - ${problem.title}", `
      })
      this.users.forEach(user => {
        data += `\n${user.username}, ${user.studentId || ''}, ${user.examToken}, `
        gradedProblems.forEach(problem => {
          data += `${user.scoreByProblem[problem.id] || 0}, `
        })
      })

      // Create an invisible download button and click it programmatically
      // to download the dashboard as a .csv file
      const tmp = document.createElement('a')

      tmp.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data))
      tmp.setAttribute('download', `CK_Judge_dashboard_${(new Date()).toISOString()}.csv`)

      tmp.style.display = 'none'
      document.body.appendChild(tmp)

      tmp.click()

      document.body.removeChild(tmp)
      this.isDownloading = false
    },
    confirmUpdatePermission (username, currentPermission) {
      this.updatePermissionModal.isOpen = true
      this.updatePermissionModal.username = username
      this.updatePermissionModal.currentPermission = currentPermission
    },
    updatePermission () {
      this.updatePermissionModal.isLoading = true
      axios.put(`/users/${this.updatePermissionModal.username}/permission`, {
        username:   this.updatePermissionModal.username,
        permission: Number(!this.updatePermissionModal.currentPermission),
      })
        .then(() => {
          this.setNotification({
            isOpen:  true,
            type:    'success',
            message: 'User permisssion updated.',
          })
        })
        .then(() => this.fetchUsers())
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
        })
        .finally(() => {
          this.updatePermissionModal.isLoading = false
          this.updatePermissionModal.isOpen = false
        })
    },
  },
  mounted () {
    this.fetchUsers()
    this.timer = setInterval(this.fetchUsers, 30000)
  },
  beforeDestroy () {
    clearInterval(this.timer)
  },
}
</script>

<style>
.v-data-table-header th {
  white-space: nowrap;
}
</style>
