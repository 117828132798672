<template lang="pug">
div.not-found
  img.not-found-img(src="@/assets/404.png")
  div.rich-text
    span= "Seems like you got lost... "
    router-link(to="/") Go back home.
</template>

<script>
export default {}
</script>

<style lang="scss">
$nav-bar-height: 126px;

.not-found {
  display: grid;
  justify-items: center;
  align-content: center;
  grid-template-rows: min-content auto;
  grid-template-columns: 100%;
  width: 100%;
  min-height: calc(100vh - 2 * #{$nav-bar-height});

  .not-found-img {
    margin-bottom: 40px;
    max-width: 500px;
    width: 50%;
    min-width: 300px;
    height: auto;
  }
}
</style>
