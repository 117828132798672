<template lang="pug">
div
  input(
    name="discussion"
    type="checkbox"
    :id="`discussion-${discussion.id}`"
    @change="isOpen = !isOpen")
  label(:for="`discussion-${discussion.id}`")
    floating-card.discussion-card
      v-container.pa-0(fluid)
        v-row(align="center")
          v-col(cols="auto")
            v-avatar(size="48")
              //- Display avatar if it is existing
              img(
                v-if="discussion.author.avatar"
                :src="discussion.author.avatar"
                :alt="discussion.author.username")
              //- Else, display account icon
              v-icon(v-else size="48" color="#B5B5B5") $profile
          v-col(cols="8")
            p.mb-0.text-h6.text-truncate {{ discussion.title }}
            p.mb-0.text-truncate {{ discussion.author.username }} asked at {{ dateToDateString(discussion.createdAt) }}
          v-spacer
          v-col.text-end(cols="auto")
            v-btn(
              icon
              large
              :disabled="isPinning === discussion.id"
              :loading="isPinning === discussion.id"
              @click.stop="togglePin(discussion.id)")
              v-icon(v-if="discussion.pinned" size="32") mdi-pin
              v-icon(v-else size="32" color="#F9F9F9B3") mdi-pin-off
        v-expand-transition
          v-row(v-show="isOpen")
            v-col(cols="12")
              v-divider
            v-col(cols="12")
              div.rich-text(v-html="discussion.content")
</template>

<script>
import axios from '@/plugins/axios.js'
import { mapMutations } from 'vuex'
import FloatingCard from '@/components/cards/FloatingCard.vue'

export default {
  name:  'ManageDiscussionCard',
  props: {
    discussion: {
      type:     Object,
      required: true,
      default:  () => {},
    },
  },
  components: {
    FloatingCard,
  },
  data () {
    return {
      isPinning: false,
      isOpen:    false,
    }
  },
  methods: {
    ...mapMutations('feature', ['setNotification']),
    togglePin () {
      this.isPinning = true
      axios.put(`/discussions/${this.discussion.id}/pinned`, {
        pinned: !this.discussion.pinned,
      })
        .then(() => {
          this.discussion.pinned = !this.discussion.pinned
          this.$emit('change')
          this.setNotification({
            isOpen:  true,
            type:    'success',
            message: `The post is ${this.discussion.pinned ? 'pinned' : 'unpinned'}`,
          })
        })
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
        })
        .finally(() => {
          // Clear loading state
          this.isPinning = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
input {
  display: none;
}

.discussion-card {
  cursor: pointer;

  .rich-text {
    cursor: default;
  }
}
</style>
