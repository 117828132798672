import axios from '@/plugins/axios.js'

const state = { games: [] }

const getters = { getGames: state => state.games }

const mutations = {
  setGames: (state, data) => {
    if (!Array.isArray(data)) {
      throw new Error('Wrong data type of "data "parameter in setGames')
    }
    state.games = data.slice()
  },
}

const actions = {
  async fetchGames ({ commit }) {
    const { data } = await axios.get('/games')
    commit('setGames', data)
  },
  async buyGameById ({ commit }, id) {
    const { data } = await axios.post(`/games/purchase/${id}`)
    commit('/user/addGame', id, { root: true })
    console.log(id, data)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
