import * as PIXI from 'pixi.js'

class Dialogue {
  constructor (app) {
    this.app = app
    this.fontSize = 16
    this.padding = 16
    this.container = new PIXI.Container()
    const pane = new PIXI.Graphics()
    pane.lineStyle(2, 0x647086, 1)
    pane.beginFill(0x262d37, 1)
    pane.drawRoundedRect(0, 0, Math.min(this.app.view.width * 0.9, 1000), this.padding * 6 + this.fontSize * 5, 16)
    pane.endFill()
    this.container.addChild(pane)
    this.container.name = 'Dialogue'
    this.container.position.set(
      (this.app.view.width - this.container.width) / 2,
      this.app.view.height - this.container.height - this.padding * 3,
    )
    this.container.visible = false
  }

  // TODO: Implement line display
}

export default Dialogue
