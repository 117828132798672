<template lang="pug">
//- Chapter list
one-column-layout
  //- Title of homework section
  heading Homework
  //- Homework card list
  tile-list(
    no-result-message="No homework yet :D"
    :items="homeworkList")
    template(v-slot:default="{item}")
      //- Each homework card
      chapter-card(
        :key="`homework-card-${item.index}`"
        :index="item.index"
        :hidden="!!item.hidden"
        :position="item.position"
        :progress="chapterProgress(item.index)"
        :deadlineStatus="deadlineStatus(item)"
        :path="`/homework/${item.index}`") {{ item.title }}
  //- Title of exam section
  heading Exam
  //- Exam card list
  tile-list(
    no-result-message="No exam yet :D"
    :items="examList")
    template(v-slot:default="{item}")
      //- Each exam card
      chapter-card(
        :key="`exam-card-${item.index}`"
        :index="item.index"
        :hidden="!!item.hidden"
        :position="item.position"
        :progress="chapterProgress(item.index)"
        :deadlineStatus="deadlineStatus(item)"
        :path="`/exam/${item.index}`") {{ deadlineStatus(item) }}
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import OneColumnLayout from '@/layouts/OneColumnLayout.vue'
import Heading from '@/components/common/Heading.vue'
import TileList from '@/components/lists/TileList.vue'
import ChapterCard from '@/components/cards/ChapterCard.vue'

export default {
  components: {
    OneColumnLayout,
    Heading,
    TileList,
    ChapterCard,
  },
  computed: {
    // The chapter information is prefetched in the landing step in `router/index.js`
    ...mapState('chapter', ['homeworkList', 'examList']),
    ...mapGetters('chapter', ['deadlineStatus']),
    ...mapGetters('user', ['chapterProgress']),
  },
}
</script>
