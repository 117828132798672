<template lang="pug">
two-columns-layout
  //- Course video section
  section.mb-11
    heading
      div.section-heading(@click="isVideoOpen = !isVideoOpen")
        v-icon.mr-3(
          size="16"
          color="#F9F9F9B3"
          :style="{'transform': isVideoOpen ? 'rotate(0)': 'rotate(-90deg)'}") $triangle
        span Course video
    v-expand-transition
      div(v-if="isVideoOpen")
        p.ma-0(v-if="homework.videoUrl.length === 0") This chapter has no course video.
        template(v-else)
          //- TODO: resolve CORS cookie issue
          iframe(
            v-for="(url, index) in homework.videoUrl"
            :key="`course-video-${index}`"
            :src="url"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen)
  //- Quick review section
  section.mb-11
    heading
      div.section-heading(@click="isReviewOpen = !isReviewOpen")
        v-icon.mr-3(
          size="16"
          color="#F9F9F9B3"
          :style="{'transform': isReviewOpen ? 'rotate(0)': 'rotate(-90deg)'}") $triangle
        span Quick review
    v-expand-transition
      div(v-if="isReviewOpen")
        floating-card
          div.rich-text(
            v-if="homework.review"
            v-html="homework.review")
          p(v-else) This chapter has no quick review.
  //- Exercises section
  section.mb-11
    heading Exercises
    div
      //- Each problem card, jump to problem page on click
      p(v-if="exercises.length === 0") This chapter has no exercise.
      exercise-card.mb-5(
        v-else
        v-for="exercise in exercises"
        :key="`exercise-card-${exercise.id}`"
        :exercise="exercise")
  //- Homework section
  section.mb-11
    heading Programming problems
    //- Problem cards of homework
    div
      //- Each problem card, jump to problem page on click
      p(v-if="problems.length === 0") This chapter has no homework.
      problem-card.mb-5(
        v-else
        v-for="problem in problems"
        :key="`problem-card-${problem.id}`"
        :problem="problem")
  //- Info card
  template(v-slot:aside)
    //- Index and title
    div
      h2 {{ homework.index }}
      h3.font-weight-medium {{ homework.title }}
    //- Progress
    progress-ring.mx-auto.my-10(
      :progress="chapterProgress(homework.index)"
      :color="homework.position"
      :size="170")
    //- Deadline information, including due day and description
    div
      template(v-if="status === 'Closed'")
        p.font-size-20.mb-2 This chapter is closed.
        p.ma-0 The deadline of this chapter is exceeded. You can still submit your code, but the score will not be updated.
      template(v-else-if="status === 'Make up'")
        p.font-size-20.mb-2 Make-up deadline: {{ dateToDateString(homework.dueTime) }}
        p.ma-0 From now on, your submission will be considered as late submission. You can still hand in your homework, but you will only get 70% of the score for each submission.
      template(v-else-if="status === 'On going'")
        p.font-size-20.ma-2 Deadline: {{ dateToDateString(homework.makeUpTime) }}
      template(v-else)
        p.font-size-20.ma-2 (No deadline)
</template>

<script>
import axios from '@/plugins/axios.js'
import store from '@/store'
import { mapState, mapGetters, mapMutations } from 'vuex'
import TwoColumnsLayout from '@/layouts/TwoColumnsLayout.vue'
import Heading from '@/components/common/Heading.vue'
import Loading from '@/components/common/Loading.vue'
import ProgressRing from '@/components/common/ProgressRing.vue'
import FloatingCard from '@/components/cards/FloatingCard.vue'
import ProblemCard from '@/components/cards/ProblemCard.vue'
import ExerciseCard from '@/components/cards/ExerciseCard.vue'

export default {
  components: {
    TwoColumnsLayout,
    Heading,
    Loading,
    ProblemCard,
    FloatingCard,
    ProgressRing,
    ExerciseCard,
  },
  data () {
    return {
      isVideoOpen:  false,
      isReviewOpen: false,
      homework:     {
        // Index of this chapter ('Lec01' for example)
        index:      '',
        // Title of this chapter ('Introducing C' for example)
        title:      '',
        // Serial number of this chapter
        position:   0,
        // Is this chapter hidden from non-admin user
        hidden:     false,
        // The starting time of make-up period
        makeUpTime: new Date(),
        // The actual deadline of this chapter
        dueTime:    new Date(),
        // Quick reveiw note
        review:     '',
        // Url of the youtube video of this chapter
        videoUrl:   [],
      },
      exercises: [],
    }
  },
  computed: {
    ...mapState('chapter', ['homeworkList']),
    ...mapGetters('chapter', ['deadlineStatus']),
    ...mapGetters('user', ['chapterProgress']),
    ...mapGetters('problem', ['chapterProblems']),
    status () {
      return this.deadlineStatus(this.homework)
    },
    problems () {
      return this.chapterProblems(this.homework.index)
    },
  },
  methods: {
    ...mapMutations('feature', ['setPageLoading', 'setNotification']),
    setHomework (index, error) {
      if (error) {
        this.setNotification({
          isOpen:  true,
          type:    'error',
          message: error,
        })
        return
      }

      this.homework = this.homeworkList[index]
      this.setTitle(`${this.homework.index} - ${this.homework.title}`)

      axios.get(`/exercises?chapter=${index}`)
        .then(res => {
          this.exercises = res.data.exercises.sort((a, b) => (a.title > b.title ? 1 : -1))
        })
        // If there is any error, show notification and clear loading state
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
        })
        .finally(() => {
          this.setPageLoading(false)
        })
    },
  },
  beforeRouteEnter (to, _, next) {
    store.commit('feature/setPageLoading', true)
    // TODO: fetch data, not only title
    axios.get(`/homework/${to.params.index}`)
      .then(res => {
        to.meta.breadcrumbs = to.meta._breadcrumbs.concat([
          {
            text:     `${res.data.index} - ${res.data.title}`,
            disabled: true,
          },
        ])
        next(vm => vm.setHomework(to.params.index, null))
      })
      .catch(error => {
        if(error.response.status === 404) {
          next('/notFound')
        }
        else {
          next(vm => vm.setHomework(null, error.response.data))
        }
      })
  },
  beforeRouteUpdate (to, _, next) {
    this.setPageLoading(true)
    axios.get(`/homework/${to.params.index}`)
      .then(res => {
        to.meta.breadcrumbs = to.meta._breadcrumbs.concat([
          {
            text:     `${res.data.index} - ${res.data.title}`,
            disabled: true,
          },
        ])
        this.setHomework(to.params.index, null)
        next()
      })
      .catch(error => {
        if(error.response.status === 404) {
          next('/notFound')
        }
        else {
          this.setHomework(null, error.response.data)
          next()
        }
      })
  },
}
</script>

<style lang="scss" scoped>
.section-heading {
  cursor: pointer;
}

iframe {
  width: 100%;
  height: 692px;
}

.font-size-20 {
  font-size: 20px !important;
}
</style>
