import Vue from 'vue'
import Vuex from 'vuex'

import feature from '@/store/modules/feature'
import announcement from '@/store/modules/announcement'
import chapter from '@/store/modules/chapter'
import problem from '@/store/modules/problem'
import game from '@/store/modules/game'
import user from '@/store/modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
  state:     {},
  mutations: {},
  modules:   {
    feature,
    announcement,
    chapter,
    problem,
    game,
    user,
  },
})
