<template lang="pug">
authentication-layout
  v-form.d-flex.flex-column.justify-start.align-center
    //- Form title
    h2.text-h5.text-md-h4.font-weight-bold.text-center Forgot your password?
    //- Description text
    section.mt-6
      p.text-center.text-body-1 Enter your user account's verified email address and we will send you a password reset link.
    //- E-mail field
    div.mt-9.d-flex.align-start
      v-icon.mr-5(size="36" color="#B5B5B5") $email
      v-text-field.mt-0.pt-0(
        v-model="email"
        required
        rounded
        light
        single-line
        height="36"
        type="email"
        label="E-mail"
        background-color="#B5B5B5"
        :error-messages="emailErrors"
        @keyup.enter.prevent="sendResetEmail()")
    //- Send e-mail button
    primary-button.mt-10(
      :isWaiting="isWaiting"
      @click="sendResetEmail()") Send
</template>

<script>
import axios from '@/plugins/axios.js'
import { mapMutations } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import AuthenticationLayout from '@/layouts/AuthenticationLayout.vue'
import PrimaryButton from '@/components/buttons/PrimaryButton.vue'

export default {
  components: {
    AuthenticationLayout,
    PrimaryButton,
  },
  data () {
    return {
      email:     '',
      isWaiting: false,
    }
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  computed: {
    // E-mail field error message handling
    emailErrors () {
      const errors = []
      if (!this.$v.email.$dirty) {
        return errors
      }
      !this.$v.email.email && errors.push('E-mail pattern not matched.')
      !this.$v.email.required && errors.push('E-mail is required.')
      return errors
    },
  },
  methods: {
    ...mapMutations('feature', ['setPopup']),
    async sendResetEmail () {
      // Ensure there is no invalid field before sending request
      this.$v.$touch()
      if (!this.$v.$anyError) {
        // Disable send e-mail button
        this.isWaiting = true

        // Send forgot password request
        await axios.post('/user/password/forgot', { email: this.email })
          // If successed, show message popup to hint user to check e-mail
          .then(() => {
            this.setPopup({
              isOpen:  true,
              type:    'message',
              title:   'E-mail sent',
              content: 'Check your e-mail for a link to reset your password. If it doesn\'t appear within a few minutes, check your spam folder.',
            })
          })
          // If there is any error, show message popup to display error message
          .catch(error => {
            this.setPopup({
              isOpen:  true,
              type:    'error',
              title:   'Error',
              content: error.response.data,
            })
          })
          // Re-enable send e-mail button
          .finally(() => {
            this.isWaiting = false
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.v-form {
  max-width: 400px;
}
.v-text-field {
  max-width: 300px;
}
</style>
