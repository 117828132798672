<template lang="pug">
floating-card(:to="`/manage/problems/problem/${problem.id}`")
  v-container.pa-0(fluid)
    v-row(align="center")
      v-col(cols="12" sm="6")
        //- Title
        p.text-h6.text-truncate {{ problem.title }}
        //- Tags
        //- TODO: implement tags group
        div.flex-center
          v-chip.mr-3(
            v-for="tag in problem.tags"
            :key="`problem-${problem.id}-tag-${tag}`"
            color="#B5B5B5"
            text-color="#30333F"
            @click.prevent="$emit('search-tag', tag)") {{ tag }}
      v-spacer
      v-col(cols="4" sm="2" lg="auto")
        //- Chapter
        v-chip(
          v-if="problem.chapter"
          color="#F9F9F9"
          text-color="#30333F") {{ problem.chapter.index }}
      v-col.text-center(cols="4" sm="2" lg="auto")
        //- Difficulty (level)
        v-chip(:color="levelOption[problem.level].color") {{ levelOption[problem.level].name }}
      v-col(cols="2" sm="1" lg="auto")
        //- Hide problem button
        v-btn(
          icon
          large
          :loading="isHiding"
          :disabled="isHiding"
          @click.prevent="toggleHidden")
          v-icon(
            v-if="problem.hidden"
            size="32"
            color="#F9F9F9B3") $eye-close
          v-icon(v-else size="32") $eye-open
      v-col(cols="2" sm="1" lg="auto")
        //- Delete problem button
        v-btn(
          icon
          large
          :loading="isDeleting"
          :disabled="isDeleting"
          @click.prevent="isDeleting = true")
          v-icon(size="32" color="#FF6E6E") $trashcan
    //- Confirm delete modal
    modal(:isOpen="isDeleting")
      template(v-slot:title)
        span Delete "{{ problem.title }}"
      template(v-slot:content)
        p Do you really want to delete "{{ problem.title }}"?
      template(v-slot:actions)
        modal-button.mr-5(@click="remove()") Delete
        modal-button(hollow @click="isDeleting = false") Cancel
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import FloatingCard from '@/components/cards/FloatingCard.vue'
import Modal from '@/components/common/Modal.vue'
import ModalButton from '@/components/buttons/ModalButton.vue'

export default {
  name:  'ManageProblemCard',
  props: {
    problem: {
      type:     Object,
      required: true,
      default:  () => {},
    },
  },
  components: {
    FloatingCard,
    Modal,
    ModalButton,
  },
  data () {
    return {
      isHiding:   false,
      isDeleting: false,
    }
  },
  computed: {
    ...mapState('problem', ['levelOption']),
  },
  methods: {
    ...mapMutations('feature', ['setNotification']),
    ...mapActions('problem', ['updateProblem', 'deleteProblem']),
    toggleHidden () {
      this.isHiding = true
      const formData = new FormData()
      formData.append('hidden', !this.problem.hidden)

      this.updateProblem({
        id:      this.problem.id,
        payload: formData,
      })
        .then(data => {
          this.setNotification({
            isOpen:  true,
            type:    'success',
            message: `Problem is ${data.hidden ? 'hidden' : 'open'}!`,
          })
        })
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
        })
        .finally(() => {
          this.isHiding = false
        })
    },
    remove () {
      this.deleteProblem({
        id: this.problem.id,
      })
        // If success, show success message and return to chapter list
        .then(() => {
          this.setNotification({
            isOpen:  true,
            type:    'success',
            message: 'Problem deleted!',
          })
        })
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
        })
        .finally(() => {
          this.isDeleting = false
        })
    },
  },
}
</script>
