<template lang="pug">
  v-row(justify="center")
    v-col(cols="10" xl="8" lg="8" md="9" sm="10")
      v-card
        v-toolbar(flat)
          v-toolbar-title {{ currentGame.name }}
          v-spacer
          v-btn(color="primary" dark @click="buyButtonHandler") {{ statusSyntax }}
        v-divider
        v-container
          v-row
            v-col(cols="4")
              h4(style="display: inline" class="mr-2") Preview:
          v-img(
            alt="headshot"
            v-bind:src="require('../' + (currentGame._id-1) + '.png')"
            aspect-ratio="1.7"
            contain)
          v-row
            v-col(cols="4")
              h4(style="display: inline" class="mr-2") Cost:
              label {{ currentGame.coinPrice }}
          v-row
            v-col
              h4 Description
              v-divider
              v-container
                article(v-html="currentGame.description")
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import axios from '@/plugins/axios'

export default {
  name: 'GameDetail',
  data () {
    return {
      currentGame: {
        id:          '-1',
        name:        'Not found',
        description: 'No description',
      },
    }
  },
  computed: {
    ...mapState('game', { games: 'games' }),
    ...mapState('user', { userGames: 'games' }),
    statusSynta () {
      return this.userGames.includes(parseInt(this.currentGame._id))
        ? 'Play!'
        : 'Buy It!'
    },
  },
  async beforeMount () {
    try {
      const { data } = await axios.get(`/games/${this.$route.params.id}`)
      this.currentGame = data
      console.log(data)
    }
    catch ({ response }) {
      console.log(response)
    }
  },
  methods: {
    ...mapMutations('', ['SET_notification']),
    ...mapActions('game', ['fetchGames', 'buyGameById']),
    async buyButtonHandler () {
      try {
        console.log(this.currentGame.link)
        await this.buyGameById(this.$route.params.id)
        window.open(this.currentGame.link, '_blank')
      }
      catch ({ response }) {
        this.SET_notification({
          isOpen:    true,
          color:   'error',
          message: response.data,
        })
      }
    },
  },
}
</script>
