<template lang="pug">
div.mb-4
  p {{ label }}
  v-input(
    :value="value"
    :rules="activeRules")
    tiptap-vuetify.rich-text-editor(
      :toolbar-attributes="{ light: true }"
      :card-props="{ color: 'transparent' }"
      :editor-properties="{ editorProps: { attributes: { class: 'rich-text' } } }"
      :value="value"
      :extensions="extensions"
      @input="change")
</template>

<script>
// Import the component and the necessary extensions
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  CodeBlock,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Image,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from 'tiptap-vuetify'

export default {
  name:       'RichTextEditor',
  components: { TiptapVuetify },
  props:      {
    value: {
      type:     String,
      required: true,
      default:  '',
    },
    label: {
      type:     String,
      required: false,
      default:  '',
    },
    required: {
      type:     Boolean,
      required: false,
      default:  false,
    },
  },
  data () {
    return {
      dirty:      false,
      extensions: [
        History,
        Bold,
        Italic,
        Underline,
        Strike,
        Blockquote,
        Link,
        Code,
        CodeBlock,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        ListItem,
        BulletList,
        OrderedList,
        Image,
        HorizontalRule,
        HardBreak,
        Paragraph,
      ],
      validations: {
        required: value => !(value === '' || value === '<p></p>') || 'Required',
      },
    }
  },
  computed: {
    activeRules () {
      const rules = []
      if(this.required) {
        rules.push(this.validations.required)
      }
      return rules
    },
  },
  methods: {
    // TODO: sync the dirty value with the one in its view component
    // TODO: what if this component is used in nested component?
    change (value) {
      if (!this.dirty) {
        this.dirty = true
        this.$emit('change')
      }
      this.$emit('input', value)
    },
    beforeWindowUnload (e) {
      e.preventDefault()
      e.returnValue = ''
      return ''
    },
  },
  // TODO: implement this behavior on v-form rather than here
  watch: {
    dirty (value) {
      if (value === true) {
        window.addEventListener('beforeunload', this.beforeWindowUnload)
      }
    },
  },
  beforeDestroy () {
    window.removeEventListener('beforeunload', this.beforeWindowUnload)
  },
}
</script>

<style lang="scss">
.v-sheet.v-toolbar {
  border-radius: 5px 5px 0 0;
}

.rich-text-editor {
  width: 100%;
  & .tiptap-vuetify-editor__content {
    border: 1px solid #f9f9f9;
    border-radius: 0 0 5px 5px;

    pre code {
      display: inline-block;
      padding: 0px;
    }
  }
}
</style>
