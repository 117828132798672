import * as PIXI from 'pixi.js'
import { gsap } from 'gsap'

class Scene {
  constructor (app) {
    this.app = app
    this.timeline = gsap.timeline()
    this.fadeFilter = new PIXI.filters.AlphaFilter()
    // Transition effect duration
    this.duration = 0.25
  }

  load () {
    this.container = new PIXI.Container()
    this.container.visible = false
  }

  unload () {
    this.app.stage.removeChild(this.container)
    this.container.destroy()
  }

  // Fade in currently active scene
  fadeIn () {
    return new Promise(resolve => {
      // Add filter to the container that is on the top of the stack
      this.container.filters = [this.fadeFilter]
      // Animate fadeFilter.alpha from 0 to 1
      this.timeline.fromTo(this.fadeFilter,
        {
          alpha: 0,
        },
        {
          alpha:      1,
          duration:   this.duration,
          onStart:    () => {
            this.container.visible = true
          },
          onComplete: () => {
            resolve()
          },
        })
    })
  }

  // Fade out currently active scene
  fadeOut () {
    return new Promise(resolve => {
      // Add filter to the container that is on the top of the stack
      this.container.filters = [this.fadeFilter]
      // Animate fadeFilter.alpha from 1 to 0
      this.timeline.fromTo(this.fadeFilter,
        {
          alpha: 1,
        },
        {
          alpha:        0,
          duration:     this.duration,
          onComplete:   () => {
            this.container.visible = false
            resolve()
          },
        })
    })
  }
}

export default Scene
