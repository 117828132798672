<template lang="pug">
div
  heading Setting
  v-list.py-0.clickable-list(color="transparent" flat)
    v-list-item(to="/setting/profile")
      span(:class="{ 'current': isCurrentRoute('setting-profile') }") Profile
    v-list-item(to="/setting/password")
      span(:class="{ 'current': isCurrentRoute('setting-password') }") Password
    v-list-item(to="/setting/authenticate")
      span(:class="{ 'current': isCurrentRoute('setting-authenticate') }") Authenticate
</template>

<script>
import Heading from '@/components/common/Heading.vue'

export default {
  name:       'SettingMenu',
  components: {
    Heading,
  },
  methods: {
    isCurrentRoute (name) {
      return this.$route.name === name
    },
  },
}
</script>

<style lang="scss" scoped>
.v-list-item {
  font-size: 20px;
}

.current {
  color: #FFD06F;
}
</style>
