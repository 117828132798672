<template lang="pug">
div
  bread-crumbs.px-7
  problem-layout
    template(v-slot:info)
      problem-information(:problem="problem")
    template(v-slot:editor)
      code-editor(
        :problemId="problem.id"
        :defaultCode="problem.templateCode")
</template>

<script>
import axios from '@/plugins/axios.js'
import store from '@/store'
import { mapState, mapMutations } from 'vuex'
import BreadCrumbs from '@/components/common/BreadCrumbs.vue'
import ProblemLayout from '@/layouts/ProblemLayout.vue'
import ProblemInformation from '@/components/problem/ProblemInformation.vue'
import CodeEditor from '@/components/problem/CodeEditor.vue'

export default {
  components: {
    BreadCrumbs,
    ProblemLayout,
    ProblemInformation,
    CodeEditor,
  },
  data () {
    return {
      // Problem detail
      problem:   {
        id:           '',
        title:        'Loading...',
        description:  'Loading...',
        inputFormat:  'Loading...',
        outputFormat: 'Loading...',
        level:        'Easy',
        tags:         [],
        totalRequest: 0,
        acRequest:    0,
        timeLimit:    0,
        memLimit:     0,
        samples:      [],
        loaderCode:   '',
        templateCode: '',
        hint:         '',
      },
    }
  },
  computed: {
    ...mapState('problem', ['problems']),
  },
  methods: {
    ...mapMutations('feature', ['setPageLoading', 'setNotification']),
    setProblem (problem, error) {
      if (error) {
        this.setNotification({
          isOpen:  true,
          type:    'error',
          message: error,
        })
        return
      }

      this.problem = problem

      this.setTitle(this.problem.title)

      this.setPageLoading(false)
    },
  },
  beforeRouteEnter (to, _, next) {
    store.commit('feature/setPageLoading', true)
    axios.get(`/problems/${to.params.id}`)
      .then(res => {
        const problem = res.data

        to.meta.breadcrumbs = to.meta._breadcrumbs.concat([
          {
            text:     problem.title,
            disabled: true,
          },
        ])
        next(vm => vm.setProblem(problem, null))
      })
      .catch(error => {
        if(error.response.status === 404) {
          next('/notFound')
        }
        else {
          next(vm => vm.setProblem(null, error.response.data))
        }
      })
  },
  beforeRouteUpdate (to, _, next) {
    this.setPageLoading(true)
    axios.get(`/problems/${to.params.id}`)
      .then(res => {
        const problem = res.data

        to.meta.breadcrumbs = to.meta._breadcrumbs.concat([
          {
            text:     problem.title,
            disabled: true,
          },
        ])
        this.setProblem(problem, null)
        next()
      })
      .catch(error => {
        if(error.response.status === 404) {
          next('/notFound')
        }
        else {
          this.setProblem(null, error.response.data)
          next()
        }
      })
  },
}
</script>
