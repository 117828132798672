import axios from '@/plugins/axios.js'

const state = {
  announcements: [],
  types:          {
    announcement: {
      color: '#FF6E6E',
      icon:  '$speaker',
    },
    homework: {
      color: '#83A3CD',
      icon:  '$code',
    },
    bonus: {
      color: '#75AA84',
      icon:  '$chat',
    },
  },
}

const getters = {
  announcementById (state) {
    return id => state.announcements.find(announcement => announcement.id === id)
  },
}

const mutations = {
  setAnnouncements (state, data) {
    state.announcements = data.announcements
    state.announcements.forEach(announcement => {
      announcement.timestamp = new Date(announcement.timestamp)
    })
  },
}

const actions = {
  fetchAnnouncements ({ commit }) {
    return axios.get('/announcements')
      .then(res => {
        commit('setAnnouncements', res.data)
      })
      .catch(error => {
        throw error
      })
  },
  createAnnouncement ({ dispatch }, data) {
    return axios.post('/announcements', data)
      .then(() => {
        dispatch('fetchAnnouncements')
      })
      .catch(error => {
        throw error
      })
  },
  updateAnnouncement ({ dispatch }, data) {
    return axios.patch(`/announcements/${data.id}`, data)
      .then(() => {
        dispatch('fetchAnnouncements')
      })
      .catch(error => {
        throw error
      })
  },
  deleteAnnouncement ({ dispatch }, data) {
    return axios.delete(`/announcements/${data.id}`)
      .then(() => {
        dispatch('fetchAnnouncements')
      })
      .catch(error => {
        throw error
      })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
