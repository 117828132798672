<template lang="pug">
two-columns-layout
  //- List heading
  heading Bonus
  card-list(:items="bonuses")
    template(v-slot:default="{item}")
      bonus-card(:bonus="item")
  //- Notice of bonus forum
  template(v-slot:aside)
    h2.mb-3 Notice
    div.rich-text(v-if="notice" v-html="notice")
    loading(v-else)
</template>

<script>
import axios from '@/plugins/axios.js'
import store from '@/store'
import { mapMutations } from 'vuex'
import TwoColumnsLayout from '@/layouts/TwoColumnsLayout.vue'
import Heading from '@/components/common/Heading.vue'
import CardList from '@/components/lists/CardList.vue'
import BonusCard from '@/components/cards/BonusCard.vue'
import Loading from '@/components/common/Loading.vue'

export default {
  components: {
    TwoColumnsLayout,
    Heading,
    BonusCard,
    CardList,
    Loading,
  },
  data () {
    return {
      notice:    '',
      bonuses:   [],
    }
  },
  methods: {
    ...mapMutations('feature', ['setPageLoading', 'setNotification']),
    setBonuses (bonuses, error) {
      if (error) {
        this.setNotification({
          isOpen:  true,
          type:    'error',
          message: error,
        })
        return
      }

      this.bonuses = bonuses
      this.bonuses.forEach(post => {
        post.createdAt = new Date(post.createdAt)
      })

      this.setPageLoading(false)
    },
  },
  mounted () {
    // Fetch notice of bonus forum
    axios.get('/static/bonus')
      .then(res => {
        this.notice = res.data
      })
      // If request failed, display error message
      .catch(error => {
        this.setNotification({
          isOpen:  true,
          type:    'error',
          message: error.response.data,
        })
      })
  },
  beforeRouteEnter (to, from, next) {
    store.commit('feature/setPageLoading', true)
    axios.get('/bonuses')
      .then(res => {
        next(vm => vm.setBonuses(res.data.bonuses, null))
      })
      .catch(error => {
        next(vm => vm.setBonuses(null, error.response.data))
      })
  },
}
</script>
