import * as PIXI from 'pixi.js'

class IconButton {
  constructor ({ name = null, icon, handler = () => {} }) {
    const button = PIXI.Sprite.from(icon)
    button.name = name
    button.interactive = true
    button.buttonMode = true
    button.on('pointerdown', handler)
    return button
  }
}

export default IconButton
