<template lang="pug">
v-container.pa-0(fluid)
  v-row
    v-col
      heading.mb-2 {{ problem.title }}
        template(v-slot:action)
          //- Link to submissions page
          white-button(:to="`/problems/${problem.id}/submissions`") Submissions
  v-row(dense)
    v-col(cols="auto")
      //- Difficulty
      v-chip(:color="levelOption[problem.level].color") {{ levelOption[problem.level].name }}
    v-col(cols="auto")
      //- Pass rate
      span.text-h6 Pass rate: {{ passRate }}% ({{ problem.acRequest }}/{{ problem.totalRequest }})
  v-row
    v-col
      //- Description
      div.rich-text(v-html="problem.description")
  v-row
    v-col
      //- Input format
      h3.mb-5 Input
      p.mb-1(v-for="line in inputFormatText") {{ line }}
  v-row
    v-col
      //- Output format
      h3.mb-5 Output
      p.mb-1(v-for="line in outputFormatText") {{ line }}
  v-row(v-if="problem.loaderCode")
    v-col
      //- Loader code
      div.expand-header(@click="isLoaderCodeOpen = !isLoaderCodeOpen")
        v-icon.mr-2(
          size="14"
          color="#F9F9F9B3"
          :style="{'transform': isLoaderCodeOpen ? 'rotate(0)': 'rotate(-90deg)'}") $triangle
        h3 Loader Code
      v-expand-transition
        div(v-show="isLoaderCodeOpen")
          p Your code will be judge using this program:
          pre(@click="copyToClipboard($event)") {{ problem.loaderCode }}
  //- Each sample case
  v-row(v-if="sampleCases.length === 0")
    v-col
      p No example
  v-row(
    v-else
    v-for="(sample, index) of sampleCases"
    :key="`sample-${index}`")
    v-col
      //- Sample case index
      p.text-h6.mb-1 Example {{ index + 1 }}
      //- Sample input
      p.text-subtitle-1 Input
      pre.mb-3(@click="copyToClipboard($event)")
        template(v-for="line in sample.input") {{ line + '\n' }}
      //- Sample output
      p.text-subtitle-1 Output
      pre.mb-3(@click="copyToClipboard($event)")
        template(v-for="line in sample.output") {{ line + '\n' }}
  v-row
    v-col
      //- Limits
      h3.mb-5 Limits
      p.mb-1 Your program needs to finish task in {{ problem.timeLimit }} second{{ problem.timeLimit > 1 ? 's' : '' }}.
      p.mb-1 Your program can only use memory less than {{ problem.memLimit }} KB.
  v-row(v-if="problem.hint")
    v-col
      //- Hint
      div.expand-header(@click="isHintOpen = !isHintOpen")
        v-icon.mr-2(
          size="14"
          color="#F9F9F9B3"
          :style="{'transform': isHintOpen ? 'rotate(0)': 'rotate(-90deg)'}") $triangle
        h3 Hint
      v-expand-transition
        div(v-show="isHintOpen")
          p {{ problem.hint }}
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import Heading from '@/components/common/Heading.vue'
import WhiteButton from '@/components/buttons/WhiteButton.vue'

export default {
  name:  'ProblemInformation',
  props: {
    problem: {
      type:     Object,
      required: true,
      default:  () => {},
    },
  },
  components: {
    Heading,
    WhiteButton,
  },
  data () {
    return {
      // Whether the loader code block is expanded
      isLoaderCodeOpen: true,
      // Whether the hint block is expanded
      isHintOpen:       false,
    }
  },
  computed: {
    ...mapState('problem', ['levelOption']),
    // Pass rate in percent, round to hundredth
    passRate () {
      return Math.round((this.problem.acRequest / this.problem.totalRequest) * 10000) / 100 || 0
    },
    inputFormatText () {
      return this.problem.inputFormat.split('\n')
    },
    outputFormatText () {
      return this.problem.outputFormat.split('\n')
    },
    // Preprocessed sample cases, split lines into an array
    sampleCases () {
      return this.problem.samples.map(sample => ({
        input:  sample.inputData ? sample.inputData.split('\n') : ['(No input)'],
        output: sample.outputData.split('\n'),
      }))
    },
  },
  methods: {
    ...mapMutations('feature', ['setNotification']),
    // Copy input / output string to clipboard
    copyToClipboard (event) {
      navigator.clipboard.writeText(event.target.innerText)
      this.setNotification({
        isOpen:  true,
        type:    'success',
        message: 'Copied to clipboard!',
        timeout: 3000,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.expand-header {
  display: flex;
  align-items: center;
  margin: 8px 0px;
  cursor: pointer;
}

pre {
  cursor: pointer;
}
</style>
