<template lang="pug">
div.flex-center.flex-nowrap
  v-btn.mr-3(
    icon
    :disabled="isVoting"
    :loading="isVoting"
    @click="$emit('vote', 'like')")
    v-icon(:color="userVote === 'like' ? '#F3C15A' : '#B5B5B5'") $like
  span.mr-8.text-h6 {{ likeCount }}
  v-btn.mr-3(
    icon
    :disabled="isVoting"
    :loading="isVoting"
    @click="$emit('vote', 'dislike')")
    v-icon(:color="userVote === 'dislike' ? '#FF6E6E' : '#B5B5B5'") $dislike
  span.text-h6 {{ dislikeCount }}
</template>

<script>
export default {
  name:  'VoteButtons',
  props: {
    isVoting: {
      type:    Boolean,
      require: true,
      default: false,
    },
    likeCount: {
      type:    Number,
      require: true,
      default: 0,
    },
    dislikeCount: {
      type:    Number,
      require: true,
      default: 0,
    },
    userVote: {
      type:    String,
      require: true,
      default: '',
    },
  },
}
</script>
