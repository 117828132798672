<template lang="pug">
one-column-layout
  v-container(fluid)
    //- Icon and username
    v-row
      v-col.d-flex.flex-column.align-center
        v-avatar.mb-4.outlined(size="152")
          v-img(v-if="user.avatar" :src="user.avatar" :alt="user.username")
          v-icon(v-else size="152") $profile
        h1 {{ user.username }}
    v-row(v-if="user.biography" justify="center")
      v-col(cols="12" sm="8" md="6")
        p.text-h6.text-center
          span.d-block(
            v-for="line in user.biography.split('\\n')"
            :style="{lineBreak: 'anywhere'}") {{ line }}
    v-row(v-if="user.username === username")
      v-spacer
      v-col(cols="auto")
        white-button(to="/setting/profile") Edit
    //- Information cards
    //- TODO: what if everything is hidden?
    v-row
      //- v-col(cols="12" lg="4")
      //-   //- Profile card
      //-   floating-card
      //-     h2.mb-3.text-center Profile
      //-     v-divider
      //-     v-list(two-line color="transparent")
      //-       v-list-item
      //-         v-list-item-icon
      //-           v-icon(size="28") $email
      //-         v-list-item-content
      //-           v-list-item-title E-mail
      //-           v-list-item-subtitle {{ user.email }}
      //-       v-list-item
      //-         v-list-item-icon
      //-           v-icon(size="28") $link
      //-         v-list-item-content
      //-           v-list-item-title Website
      //-           v-list-item-subtitle
      //-             a(:href="user.website" target="_blank") {{ user.website }}
      //-       v-list-item
      //-         v-list-item-icon
      //-           v-icon(size="28") $biography
      //-         v-list-item-content
      //-           v-list-item-title Biography
      //-           span.text-body-2 {{ user.biography }}
      v-col(cols="12")
        //- Daily submission card
        floating-card.mb-6
          h2.mb-3.text-center Daily Submission
          v-divider
          scroll-bar.mt-3
            div(style="min-width: 700px")
              calendar-heatmap(
                v-bind="heatmapSettings"
                :values="dailySubmission")
        //- Recent Activity card
        floating-card
          h2.mb-3.text-center Recent Solved Problems
          v-divider
          //- Recent solved problems
          h4.mt-3.text-center(v-if="!solvedProblems.length") No result.
          v-list(v-else color="transparent")
            v-list-item(
              v-for="problem in solvedProblems"
              :key="`profile-solved-problem-${problem.problemId}`")
              v-list-item-avatar(color="#83A3CD")
                v-icon(size="28") $code
              v-list-item-content
                v-list-item-title
                  span= "Solved problem "
                  router-link(:to="`/problems/${problem.problemId}`") {{ problem.title }}
                  span .
                v-list-item-subtitle At {{ dateToString(problem.timestamp) }}
          //- TODO: when /users/:username/profile/discussions available, finish this tabs
          //- v-tabs(
          //-   v-model="tab"
          //-   centered
          //-   center-active
          //-   color="#FFD06F"
          //-   background-color="transparent")
          //-   v-tabs-slider.rounded
          //-   v-tab.text-subtitle-1(href="#profile-solved-problem") Solved problems
          //-   v-tab.text-subtitle-1(href="#profile-discussion") Discussion
          //- v-tabs-items.background-transparent(v-model="tab")
          //-   //- Recent solved problems tab
          //-   v-tab-item(value="profile-solved-problem")
          //-     h4.mt-3.text-center(v-if="!solvedProblems.length") No solved problem.
          //-     v-list(v-else color="transparent")
          //-       v-list-item(
          //-         v-for="problem in solvedProblems"
          //-         :key="`profile-solved-problem-${problem.problemId}`")
          //-         v-list-item-avatar(color="#83A3CD")
          //-           v-icon(size="28") $code
          //-         v-list-item-content
          //-           v-list-item-title
          //-             span= "Solved problem "
          //-             router-link(:to="`/problems/${problem.problemId}`") {{ problem.title }}
          //-             span .
          //-           v-list-item-subtitle At {{ dateToString(problem.date) }}
          //-   //- Recent joined discussion tab
          //-   v-tab-item(value="profile-discussion")
          //-     h4.mt-3.text-center(v-if="!discussions.length") No discussion.
          //-     v-list(v-else color="transparent")
          //-       v-list-item(
          //-         v-for="discussion in discussions"
          //-         :key="`profile-discussion-${discussion.discussionId}`")
          //-         v-list-item-avatar(color="#75AA84")
          //-           v-icon(size="28") $chat
          //-         v-list-item-content
          //-           v-list-item-title
          //-             span= "Joined the discussion on "
          //-             router-link(:to="`/discussion/${discussion.discussionId}`") {{ discussion.title }}
          //-             span .
          //-           v-list-item-subtitle At {{ dateToString(discussion.date) }}
</template>

<script>
import axios from '@/plugins/axios.js'
import store from '@/store'
import { mapState, mapMutations } from 'vuex'
import { CalendarHeatmap } from 'vue-calendar-heatmap'
import OneColumnLayout from '@/layouts/OneColumnLayout.vue'
import Loading from '@/components/common/Loading.vue'
import FloatingCard from '@/components/cards/FloatingCard.vue'
import ScrollBar from '@/components/common/ScrollBar.vue'
import WhiteButton from '@/components/buttons/WhiteButton.vue'

export default {
  components: {
    OneColumnLayout,
    CalendarHeatmap,
    Loading,
    FloatingCard,
    ScrollBar,
    WhiteButton,
  },
  data () {
    return {
      tab:       null,
      user:      {
        username:  '',
        avatar:    '',
        email:     '',
        website:   '',
        biography: '',
      },
      dailySubmission: [],
      solvedProblems:  [],
      discussions:     [],
      heatmapSettings: {
        endDate:     new Date(),
        tooltipUnit: 'submission',
        rangeColor:  ['#F9F9F9', '#FFECC6', '#FDDD9C', '#FFCB61', '#FFBC32'],
      },
    }
  },
  computed: {
    ...mapState('user', ['username']),
  },
  methods: {
    ...mapMutations('feature', ['setPageLoading', 'setNotification']),
    setProfile (profile, error) {
      if (error) {
        this.setNotification({
          isOpen:  true,
          type:    'error',
          message: error,
        })
        return
      }

      this.user = profile.user

      this.setTitle(`${this.user.username}'s Profile'`)

      this.dailySubmission = profile.dailySubmission
      this.dailySubmission.forEach(record => {
        record.date = new Date(record.timestamp)
        delete record.timestamp
      })

      this.solvedProblems = profile.solvedProblems
      this.solvedProblems.forEach(record => {
        record.timestamp = new Date(record.timestamp)
      })

      this.setPageLoading(false)
    },
  },
  beforeRouteEnter (to, _, next) {
    store.commit('feature/setPageLoading', true)
    Promise.all([
      axios.get(`/users/${to.params.username}`),
      axios.get(`/users/${to.params.username}/profile/dailySubmission`),
      axios.get(`/users/${to.params.username}/profile/solvedProblems`),
    ])
      .then(responses => {
        const profile = {
          user:            responses[0].data,
          dailySubmission: responses[1].data,
          solvedProblems:  responses[2].data,
        }
        next(vm => vm.setProfile(profile, null))
      })
      .catch(error => {
        if(error.response.status === 404) {
          next('/notFound')
        }
        else {
          next(vm => vm.setProfile(null, error.response.data))
        }
      })
  },
  beforeRouteUpdate (to, _, next) {
    this.setPageLoading(true)
    Promise.all([
      axios.get(`/users/${to.params.username}`),
      axios.get(`/users/${to.params.username}/profile/dailySubmission`),
      axios.get(`/users/${to.params.username}/profile/solvedProblems`),
    ])
      .then(responses => {
        const profile = {
          user:            responses[0].data,
          dailySubmission: responses[1].data,
          solvedProblems:  responses[2].data,
        }
        this.setProfile(profile, null)
        next()
      })
      .catch(error => {
        if(error.response.status === 404) {
          next('/notFound')
        }
        else {
          this.setProfile(null, error.response.data)
          next()
        }
      })
  },
}
</script>

<style lang="scss" scoped>
.outlined {
  outline: 2px solid #F9F9F9;
  border: 4px solid transparent;
}

.background-transparent {
  background-color: transparent;
}

// TODO: wrap v-tab to a customized component
.v-tab {
  text-transform: none !important;
}

.v-tab::before {
  background-color: inherit !important;
}

.v-tabs-items.full-height-tab .v-window-item {
  height: 540px; //might need to change this in the future
}

.v-tabs-items.full-height-tab {
  background-color: #1F2327;
  max-width: 360px;
}

</style>
