<template lang="pug">
div.d-flex.flex-column.flex-md-row.justify-start.justify-md-space-around.align-center.layout
  router-link(to="/")
    v-img.ml-md-12.mx-md-12(v-if="$vuetify.breakpoint.mdAndUp" src="@/assets/logo.png", height="auto", width="500")
    v-img.mb-11(v-else src="@/assets/logo-text.png", height="auto", width="270")
  div.mt-11.mt-md-0.mb-4.mb-md-0.mx-8.mx-md-12
    slot
</template>

<script>
export default {
  name: 'AuthenticationLayout',
}
</script>

<style lang="scss" scoped>
$nav-bar-height: 126px;

.layout {
  min-height: calc(100vh - #{$nav-bar-height} * 2);
}

</style>
