<template lang="pug">
div.about
  v-img(
    v-if="$vuetify.breakpoint.mdAndUp"
    src="@/assets/logo.png"
    height="auto"
    width="400")
  v-img(
    v-else
    src="@/assets/logo.png"
    height="auto"
    width="270")
  h1.mt-10.mb-4 Welcome to CK Judge!
  div.mx-5
    p.mb-1.text-center This website is for student who participate in "Introduction to Computers and Programming" to practice your programming technique online.
    p.mb-6.text-center Come join us and have fun!
  primary-button(to="/signin") Start
</template>

<script>
import PrimaryButton from '@/components/buttons/PrimaryButton.vue'

export default {
  components: {
    PrimaryButton,
  },
}
</script>

<style lang="scss" scoped>
$nav-bar-height: 126px;

.about {
  min-height: calc(100vh - #{$nav-bar-height} * 2);
  margin-bottom: 32px;
  display: grid;
  justify-items: center;
  align-content: center;
}
</style>
