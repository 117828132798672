<template lang="pug">
two-columns-layout
  heading Announcements
    template(v-slot:action)
      white-button(to="/manage/announcements/announcement") Create
  //- List body
  card-list(:items="announcements")
    template(v-slot:default="{item}")
      manage-announcement-card(:announcement="item")
  //- Menu panel
  template(v-slot:aside)
    manage-menu
</template>

<script>
import { mapState } from 'vuex'
import TwoColumnsLayout from '@/layouts/TwoColumnsLayout.vue'
import Heading from '@/components/common/Heading.vue'
import Loading from '@/components/common/Loading.vue'
import WhiteButton from '@/components/buttons/WhiteButton.vue'
import ManageMenu from '@/components/menus/ManageMenu.vue'
import CardList from '@/components/lists/CardList.vue'
import ManageAnnouncementCard from '@/components/cards/ManageAnnouncementCard.vue'

export default {
  components: {
    TwoColumnsLayout,
    Heading,
    Loading,
    WhiteButton,
    ManageMenu,
    CardList,
    ManageAnnouncementCard,
  },
  computed: {
    ...mapState('announcement', ['announcements']),
  },
}
</script>
