import axios from '@/plugins/axios.js'

function toDate (string) {
  return string ? new Date(string) : null
}

const state = {
  homeworkList: {},
  examList:     {},
}

const getters = {
  chapters (state) {
    return [...Object.keys(state.homeworkList), ...Object.keys(state.examList)]
  },
  // The deadline status of a chapter
  // Possible value includes ['On going', 'Make up', 'Closed']
  deadlineStatus () {
    return chapter => {
      const now = new Date()
      // If current time is later than due time,
      // it means that this chapter is closed
      if(chapter.dueTime && now > chapter.dueTime) {
        return 'Closed'
      }
      // If current time is earlier than due time but later than make-up time,
      // it means that this chapter is in make-up period
      else if(chapter.makeUpTime && now > chapter.makeUpTime) {
        return 'Make up'
      }
      // If current time is earlier than make-up time,
      // it means that this chapter is on going
      else if(chapter.startTime) {
        return 'On going'
      }
      else {
        return ''
      }
    }
  },
}

const mutations = {
  setHomework (state, data) {
    // Initialize a container
    state.homeworkList = {}
    for(let i = 0; i < data.homework.length; ++i) {
      // Set a serial number
      // TODO: this information should come from backend
      data.homework[i].position = i
      // Convert time string to date object
      data.homework[i].startTime = toDate(data.homework[i].startTime)
      data.homework[i].dueTime = toDate(data.homework[i].dueTime)
      data.homework[i].makeUpTime = toDate(data.homework[i].makeUpTime)
      // Split video urls
      // TODO: this property should be an array of strings
      data.homework[i].videoUrl = data.homework[i].videoUrl.split(',')
      if(data.homework[i].videoUrl[0] === '') {
        data.homework[i].videoUrl.shift()
      }

      // Store to state
      state.homeworkList[data.homework[i].index] = data.homework[i]
    }
  },
  setExams (state, data) {
    // Initialize a container
    state.examList = {}
    for(let i = 0; i < data.exams.length; ++i) {
      // Set a serial number
      data.exams[i].position = i
      // Convert time string to date object
      data.exams[i].startTime = toDate(data.exams[i].startTime)
      data.exams[i].dueTime = toDate(data.exams[i].dueTime)
      // Store to state
      state.examList[data.exams[i].index] = data.exams[i]
    }
  },
  clearChapter (state) {
    state.homeworkList = {}
    state.examList = {}
  },
}

const actions = {
  fetchChapters ({ commit }) {
    // Ensure to get both homework and exams first before setting data to store
    // This can prevent vue from rendering the chapter cards twice in home page
    return Promise.all([axios.get('/homework'), axios.get('/exams')])
      .then(result => {
        commit('setHomework', result[0].data)
        commit('setExams', result[1].data)
      })
      .catch(error => {
        throw error
      })
  },
  createChapter ({ dispatch }, data) {
    return axios.post(`/${data.type}`, data.payload)
      .then(() => {
        dispatch('fetchChapters')
      })
      .catch(error => {
        throw error
      })
  },
  updateChapter ({ dispatch }, data) {
    return axios.patch(`/${data.type}/${data.index}`, data.payload)
      .then(() => {
        dispatch('fetchChapters')
      })
      .catch(error => {
        throw error
      })
  },
  deleteChapter ({ dispatch }, data) {
    return axios.delete(`/${data.type}/${data.index}`)
      .then(() => {
        dispatch('fetchChapters')
      })
      .catch(error => {
        throw error
      })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
