<template lang="pug">
two-columns-layout
  //- Form heading and buttons
  heading Exercise Information
    template(v-slot:action)
      div.flex-center.flex-nowrap
        //- Hide / unhide button
        v-btn.mr-5(
          icon
          @click.prevent="exercise.hidden = !exercise.hidden")
          v-icon(
            v-if="exercise.hidden"
            size="32"
            color="#F9F9F9B3") $eye-close
          v-icon(v-else size="32") $eye-open
        //- Delete exercise button, only clickable in update page
        v-btn(
          icon
          color="#FF6E6E"
          :loading="isDeleting"
          :disabled="isDeleting || action === 'create'"
          @click.prevent="isDeleting = true")
          v-icon(size="32") $trashcan
  v-form(ref="form")
    v-container.pa-0(fluid)
      v-row
        v-col(cols="12" sm="auto" order="12")
          //- Chapter
          dropdown-list(
          v-model="exercise.chapter"
          :items="chapters"
          label="Chapter"
          required)
        v-col(order="1")
          //- Exercise title
          text-field(
            v-model="exercise.title"
            label="Title"
            required)
      v-row(
        v-for="(MCQ, index) in MCQs"
        :key="`exercise-MCQ-${index}`")
        v-col
          floating-card
            div.flex-center.mb-3
              span.text-h6 # {{ index + 1 }}
              v-spacer
              v-btn(icon @click="deleteMCQ(index)")
                v-icon(color="#FF6E6E") $trashcan
            v-divider.mb-3
            rich-text-editor(
              v-model="MCQ.description"
              label="Description"
              required)
            span.text-subtitle-1 Options
            v-list(color="transparent" dense)
              v-list-item(
                v-for="(option, optionIndex) in MCQ.choices"
                :key="`exercise-MCQ-${index}-option-${optionIndex}`")
                v-list-item-action
                  v-checkbox(
                    v-model="MCQ.answer"
                    :value="optionIndex"
                    :rules="[notEmpty(MCQ.answer)]")
                v-list-item-content
                  v-text-field(
                    v-model="MCQ.choices[optionIndex]"
                    single-line
                    label="Option text"
                    ref="options"
                    :rules="[required]")
                v-list-item-action
                  v-btn(
                    icon
                    color="#FF6E6E"
                    @click="deleteMCQOption(index, optionIndex)")
                    v-icon mdi-close
              v-list-item(:key="`exercise-MCQ-${index}-option-create`")
                v-list-item-action
                  v-checkbox(disabled)
                v-list-item-content
                  v-text-field(
                    readonly
                    single-line
                    value="Create option..."
                    @focus="createMCQOption(index)")
                v-list-item-action
                  v-btn(disabled icon color="#FF6E6E")
                    v-icon mdi-close
      v-row
        v-col.text-center
          white-button(@click="createMCQ") Create Question
      v-row
        v-col(cols="auto")
          //- Submit button
          primary-button(
            :isWaiting="isLoading"
            @click="submitForm()") {{ action }}
        v-col(cols="auto")
          //- Cancel button
          primary-button(
            hollow
            :isWaiting="isLoading"
            @click="goToHomework()") Cancel
  //- Confirm delete modal
  modal(:isOpen="isDeleting")
    template(v-slot:title)
      span Delete "{{ exercise.title }}"
    template(v-slot:content)
      p Do you really want to delete "{{ exercise.title }}"?
    template(v-slot:actions)
      modal-button.mr-5(@click="deleteExercise()") Delete
      modal-button(hollow @click="isDeleting = false") Cancel
  //- Menu panel
  template(v-slot:aside)
    manage-menu
</template>

<script>
import axios from '@/plugins/axios.js'
import { mapMutations, mapGetters } from 'vuex'
import TwoColumnsLayout from '@/layouts/TwoColumnsLayout.vue'
import Heading from '@/components/common/Heading.vue'
import FloatingCard from '@/components/cards/FloatingCard.vue'
import ManageMenu from '@/components/menus/ManageMenu.vue'
import PrimaryButton from '@/components/buttons/PrimaryButton.vue'
import TextField from '@/components/form/TextField.vue'
import DropdownList from '@/components/form/DropdownList.vue'
import RichTextEditor from '@/components/form/RichTextEditor.vue'
import Modal from '@/components/common/Modal.vue'
import ModalButton from '@/components/buttons/ModalButton.vue'
import WhiteButton from '@/components/buttons/WhiteButton.vue'

export default {
  components: {
    TwoColumnsLayout,
    Heading,
    FloatingCard,
    ManageMenu,
    WhiteButton,
    PrimaryButton,
    TextField,
    DropdownList,
    RichTextEditor,
    Modal,
    ModalButton,
  },
  data () {
    return {
      action:     'update',
      isLoading:  true,
      isDeleting: false,
      exercise:   {
        id:      '',
        title:   '',
        chapter: '',
        hidden:  true,
      },
      MCQs:        [],
      removedMCQs: [],
      formDirty:   false,
    }
  },
  computed: {
    ...mapGetters('chapter', ['chapters']),
    notEmpty () {
      return answers => answers.length > 0 || 'At least one option should be selected.'
    },
    required () {
      return value => value !== '' || 'Option cannot be empty string.'
    },
  },
  watch: {
    exercise: {
      handler () {
        this.formDirty = true
      },
      deep: true,
    },
    MCQs () {
      this.formDirty = true
    },
    removedMCQs () {
      this.formDirty = true
    },
  },
  methods: {
    ...mapMutations('feature', ['setNotification']),
    // Return to the belonging homework page
    goToHomework () {
      this.formDirty = false
      this.$router.push(`/manage/chapter/homework/${this.$route.params.homeworkId}`).catch(()=>{})
    },
    // Create one multiple choice question
    createMCQ () {
      this.MCQs.push({
        index:       this.MCQs.length,
        description: '',
        choices:     [],
        answer:      [],
      })
    },
    // Delete multiple choice question
    deleteMCQ (index) {
      const removedMCQ = this.MCQs.splice(index, 1)
      this.removedMCQs.push(removedMCQ[0])
    },
    // Create one multiple choice question
    createMCQOption (index) {
      this.MCQs[index].choices.push('')
      this.$nextTick(() => {
        this.$refs.options[this.$refs.options.length - 1].focus()
      })
    },
    // Delete multiple choice question
    deleteMCQOption (index, optionIndex) {
      this.MCQs[index].choices.splice(optionIndex, 1)
    },
    // Submit form
    submitForm () {
      // Validate each field before submitting
      if(!this.$refs.form.validate()) {
        this.setNotification({
          isOpen:  true,
          type:    'warning',
          message: 'Please ensure you filled in every field correctly',
        })
        return
      }

      // Decide which action to do
      this[`${this.action}Exercise`]()
    },
    // Create exercise
    createExercise () {
      // Set loading state
      this.isLoading = true

      // Create exercise
      axios.post('/exercises', this.exercise)
        // Then create the MCQs in it
        .then(res => Promise.all(
          this.MCQs.map(MCQ => axios.post(`/exercises/${res.data.id}/MCQ`, MCQ)),
        ))
        // If success, show success message and return to exercise list
        .then(() => {
          this.formDirty = false
          this.setNotification({
            isOpen:  true,
            type:    'success',
            message: 'New exercise created!',
          })
          this.goToHomework()
        })
        // If there is any error, show notification and clear loading state
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
          this.isLoading = false
        })
    },
    // Update exercise
    updateExercise () {
      // Set loading state
      this.isLoading = true

      // Update exercise
      axios.patch(`/exercises/${this.exercise.id}`, this.exercise)
        // Then create the new MCQs
        .then(() => Promise.all(
          this.MCQs.filter(MCQ => !MCQ.id).map(MCQ =>
            axios.post(`/exercises/${this.exercise.id}/MCQ`, MCQ)),
        ))
        // Then update the existing MCQs
        .then(() => Promise.all(
          this.MCQs.filter(MCQ => MCQ.id).map(MCQ =>
            axios.patch(`/exercises/${this.exercise.id}/MCQ/${MCQ.id}`, MCQ)),
        ))
        // Then delete the removed MCQs
        .then(() => Promise.all(
          this.removedMCQs.map(MCQ =>
            axios.delete(`/exercises/${this.exercise.id}/MCQ/${MCQ.id}`)),
        ))
        // If success, show success message
        .then(() => {
          this.formDirty = false
          this.setNotification({
            isOpen:  true,
            type:    'success',
            message: 'Exercise updated!',
          })
        })
        // If there is any error, show notification
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
        })
        // Clear loading state
        .finally(() => {
          this.isLoading = false
        })
    },
    // Delete exercise
    deleteExercise () {
      // Delete MCQs
      Promise.all(this.removedMCQs.concat(this.MCQs.filter(MCQ => MCQ.id)).map(MCQ =>
        axios.delete(`/exercises/${this.exercise.id}/MCQ/${MCQ.id}`),
      ))
        // Then delete exercise
        .then(() => axios.delete(`/exercises/${this.exercise.id}`))
        // If success, show success message and return to exercise list
        .then(() => {
          this.formDirty = false
          this.setNotification({
            isOpen:  true,
            type:    'success',
            message: 'Exercise deleted!',
          })
          this.goToHomework()
        })
        // If there is any error, show notification and clear deleting state
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
          this.isDeleting = false
        })
    },
  },
  mounted () {
    // If exercise id is not empty string,
    // which means user is updating exercise, need to fetch data first
    if(this.$route.params.id) {
      axios.get(`/exercises/${this.$route.params.id}`)
        .then(res => {
          this.exercise = {
            id:      res.data.id,
            title:   res.data.title,
            chapter: res.data.chapter ? res.data.chapter.index : '',
            hidden:  res.data.hidden,
          }

          this.MCQs = res.data.MCQs.sort((a, b) => a.index - b.index)

          if (this.MCQs.length === 0) {
            this.createMCQ()
          }
        })
        // If there is any error, show notification
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
        })
        .finally(() => {
          // Clear loading state
          this.isLoading = false

          // Clear formDirty flag
          this.$nextTick(() => {
            this.formDirty = false
          })
        })
    }
    // Else, it means user is creating exercise
    else {
      this.action = 'create'
      this.exercise.chapter = this.$route.params.homeworkId
      this.createMCQ()
      this.isLoading = false
      // Clear formDirty flag
      this.$nextTick(() => {
        this.formDirty = false
      })
    }
  },
  beforeRouteLeave (to, from, next) {
    // If the form is dirty, check if user really want to leave
    // before route change
    if(this.formDirty && !this.confirmRouteChange()) {
      next(false)
    }
    else {
      next()
    }
  },
}
</script>
