<template lang="pug">
floating-card.chapter-card(:to="path")
  v-overlay(:value="hidden" absolute opacity="0.75")
    v-icon.mt-15(size="64") $eye-close
  //- Chapter name, compatible for chapter index or exam name
  h3.card-index {{ index }}
  //- Title text, compatible for chapter title or exam status
  //- This text should be inject from parent
  span.card-title
    slot
  //- Progress ring for this chapter
  progress-ring.card-progress(:color="position" :progress="progress")
  //- Display alarm if the chapter is in make-up status
  //- v-icon.status-icon(v-show="deadlineStatus === 'Make up'") $alarm
  v-icon.status-icon(
    v-show="deadlineStatus === 'Make up'"
    color="#F9F9F980"
    size="36") $alarm
  //- Display lock if the chapter is in closed status
  v-icon.status-icon(
    v-show="deadlineStatus === 'Closed'"
    color="#F9F9F980"
    size="36") $lock
</template>

<script>
import FloatingCard from '@/components/cards/FloatingCard.vue'
import ProgressRing from '@/components/common/ProgressRing.vue'

export default {
  name:  'ChapterCard',
  props: {
    // Index of this chapter
    index: {
      type:     String,
      required: true,
      default:  '',
    },
    // Whether this chapter is hidden
    hidden: {
      type:     Boolean,
      required: false,
      default:  false,
    },
    // Position of this card in the container
    position: {
      type:     Number,
      required: true,
      default:  0,
    },
    // Accomplishment progress in a number ranging in [0, 1]
    progress: {
      type:     Number,
      required: true,
      default:  0,
    },
    // Deadline status of this chapter, including ['On going', 'Make up', 'Closed']
    deadlineStatus: {
      type:     String,
      required: false,
      default:  'On going',
    },
    // Route to chapter page
    path: {
      type:     String,
      required: true,
    },
  },
  components: {
    FloatingCard,
    ProgressRing,
  },
}
</script>

<style lang="scss" scoped>
.chapter-card {
  display: grid;
  grid-template-rows: min-content min-content 1fr;
  align-items: center;
  position: relative;
  width: 274px;
  height: 310px;
  border-radius: 5px;

  .card-index {
    font-size: 24px;
    font-weight: 500;
  }

  .card-title {
    font-size: 20px;
  }

  .card-progress {
    display: block;
    justify-self: center;
  }

  .status-icon {
    position: absolute;
    top: 24px;
    right: 24px;
  }
}
</style>
