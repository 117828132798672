import * as PIXI from 'pixi.js'
import Scene from '@/components/story/prototypes/Scene.js'
import TextButton from '@/components/story/components/TextButton.js'

class LabScene extends Scene {
  constructor (app) {
    super(app)
    // Load items' information in labs
    this.labInfos = require('@/assets/story/labInfos.json')

    // Set default aspect ratio
    this.aspectRatio = 1

    // Initialize a resource loader
    this.loader = new PIXI.Loader()
    // Add items sheet and background image for each lab
    this.loader.add('backgroundImage', require('@/assets/story/background.png'))
    for (let labId in this.labInfos) {
      this.loader.add(labId, require(`@/assets/story/${this.labInfos[labId].background}`))
    }
    // Load items and backgrounds. Every value in itemSheet is a Texture
    this.backgroundImages = {}
    this.loader.load((loader, resources) => {
      for (let labId in this.labInfos) {
        this.backgroundImages[labId] = resources[labId]
      }
      new PIXI.Spritesheet(
        resources.backgroundImage.texture.baseTexture,
        require('@/assets/story/background.json'),
      ).parse((items) => {
        this.itemSheet = items
      })
    })
    // Setup "pick up item" listener
    window.addEventListener('pickup-item', (e) => {
      this.container.getChildByName(e.detail.name).visible = false
    })
  }

  // id: lab name
  load ({ id }) {
    super.load()
    this.id = id
    this.container.name = 'LabScene' + this.id
    this.labInfo = this.labInfos[this.id]

    // Set background image
    this.setBackground()

    // Set items
    this.setItems()

    // Add "back to map" button
    const backButton = new TextButton({
      text:  'Back to map',
      style: {
        fontSize: 16,
        fill:     '#e2e2b4',
      },
      handler: () => {
        window.dispatchEvent(new CustomEvent('remove-scene'))
      },
    })
    backButton.position.set(32, 32)
    this.container.addChild(backButton)

    this.app.stage.addChild(this.container)
  }

  // Create sprites including background
  setBackground () {
    const background = new PIXI.Sprite(this.backgroundImages[this.id].texture)
    background.name = 'Background' + this.id
    // Reset the aspectRatio
    this.aspectRatio = this.app.view.width / background.texture.width
    background.scale.set(this.aspectRatio)
    this.container.addChild(background)
  }

  // Create sprites including items
  setItems () {
    this.labInfo.items.forEach((itemInfo, idx) => {
      const name = `item${idx}`
      this.container.addChild(this.createItem({
        name:            name,
        sprite:          this.itemSheet[itemInfo.sprite],
        x:               this.app.view.width * itemInfo.x,
        y:               this.app.view.height * itemInfo.y,
        aspectRatio:     this.aspectRatio,
        onClickListener: () => {
          window.dispatchEvent(
            new CustomEvent('open-dialog', {
              detail: {
                name:   name,
                option: {},
              },
            }),
          )
        },
      }))
    })
  }

  // Create an item with given property
  createItem ({ name = null, sprite, x, y, aspectRatio, onClickListener = () => { } }) {
    const item = new PIXI.Sprite(sprite)
    item.name = name
    item.position.set(x, y)
    item.scale.set(aspectRatio)
    item.interactive = true
    item.buttonMode = true
    item.on('pointerdown', onClickListener)
    return item
  }
}

export default LabScene
