<template lang="pug">
v-card(flat color="transparent")
  v-form.d-flex.flex-column
    //- Username field
    div.mt-10.d-flex.align-start
      v-icon.mr-5(size="36" color="#B5B5B5") mdi-account-circle
      v-text-field.mt-0.pt-0(
        v-model="username"
        type="text"
        label="Username"
        required
        rounded
        light
        single-line
        height="36"
        background-color="#B5B5B5"
        :error-messages="usernameErrors")
    //- Password field
    div.mt-6.d-flex.align-start
      v-icon.mr-5(size="36" color="#B5B5B5") mdi-lock
      v-text-field.mt-0.pt-0(
        v-model="password"
        type="password"
        label="Password"
        required
        rounded
        light
        single-line
        height="36"
        background-color="#B5B5B5"
        :error-messages="passwordErrors"
        @keyup.enter.prevent="submitForm()")
    //- Sign in Button
    primary-button.mt-10.align-self-center(
      :isWaiting="isWaiting"
      @click="submitForm()") Sign in
  //- Link to forgot password page
  router-link.link(to="/forgotPwd") Forgot password?
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import PrimaryButton from '@/components/buttons/PrimaryButton.vue'

export default {
  name:       'SigninForm',
  components: {
    PrimaryButton,
  },
  data () {
    return {
      isWaiting: false,
      username:  '',
      password:  '',
    }
  },
  validations: {
    username: { required },
    password: { required },
  },
  computed: {
    // User name field error message handling
    usernameErrors () {
      const errors = []
      if (!this.$v.username.$dirty) {
        return errors
      }
      !this.$v.username.required && errors.push('User name is required.')
      return errors
    },
    // Password field error message handling
    passwordErrors () {
      const errors = []
      if (!this.$v.password.$dirty) {
        return errors
      }
      !this.$v.password.required && errors.push('Password is required.')
      return errors
    },
  },
  methods: {
    ...mapMutations('feature', ['setNotification']),
    ...mapActions('user', ['signIn']),
    async submitForm () {
      // Ensure there is no invalid field before submitting
      this.$v.$touch()
      if (!this.$v.$anyError) {
        // Disable sign in button
        this.isWaiting = true

        // Send sign in request
        await this.signIn({
          username: this.username,
          password: this.password,
        })
          // If successed, send user to home page
          .then(() => {
            this.$router.push('/').catch(()=>{})
          })
          // If there is any error, show notification
          .catch(error => {
            this.setNotification({
              isOpen:  true,
              type:    'error',
              message: error.response.data,
            })
          })
          // Re-enable sign in button
          .finally(() => {
            this.isWaiting = false
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.link {
  display: block;
  margin-top: 20px;
  color: #F9F9F9;
  font-size: 20px;
  text-align: center;
}
</style>
