<template lang="pug">
floating-card(:to="`/discussion/${discussion.id}`")
  v-container.pa-0(fluid)
    v-row(align="center")
      v-col(v-if="$vuetify.breakpoint.mdAndUp" sm="auto")
        //- If the post is pinned, display pin rather than avatar
        v-icon(
          v-if="discussion.pinned"
          size="36"
          color="#F3C15A") mdi-pin
        //- User avatar
        v-avatar(v-else size="52")
          img(
            v-if="discussion.author.avatar"
            :src="discussion.author.avatar"
            :alt="discussion.author.username")
          v-icon(v-else size="52" color="#B5B5B5") $profile
      v-col(cols="12" sm="6")
        //- Question title
        p.mb-1.text-h6.text-truncate {{ discussion.title }}
        //- Asker and asking time
        p.mb-0.text-truncate {{ discussion.author.username }} asked at {{ dateToDateString(discussion.createdAt) }}
      v-spacer
      v-col(cols="6" sm="auto")
        //- Related chapter
        v-chip(
          v-if="discussion.chapter"
          color="#F9F9F9"
          text-color="#30333F"
          :to="`/${discussion.chapter.type.toLowerCase()}/${discussion.chapter.index}`") {{ discussion.chapter.index }}
      v-col.flex-center.flex-column(cols="2" sm="auto")
        //- Replies count
        v-icon.mb-1(color="#B5B5B5") $reply
        span {{ discussion.repliesCount }}
      v-col.flex-center.flex-column(cols="2" sm="auto")
        //- Likes count
        v-icon.mb-1(:color="voteType === 'like' ? '#F3C15A' : '#B5B5B5'") $like
        span {{ likeCount }}
      v-col.flex-center.flex-column(cols="2" sm="auto")
        //- Dislikes count
        v-icon.mb-1(:color="voteType === 'dislike' ? '#FF6E6E' : '#B5B5B5'") $dislike
        span {{ dislikeCount }}
</template>

<script>
import { mapState } from 'vuex'
import FloatingCard from '@/components/cards/FloatingCard.vue'

export default {
  name:  'DiscussionCard',
  props: {
    discussion: {
      type:     Object,
      required: true,
      default:  () => {},
    },
  },
  components: {
    FloatingCard,
  },
  computed: {
    ...mapState('user', ['username']),
    voteType () {
      const myVote = this.discussion.votes.find(vote => vote.voter.username === this.username)
      return myVote ? myVote.voteType : ''
    },
    likeCount () {
      return this.discussion.votes.filter(vote => vote.voteType === 'like').length
    },
    dislikeCount () {
      return this.discussion.votes.filter(vote => vote.voteType === 'dislike').length
    },
  },
}
</script>
