<template lang="pug">
v-container.pa-0.code-editor(fluid)
  v-row
    v-col(cols="auto")
      //- Upload file from user's file system
      input(
        ref="file"
        type="file"
        accept=".c"
        style="display: none"
        @click="event => event.target.value=null"
        @change="getFileFromInput")
      //- Upload file button
      white-button(@click="$refs.file.click()")
        v-icon.mr-3 $upload
        | Upload File
    v-col(cols="auto")
      //- Upload from github button
      white-button(@click="openGitDialog('github')")
        v-icon.mr-3 mdi-github
        | Upload From Github
    v-col(cols="auto")
      //- Upload from gitlab button
      white-button(@click="openGitDialog('gitlab')")
        v-icon.mr-3 mdi-gitlab
        | Upload From Gitlab
  v-row
    v-col(cols="12")
      //- Code editor
      codemirror(
        v-model="code"
        :options="cmOptions"
        @ready="cm => { cm.setSize('100%', '50vh') }")
      //- Compile message
      div.compile-message
        span Compile Message
        v-expand-transition
          div(v-show="compileMsg.isOpen")
            pre {{ compileMsg.message }}
  v-row
    v-col(cols="auto")
      //- Submit button
      primary-button(
        :isWaiting="isJudging"
        @click="submitCode(false)") Submit
    v-col(cols="auto")
      //- Test code button
      v-tooltip(right)
        template(v-slot:activator="{ on, attrs }")
          div(v-bind="attrs" v-on="on")
            primary-button(
              hollow
              :isWaiting="isJudging"
              @click="submitCode(true)") Test Code
        span Testing submission will not be recorded
  //- Score displayer
  v-snackbar(
    v-model="judgeResult.isOpen"
    absolute
    top right
    :timeout="-1"
    :color="submissionStatusOption[judgeResult.status].color")
    span {{ judgeResultText }}
    template(v-slot:action="{ attrs }")
      v-btn(v-bind="attrs" text @click="judgeResult.isOpen = false") Close
  //- Upload file from third-party git server
  modal(:isOpen="gitDialog.isOpen")
    template(v-slot:title)
      span Upload from {{ gitDialog.host }}
    template(v-slot:content)
      p(v-if="gitDialog.isLoading") Loading...
      template(v-else)
        //- If user hasn't authorized on given host, display hint text
        div.py-5.text-center.rich-text.light(v-if="!gitDialog[gitDialog.host].authorized")
          span= "Please "
          router-link(to="/setting/authenticate") authenticate your account
          |  first.
        //- Else, display the repo got from given host
        v-treeview(
          v-else
          activatable
          open-on-click
          item-key="url"
          style="width: 100%"
          color="#1F2327"
          :items="gitDialog[gitDialog.host].repo"
          @update:active="items => { gitDialog.selectedFile = items[0] }")
          template(v-slot:prepend="{ item, open }")
            v-icon(v-if="item.type === 'folder'") {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
            v-icon(v-else) $file
    template(v-slot:actions)
      v-spacer
      //- Select file button. Only available to authorized host
      modal-button.mr-5(
        :loading="gitDialog.isFetching"
        :disabled="!gitDialog[gitDialog.host].authorized || gitDialog.isFetching"
        @click="getFileFromGit()") Select
      //- Cancel button
      modal-button(
        hollow
        :disabled="gitDialog.isFetching"
        @click="gitDialog.isOpen = false") Cancel
</template>

<script>
import axios from '@/plugins/axios.js'
import { mapState, mapMutations, mapActions } from 'vuex'
import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/dracula.css'
import 'codemirror/mode/clike/clike.js'

import Modal from '@/components/common/Modal.vue'
import ModalButton from '@/components/buttons/ModalButton.vue'
import PrimaryButton from '@/components/buttons/PrimaryButton.vue'
import WhiteButton from '@/components/buttons/WhiteButton.vue'

export default {
  name:  'CodeEditor',
  props: {
    // Problem id
    problemId: {
      type:     String,
      required: true,
      default:  '',
    },
    // Default code if no user's code in storage
    defaultCode: {
      type:     String,
      required: false,
      default:  '',
    },
  },
  components: {
    Modal,
    ModalButton,
    PrimaryButton,
    WhiteButton,
    Codemirror: codemirror,
  },
  data () {
    return {
      // Code in the editor
      code:      '',
      // Code judging status
      isJudging: false,
      // Status of git dialog
      gitDialog: {
        isOpen:     false,
        isLoading:  false,
        isFetching: false,
        host:       'github',
        github:     {
          authorized: false,
          repo:       [],
        },
        gitlab: {
          authorized: false,
          repo:       [],
        },
        selectedFile: '',
      },
      // Codemirror option
      cmOptions: {
        mode:           'text/x-csrc',
        theme:          'dracula',
        indentUnit:     4,
        line:           true,
        lineNumbers:    true,
        scrollbarStyle: null,
      },
      // Compile message
      compileMsg: {
        isOpen:  false,
        message: '',
      },
      // Judge result
      judgeResult: {
        isOpen: false,
        status: 'Accepted',
        score:  100,
      },
    }
  },
  computed: {
    // Level options and submission status options for those chips' appearance
    ...mapState('problem', ['submissionStatusOption']),
    // Judge result text, with status and score
    judgeResultText () {
      return `${this.judgeResult.status} (${this.judgeResult.score}/100)`
    },
  },
  methods: {
    ...mapMutations('feature', ['setNotification']),
    ...mapActions('user', ['fetchUserProgressByProblem']),
    // Get file from user's file system
    getFileFromInput (e) {
      const file = e.target.files[0]
      if (file instanceof Blob) {
        const fr = new FileReader()
        fr.onload = event => {
          this.code = event.target.result
        }
        fr.readAsText(file)
      }
    },
    // Open git dialog with given host
    openGitDialog (host) {
      this.gitDialog.isOpen = true
      // Set current git host
      this.gitDialog.host = host
      // If the repo from given host is not available yet, fetch it
      if(!this.gitDialog[host].repo.length) {
        // Set loading state
        this.gitDialog.isLoading = true

        // Request for repo on given host
        axios.get(`/user/${host}/repos`)
          // If request successed, which means the user has authorized
          // on the given host, set authorized flag and repo tree
          .then(res => {
            this.gitDialog[host].authorized = true
            this.gitDialog[host].repo = res.data.repos
          })
          // If request failed, display error message
          .catch(error => {
            this.setNotification({
              isOpen:  true,
              type:    'error',
              message: error.response.data,
            })
          })
          // Clear loading state
          .finally(() => {
            this.gitDialog.isLoading = false
          })
      }
    },
    // Get file from third party git server
    getFileFromGit () {
      // Set loading state
      this.gitDialog.isFetching = true

      // Request for the specific file
      axios.get(`/user/${this.gitDialog.host}/file?url=${this.gitDialog.selectedFile}`)
        // If request success, put the content of file into editor and close dialog
        .then(res => {
          this.code = res.data
          this.gitDialog.isOpen = false
        })
        // If request failed, display error message
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
        })
        // Clear loading state
        .finally(() => {
          this.gitDialog.isFetching = false
        })
    },
    // Submit code to judge
    submitCode (isTesting) {
      // Reset displayers' status
      this.judgeResult.isOpen = false
      this.compileMsg.isOpen = false

      // Set loading state
      this.isJudging = true

      // Submit code to judge
      axios.post('/judge', {
        test:      isTesting,
        code:      this.code,
        problemId: this.problemId,
      })
        // If request success, display judge result
        .then(res => {
          // Display score and reward
          this.judgeResult.status = res.data.status
          this.judgeResult.score = res.data.score
          this.judgeResult.isOpen = true

          // If there is compile message, display it
          if(res.data.message !== '') {
            this.compileMsg.message = res.data.message
            this.compileMsg.isOpen = true
          }
          // WORKAROUND: update score as soon as possible
          this.$emit('judged')

          // If it is not a test submit, update user's progress
          if(!isTesting) {
            this.fetchUserProgressByProblem(this.problemId)
          }
        })
        // If request failed, display error message
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
        })
      // Clear loading status
        .finally(() => {
          this.isJudging = false
        })
    },
    // Save code in editor before user leaving this problem
    saveCode (problemId, code) {
      localStorage.setItem(problemId, code)
    },
    // Set code in editor
    setCode (problemId) {
      if(!localStorage.getItem(problemId)) {
        localStorage.setItem(problemId, this.defaultCode)
      }
      this.code = localStorage.getItem(problemId)
    },
  },
  watch: {
    problemId (newValue, oldValue) {
      this.judgeResult.isOpen = false
      this.compileMsg.isOpen = false

      if (oldValue) {
        this.saveCode(oldValue, this.code)
      }

      this.$nextTick(() => {
        this.setCode(newValue)
      })
    },
  },
  mounted () {
    this.setCode(this.problemId)
    window.addEventListener('beforeunload', this.saveCode)
  },
  beforeDestroy () {
    this.saveCode(this.problemId, this.code)
    window.removeEventListener('beforeunload', this.saveCode)
  },
}
</script>

<style lang="scss">
.code-editor {
  // Add this attribute to make v-snack's `absolute` prop work
  position: relative;

  .v-snack--absolute .v-snack__wrapper {
    margin-top: 12px;
    margin-right: 0;
  }
}

.compile-message {
  padding: 16px;
  border-top: solid 1px #F9F9F9;
  background-color: #282A36;

  & pre {
    margin: 16px 0px 0px;
    padding: 0px;
    background-color: #282A36;
  }
}
</style>
