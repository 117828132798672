<template lang="pug">
two-columns-layout
  heading Update Password
  v-form(ref="form")
    text-field(
      v-model="password.oldPassword"
      label="Old Password"
      required
      password)
    text-field(
      v-model="password.newPassword"
      label="New Password"
      required
      password)
    text-field(
      v-model="password.confirmPassword"
      label="New Password Again"
      required
      password
      :rule="[confirmed]")
  primary-button(
    :isWaitng="isLoading"
    @click="updatePassword") Update
  //- Menu panel
  template(v-slot:aside)
    setting-menu
</template>

<script>
import axios from '@/plugins/axios.js'
import { mapMutations, mapActions } from 'vuex'
import TwoColumnsLayout from '@/layouts/TwoColumnsLayout.vue'
import Heading from '@/components/common/Heading.vue'
import TextField from '@/components/form/TextField.vue'
import PrimaryButton from '@/components/buttons/PrimaryButton.vue'
import SettingMenu from '@/components/menus/SettingMenu.vue'

export default {
  components: {
    TwoColumnsLayout,
    Heading,
    TextField,
    PrimaryButton,
    SettingMenu,
  },
  data () {
    return {
      isLoading: false,
      password:  {
        oldPassword:     '',
        newPassword:     '',
        confirmPassword: '',
      },
    }
  },
  methods: {
    ...mapMutations('feature', ['setNotification']),
    ...mapActions('user', ['signOut']),
    confirmed (value) {
      return value === this.password.confirm || 'New password confirmation failed'
    },
    // Update user's password
    updatePassword () {
      // Validate each field before submitting
      if(!this.$refs.form.validate()) {
        this.setNotification({
          isOpen:  true,
          type:    'warning',
          message: 'Please ensure you filled in every field correctly',
        })
        return
      }

      this.isLoading = true

      axios.put('/user/password', this.password)
        .then(() => this.signOut())
        .then(() => {
          this.setNotification({
            isOpen:  true,
            type:    'success',
            message: 'Password updated! Now please sign in again...',
            timeout: 5000,
          })
          this.$router.push('/signin').catch(() => {})
        })
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
