<template lang="pug">
div
  input(
    name="submission"
    type="checkbox"
    :id="`submission-${submission.submissionId}`"
    @change="toggleSubmission()")
  label(:for="`submission-${submission.submissionId}`")
    floating-card.submission-card
      v-container.pa-0(fluid)
        v-row(align="center")
          v-col(cols="auto")
            //- Submit datetime
            span.text-h6 {{ dateToString(submission.timestamp) }}
          v-col(cols="auto")
            //- Judge result
            //- TODO: short status name when mobile
            v-chip(:color="submissionStatusOption[submission.status].color") {{ submission.status }}
          v-spacer
          v-col(cols="auto")
            //- Score
            span.text-h6 {{ submission.score }} / 100
          v-col(cols="auto")
            //- Download button
            v-btn(icon)
              v-icon(color="#F9F9F9B3" @click.stop="downloadCode") $download
          v-col(cols="auto")
            //- Expansion state hint
            //- Point to the bottom if the list is closed; point to the top otherwise.
            v-icon(
              size="16"
              color="#F9F9F9B3"
              :style="{'transform': isOpen ? 'rotatez(-180deg)': 'rotatez(0)'}") $triangle
        v-expand-transition
          v-row(v-show="isOpen")
            v-col(cols="12")
              v-divider
            v-col(cols="12")
              //- Submission code, display on card expand
              pre(@click.prevent="")
                template(v-if="code === ''") Loading...
                template(v-else) {{ code }}
</template>

<script>
import axios from '@/plugins/axios.js'
import { mapState, mapMutations } from 'vuex'
import FloatingCard from '@/components/cards/FloatingCard.vue'

export default {
  name:  'SubmissionCard',
  props: {
    submission: {
      type:     Object,
      required: true,
      default:  () => {},
    },
  },
  components: {
    FloatingCard,
  },
  data () {
    return {
      isOpen: false,
      code:   '',
    }
  },
  computed: {
    ...mapState('problem', ['submissionStatusOption']),
  },
  methods: {
    ...mapMutations('feature', ['setNotification']),
    // Fetch code by of this submission
    // This function should only be called when user needs to
    // e.g. when the card is opened or user want to download code
    fetchCode () {
      return axios.get(`/user/code/${this.submission.submissionId}`)
        .then(res => {
          this.code = res.data
        })
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
        })
    },
    // Open / close submission card
    toggleSubmission () {
      this.isOpen = !this.isOpen
      // If the code is not ready, fetch it
      if(this.code === '') {
        this.fetchCode()
      }
    },
    async downloadCode () {
      // If the code is not ready, fetch it
      if(this.code === '') {
        await this.fetchCode()
      }

      // Create an invisible download button and click it programmatically
      // to download code text as .c file
      const tmp = document.createElement('a')
      tmp.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.code))
      tmp.setAttribute('download', `${this.submission.timestamp.toISOString()}-${this.submission.submissionId}.c`)

      tmp.style.display = 'none'
      document.body.appendChild(tmp)

      tmp.click()

      document.body.removeChild(tmp)
    },
  },
}
</script>

<style lang="scss" scoped>
input {
  display: none;
}

.submission-card {
  cursor: pointer;

  pre {
    cursor: default;
  }
}
</style>
