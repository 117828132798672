<template lang="pug">
div.content
  bread-crumbs
  slot
</template>

<script>
import BreadCrumbs from '@/components/common/BreadCrumbs.vue'

export default {
  name:       'OneColumnLayout',
  components: {
    BreadCrumbs,
  },
}
</script>

<style lang="scss" scoped>
$margin: 28px;
$content-min-width: 320px;

.content {
  margin: $margin auto;
  padding: 0;
  max-width: 1264px;
  width: 80%;
  min-width: $content-min-width;
  min-height: calc(100vh - 126px - #{$margin} * 2);
}
</style>
