<template lang="pug">
div.heading
  h2.heading-title.mr-5.text-truncate
    slot
  div.heading-action
    slot(name="action")
  div.heading-divider.pt-3
    v-divider
</template>

<script>
export default {
  name:  'Heading',
}
</script>

<style lang="scss" scoped>

.heading {
  margin-bottom: 20px;
  width: 100%;
  display: grid;

  grid-template-rows: auto auto;
  grid-template-columns: 1fr min-content;
  grid-template-areas:
  "title action"
  "divider divider";

  .heading-title {
    grid-area: title;
    font-size: 24px;
    font-weight: 500;
  }

  .heading-action {
    grid-area: action;
  }

  .heading-divider {
    grid-area: divider;
  }
}
</style>
