<template lang="pug">
//- TODO: add visibility setting
two-columns-layout
  heading.mb-10 Profile Setting
    template(v-slot:action)
      white-button(:to="`/profile/${username}`") View Profile
  loading(v-if="isLoading")
  v-form(v-else ref="form")
    v-container.pa-0(fluid)
      v-row
        v-col.d-flex.justify-center
          v-avatar.outlined(size="156")
            img(v-if="profile.avatar" :src="profile.avatar" :alt="profile.username")
            v-icon(v-else size="156") $profile
      v-row
        v-col.d-flex.justify-center
          input(
            type="file"
            style="display: none;"
            ref="imageInput"
            accept="image/*"
            @change="avatarOnchange")
          white-button(@click="$refs.imageInput.click()")
            v-icon.mr-3 $upload
            | Upload Image
      v-row.mt-10(dense)
        v-col.d-flex(cols="12" md="6")
          v-icon.mt-3.mr-5(size="32") $profile
          v-text-field(
            v-model="profile.username"
            label="Username"
            outlined
            required
            :max-length="20")
        v-col.d-flex(cols="12" md="6")
          v-icon.mt-3.mr-5(size="32") $student
          v-text-field(
            v-model="profile.studentId"
            label="Student ID"
            outlined
            required
            disabled)
        v-col.d-flex(cols="12")
          v-icon.mt-3.mr-5(size="32") $email
          v-text-field(
            v-model="profile.email"
            label="E-mail"
            outlined
            required)
            //- :append-icon="profile.public.email ? '$eye-open' : '$eye-close'"
            //- @click:append="toggleHidden('email')")
        //- v-col.d-flex(cols="12")
          v-icon.mt-3.mr-5(size="32") $link
          v-text-field(
            v-model="profile.website"
            label="Website"
            outlined)
            //- :append-icon="profile.public.website ? '$eye-open' : '$eye-close'"
            //- @click:append="toggleHidden('website')")
        v-col.d-flex(cols="12")
          v-icon.mt-3.mr-5(size="32") $biography
          v-textarea(
            v-model="profile.biography"
            label="Biography"
            outlined
            :rules="[maxBiographyLength]")
            //- :append-icon="profile.public.biography ? '$eye-open' : '$eye-close'"
            //- @click:append="toggleHidden('biography')")
      //- v-row
        v-col.flex-center(cols="12")
          v-avatar.mr-5(size="48" color="#83A3CD")
            v-icon.pa-2.icon-outlined(size="44") $code
          span.mr-5.text-subtitle-1 Solved Problem
          v-btn(icon @click="toggleHidden('solvedProblem')")
            v-icon {{ profile.public.solvedProblem ? '$eye-open' : '$eye-close' }}
        v-col.flex-center(cols="12")
          v-avatar.mr-5(size="48" color="#75AA84")
            v-icon.pa-2.icon-outlined(size="44") $chat
          span.mr-5.text-subtitle-1 Discussion
          v-btn(icon @click="toggleHidden('discussion')")
            v-icon {{ profile.public.discussion ? '$eye-open' : '$eye-close' }}
      v-row
        v-col
          primary-button(
            :isWaiting="isUpdating"
            @click="updateProfile()") Save
  //- Menu panel
  template(v-slot:aside)
    setting-menu
</template>

<script>
import axios from '@/plugins/axios.js'
import { mapState, mapMutations, mapActions } from 'vuex'
import TwoColumnsLayout from '@/layouts/TwoColumnsLayout.vue'
import Heading from '@/components/common/Heading.vue'
import PrimaryButton from '@/components/buttons/PrimaryButton.vue'
import WhiteButton from '@/components/buttons/WhiteButton.vue'
import SettingMenu from '@/components/menus/SettingMenu.vue'
import Loading from '../../components/common/Loading.vue'

export default {
  components: {
    TwoColumnsLayout,
    Heading,
    PrimaryButton,
    WhiteButton,
    SettingMenu,
    Loading,
  },
  data () {
    return {
      isLoading:  true,
      isUpdating: false,
      profile:    {
        avatar:    '',
        username:  '',
        studentId: '',
        email:     '',
        website:   '',
        biography: '',
        public:    {
          email:         true,
          website:       true,
          biography:     true,
          // solvedProblem: false,
          // discussion:    false,
        },
      },
    }
  },
  computed: {
    // Profile data retrived from store
    ...mapState('user', ['username', 'studentId', 'avatar', 'email']),
  },
  methods: {
    ...mapMutations('feature', ['setNotification']),
    ...mapActions('user', ['updateUserInfo']),
    maxBiographyLength (value) {
      return value ? value.length <= 200 : true
    },
    toggleHidden (fieldName) {
      this.profile.public[fieldName] = !this.profile.public[fieldName]
    },
    // The event that avatar changes
    avatarOnchange (event) {
      const image = event.target.files[0]
      // Image size can't exceed 1 MB.
      if(image.size > 1048576) {
        this.setNotification({
          isOpen:  true,
          type:    'error',
          message: 'Maximum file size Exceeded.',
        })
        return
      }

      if (image instanceof Blob) {
        const fileReader = new FileReader()
        fileReader.onload = () => {
          this.profile.avatar = fileReader.result
        }
        fileReader.readAsDataURL(image)
      }
    },
    // Update profile
    updateProfile () {
      // Validate each field before submitting
      if(!this.$refs.form.validate()) {
        this.setNotification({
          isOpen:  true,
          type:    'warning',
          message: 'Please ensure you filled in every field correctly',
        })
        return
      }

      this.isUpdating = true

      // TODO: remove this line
      this.profile.public.biography = true

      // Update user's information
      this.updateUserInfo(this.profile)
        .then(() => {
          this.setNotification({
            isOpen:  true,
            type:    'success',
            message: 'Profile updated!',
          })
        })
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
        })
        .finally(() => {
          this.isUpdating = false
        })
    },
  },
  mounted () {
    // TODO: get from /user/profile
    axios.get(`/users/${this.username}`)
      .then(res => {
        this.profile = {
          avatar:    this.avatar,
          username:  this.username,
          studentId: this.studentId,
          email:     this.email,
          ...res.data,
        }
      })
      .catch(error => {
        this.setNotification({
          isOpen:  true,
          type:    'error',
          message: error.response.data,
        })
      })
      .finally(() => {
        this.isLoading = false
      })
  },
}
</script>

<style lang="scss" scoped>
.outlined {
  outline: 2px solid #F9F9F9;
  border: 4px solid transparent;
}

.icon-outlined {
  border: #30333F 2px solid;
}

</style>
