<template lang="pug">
v-breadcrumbs.pa-0.mb-5(
  v-if="!!breadcrumbs"
  :items="breadcrumbs"
  divider=">"
  large)
  template(v-slot:item="{ item }")
    v-breadcrumbs-item(
      exact
      :to="item.to"
      :disabled="item.disabled") {{ item.text }}
</template>

<script>
export default {
  name:     'BreadCrumbs',
  computed: {
    breadcrumbs () {
      if (typeof this.$route.meta.breadcrumbs === 'function') {
        return this.$route.meta.breadcrumbs.call(this, this.$route)
      }
      return this.$route.meta.breadcrumbs
    },
  },
}
</script>
