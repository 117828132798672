<template lang="pug">
v-menu(
  bottom
  offset-y
  nudge-bottom="20")
  template(v-slot:activator="activator")
    v-btn(
      v-bind="activator.attrs"
      v-on="activator.on"
      text
      :width="maxOptionWidth"
      @click="setFocus(activator.value)")
      span.mr-3.text-capitalize.text-h6 {{ value }}
      v-icon(
        size="16"
        color="#B5B5B5"
        :style="{'transform': activator.value ? 'rotatez(-180deg)': 'rotatez(0)'}") $triangle
  floating-card.pa-3
    div.clickable-list
      //- Each option
      div.pa-2.text-subtitle-1.text-center(
        v-for="(option, idx) in options"
        :key="`filter-option-${idx}`"
        @click="change(option)") {{ option }}
</template>

<script>
import FloatingCard from '@/components/cards/FloatingCard.vue'

export default {
  name:  'ListFilter',
  props: {
    options: {
      type:    Array,
      require: true,
      default: () => [],
    },
    value: {
      type:     String,
      required: true,
      default:  '',
    },
  },
  components: {
    FloatingCard,
  },
  computed: {
    // WORKAROUND: calculate maximum option's width programatically
    // to give the list filter enough width.
    maxOptionWidth () {
      const c = document.createElement('canvas')
      const ctx = c.getContext('2d')
      ctx.font = '1.25rem Noto Sans TC'

      const longestOption = this.options.slice().sort((a, b) => b.length - a.length)[0]
      const optionWidth = ctx.measureText(longestOption).width
      const padding = 20
      const spacer = 12
      const iconSize = 16

      c.remove()

      return optionWidth + padding * 2 + spacer + iconSize
    },
  },
  methods: {
    change (value) {
      this.$emit('input', value)
      this.$emit('blur')
    },
    setFocus (isClosed) {
      if (isClosed) {
        this.$emit('blur')
      }
      else {
        this.$emit('focus')
      }
    },
  },
  mounted () {
    this.change(this.options[0])
  },
}
</script>
