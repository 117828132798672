<template lang="pug">
//- TODO: wait for menu-props works on v-autocomplete
v-autocomplete.problem-picker(
  outlined
  multiple
  item-value="id"
  item-text="title"
  :items="problems"
  :value="selected"
  :label="label"
  :rules="activeRules"
  :search-input.sync="searchInput"
  @input="change")
  //- Autocomplete heading
  template(v-slot:selection="{item}")
    v-chip(
      close
      :color="levelOption[item.level].color"
      @click:close="remove(item)") {{ item.title }}
  //- Autocomplete item
  template(v-slot:item="{attrs, on, item, active}")
    v-list-item(
      v-bind="attrs"
      v-on="on"
      v-slot:default="{active}")
      v-list-item-action
        v-checkbox(:input-value="active")
      div.list-item-content
        v-chip.level-chip(:color="levelOption[item.level].color") {{ levelOption[item.level].name }}
        div
          span.mr-5 {{ item.title }}
          v-chip(
            v-if="item.chapter"
            color="#F9F9F9"
            text-color="#30333F") {{ item.chapter.index }}
        v-btn(icon @click.stop="openProblem(item.id)")
          v-icon mdi-pencil
</template>

<script>
import { mapState } from 'vuex'

export default {
  name:       'ProblemPicker',
  props: {
    value: {
      type:     Array,
      required: true,
    },
    label: {
      type:     String,
      required: false,
      default:  '',
    },
    required: {
      type:     Boolean,
      required: false,
      default:  false,
    },
    rules: {
      type:     Array,
      required: false,
      default:  () => [],
    },
  },
  data () {
    return {
      selected:    [],
      searchInput: null,
      validations: {
        required: value => (!!value || value.length > 0) || 'Required',
      },
    }
  },
  computed: {
    ...mapState('problem', ['problems', 'levelOption']),
    activeRules () {
      let rules = []
      if(this.required) {
        rules.push(this.validations.required)
      }
      rules = rules.concat(this.rules)
      return rules
    },
  },
  methods: {
    openProblem (problemId) {
      const { href } = this.$router.resolve({
        name:   'manage-update-problem',
        params: { id: problemId },
      })
      window.open(href, '_blank')
    },
    change (value) {
      this.searchInput = null
      this.$emit('input', value)
    },
    remove (target) {
      const index = this.selected.findIndex(item => target.id === item)
      this.selected.splice(index, 1)
    },
  },
  watch: {
    value () {
      this.selected = this.value
    },
  },
}
</script>

<style lang="scss" scoped>
.list-item-content {
  width: 100%;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 100px auto 36px;
  align-items: center;

  .level-chip {
    justify-self: center;
  }
}
</style>

<style lang="scss">
// The menu inside the autocomplete is outside of scss' "scoped" scope,
// so we need to separate its style from the "scoped" block
.v-autocomplete__content.v-menu__content {
  box-shadow: 2px 2px 6px #F9F9F980;
  border: 1px solid #F9F9F9;
  border-radius: 5px;
}
</style>
