<template lang="pug">
//- Add wrapper to make margin and padding setting outside work
div.date-picker
  //- Date picker with dialog
  v-dialog(
    v-model="isOpen"
    max-width="min-content"
    @click:outside="cancel()")
    template(v-slot:activator="{ on, attrs }")
      //- Selected date value
      v-text-field.text-field(
        v-bind="attrs"
        v-on="on"
        :value="dateToString(value)"
        :label="label"
        :rules="activeRules"
        readonly
        clearable
        outlined
        @input="change")
    v-card
      v-card-title.d-flex.justify-center
        span.datetime-value.mr-3(@click="state = 'date'") {{ dateString }}
        span.datetime-value(@click="state = 'time'") {{ timeString }}
      v-window(v-model="state")
        v-window-item(value="date")
          //- Date picker
          v-date-picker(
            v-model="date"
            :allowed-dates="allowedDates"
            :events="events"
            @change="state = 'time'")
        v-window-item(value="time")
          //- Time picker
          v-time-picker(
            v-model="time"
            format="24hr")
      v-card-actions
        v-spacer
        v-btn(@click="change") OK
</template>

<script>
export default {
  name:  'DateTimePicker',
  props: {
    value: {
      type:      Date,
      required:  false,
      default:   null,
    },
    label: {
      type:     String,
      required: false,
      default:  '',
    },
    required: {
      type:     Boolean,
      required: false,
      default:  false,
    },
    allowedDates: {
      type:     Function,
      required: false,
      default:  null,
    },
    events: {
      type:     Function,
      required: false,
      default:  null,
    },
  },
  data () {
    return {
      isOpen:      false,
      state:       'date',
      date:        '',
      time:        '',
      validations: {
        required: value => this.isValidDate(new Date(value)) || 'Required',
      },
    }
  },
  computed: {
    activeRules () {
      let rules = []
      if(this.required) {
        rules.push(this.validations.required)
      }
      return rules
    },
    dateString () {
      return this.date ? this.date : '----/--/--'
    },
    timeString () {
      return this.time ? this.time : '--:--'
    },
  },
  methods: {
    change () {
      this.isOpen = false
      this.state = 'date'
      if(this.date && this.time) {
        const v = new Date(`${this.date} ${this.time}`)
        this.$emit('input', v)
      }
      else {
        this.$emit('input', null)
      }
    },
    cancel () {
      this.state = 'date'
      this.setDateTime()
    },
    setDateTime () {
      if(this.value) {
        this.date = this.dateToDateString(this.value).replace(/\//g, '-')
        this.time = this.value.toLocaleTimeString('zh-TW', {
          hour:      '2-digit',
          minute:    '2-digit',
          hourCycle: 'h23',
        })
      }
    },
  },
  watch: {
    value () {
      this.setDateTime()
    },
  },
  mounted () {
    this.setDateTime()
  },
}
</script>

<style lang="scss" scoped>
.datetime-value {
  cursor: pointer;
  transition: color .2s;
  font-size: 24px;

  &:hover {
    color: #FFD06F;
  }
}
</style>
