<template lang="pug">
two-columns-layout
  //- Form heading and buttons
  heading Announcement Information
    template(v-slot:action)
      //- Delete announcement button, only clickable in update page
      v-btn(
        icon
        :loading="isDeleting"
        :disabled="isDeleting || action === 'create'"
        @click.prevent="isDeleting = true")
        v-icon(
          size="32"
          color="#FF6E6E") $trashcan
  v-form(ref="form")
    v-container(fluid)
      v-row
        v-col(cols="12" sm="8")
          //- Announcement title
          text-field(
            v-model="announcement.title"
            label="Title"
            required)
        v-col(cols="auto" sm="4")
          //- Announcement type
          dropdown-list(
            v-model="announcement.type"
            label="Type"
            :items="Object.keys(announcementTypes)"
            required)
      v-row
        v-col
          //- Announcement content
          rich-text-editor(
            v-model="announcement.content"
            label="Content"
            @change="formDirty = true")
      v-row
        v-col(cols="auto")
          //- Submit button
          primary-button(
            :isWaiting="isLoading"
            @click="submitForm()") {{ action }}
        v-col(cols="auto")
          //- Cancel button
          primary-button(
            hollow
            :isWaiting="isLoading"
            @click="goToAnnouncementList()") Cancel
  //- Confirm delete modal
  modal(:isOpen="isDeleting")
    template(v-slot:title)
      span Delete "{{ announcement.title }}"
    template(v-slot:content)
      p Do you really want to delete "{{ announcement.title }}"?
    template(v-slot:actions)
      modal-button.mr-5(@click="deleteAnnouncement()") Delete
      modal-button(hollow @click="isDeleting = false") Cancel
  //- Menu panel
  template(v-slot:aside)
    manage-menu
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import TwoColumnsLayout from '@/layouts/TwoColumnsLayout.vue'
import Heading from '@/components/common/Heading.vue'
import ManageMenu from '@/components/menus/ManageMenu.vue'
import PrimaryButton from '@/components/buttons/PrimaryButton.vue'
import TextField from '@/components/form/TextField.vue'
import DropdownList from '../../components/form/DropdownList.vue'
import RichTextEditor from '@/components/form/RichTextEditor.vue'
import Modal from '@/components/common/Modal.vue'
import ModalButton from '@/components/buttons/ModalButton.vue'

export default {
  components: {
    TwoColumnsLayout,
    Heading,
    ManageMenu,
    PrimaryButton,
    TextField,
    DropdownList,
    RichTextEditor,
    Modal,
    ModalButton,
  },
  data () {
    return {
      action:       'update',
      isLoading:    true,
      isDeleting:   false,
      announcement: {
        // Index of this announcement
        id:      this.$route.params.id || '',
        // Title of this annoucement
        title:   '',
        // Content of this annoucement
        content: '',
        // Type of this announcement
        type:    'announcement',
      },
      formDirty: false,
    }
  },
  computed: {
    ...mapState('announcement', { announcementTypes: 'types' }),
    ...mapGetters('announcement', ['announcementById']),
  },
  methods: {
    ...mapMutations('feature', ['setNotification']),
    ...mapActions('announcement', {
      _createAnnouncement: 'createAnnouncement',
      _updateAnnouncement: 'updateAnnouncement',
      _deleteAnnouncement: 'deleteAnnouncement',
    }),
    // Return to announcement list page
    goToAnnouncementList () {
      this.$router.push('/manage/announcements').catch(()=>{})
    },
    // Submit form
    submitForm () {
      // Validate each field before submitting
      if(!this.$refs.form.validate()) {
        this.setNotification({
          isOpen:  true,
          type:    'warning',
          message: 'Please ensure you filled in every field correctly',
        })
        return
      }

      // Decide which action to do
      this[`${this.action}Announcement`]()
    },
    // Create announcement
    createAnnouncement () {
      // Set loading state
      this.isLoading = true

      // Create announcement
      this._createAnnouncement(this.announcement)
        // If success, show success message and return to announcement list
        .then(() => {
          this.formDirty = false
          this.setNotification({
            isOpen:  true,
            type:    'success',
            message: 'New announcement created!',
          })
          this.goToAnnouncementList()
        })
        // If there is any error, show notification and clear loading state
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
          this.isLoading = false
        })
    },
    // Update announcement
    updateAnnouncement () {
      // Set loading state
      this.isLoading = true

      // Update announcement
      this._updateAnnouncement(this.announcement)
        // If success, show success message
        .then(() => {
          this.formDirty = false
          this.setNotification({
            isOpen:  true,
            type:    'success',
            message: 'Announcement updated!',
          })
        })
        // If there is any error, show notification
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
        })
        // Clear loading state
        .finally(() => {
          this.isLoading = false
        })
    },
    // Delete announcement
    deleteAnnouncement () {
      // Delete announcement
      this._deleteAnnouncement(this.announcement)
        // If success, show success message and return to announcement list
        .then(() => {
          this.formDirty = false
          this.setNotification({
            isOpen:  true,
            type:    'success',
            message: 'Announcement deleted!',
          })
          this.goToAnnouncementList()
        })
        // If there is any error, show notification and clear deleting state
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
          this.isDeleting = false
        })
    },
  },
  mounted () {
    // If announcement id is not empty string,
    // which means user is updating announcements, need to fetch data first
    if(this.announcement.id) {
      // Assign announcement with new object to prevent shallow copy
      this.announcement = {
        ...this.announcementById(this.announcement.id),
      }
    }
    // Else, it means user is creating announcement
    else {
      this.action = 'create'
    }

    this.isLoading = false
  },
  beforeRouteLeave (to, from, next) {
    if(this.formDirty && !this.confirmRouteChange()) {
      next(false)
    }
    else {
      next()
    }
  },
}
</script>
