<template lang="pug">
div
  input(
    name="problem"
    type="radio"
    :id="`problem-${problem.id}`"
    @change="$emit('click')")
  label(:for="`problem-${problem.id}`")
    floating-card.problem-card
      v-overlay(
        absolute
        opacity="0.75"
        :value="!!problem.hidden")
        v-icon $eye-close
      v-container.pa-0(fluid)
        v-row(align="center")
          v-col(cols="12" sm="6" md="8")
            //- Title of the problem
            p.text-h6.text-truncate {{ problem.title }}
            //- Tags of the problem
            div.flex-center
              v-chip.mr-4(
                v-for="tag in problem.tags"
                :key="`problem-${problem.id}-tag-${tag}`"
                color="#B5B5B5"
                text-color="#30333F"
                @click.prevent="$emit('search-tag', tag)") {{ tag }}
          v-spacer
          v-col(cols="6" sm="auto")
            //- Difficulty (level) of the problem
            v-chip(:color="levelOption[problem.level].color") {{ levelOption[problem.level].name }}
          v-col.text-end(cols="6" sm="auto")
            //- Score that user get on this problem (out of 100)
            span.text-h6 {{ problem.score }} / 100
</template>

<script>
import { mapState } from 'vuex'
import FloatingCard from '@/components/cards/FloatingCard.vue'

export default {
  name:  'ExamProblemCard',
  props: {
    problem: {
      type:     Object,
      required: true,
      default:  () => { },
    },
  },
  components: {
    FloatingCard,
  },
  computed: {
    ...mapState('problem', ['levelOption']),
  },
}
</script>

<style lang="scss" scoped>
input {
  display: none;

  &:checked ~ label > .problem-card {
    border-color: #B5B5B5;
  }
}

.problem-card {
  // Add this attribute to make v-overlay's `absolute` prop work
  position: relative;

  border: 2px solid #30333F;
  color: #F9F9F9;
  text-decoration: none;
  cursor: pointer;
  transition: all .2s;

  &:hover {
    border-color: #B5B5B5;
  }

  &:active {
    box-shadow: 10px 10px 6px #F9F9F933;
  }
}
</style>
