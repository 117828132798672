<template lang="pug">
floating-card.problem-card(:to="`/problems/${problem.id}`")
  //- Since `problem.hidden` is not available for normal user,
  //- which means it will be `undefined`, so the `!!` is required
  //- to get the boolean value of this property properly.
  v-overlay(
    absolute
    opacity="0.75"
    :value="!!problem.hidden")
    v-icon $eye-close
  v-container.pa-0(fluid)
    v-row(align="center")
      v-col(cols="12" sm="6" md="8")
        //- Title of the problem
        p.text-h6.text-truncate {{ problem.title }}
        //- Tags of the problem
        div.flex-center
          v-chip.mr-4(
            v-for="tag in problem.tags"
            :key="`problem-${problem.id}-tag-${tag}`"
            color="#B5B5B5"
            text-color="#30333F"
            @click.prevent="$emit('search-tag', tag)") {{ tag }}
      v-spacer
      v-col(cols="6" sm="auto")
        //- Difficulty (level) of the problem
        v-chip(:color="levelOption[problem.level].color") {{ levelOption[problem.level].name }}
      v-col.text-end(cols="6" sm="auto")
        //- Score that user get on this problem (out of 100)
        span.text-h6 {{ problem.score }} / 100
</template>

<script>
import { mapState } from 'vuex'
import FloatingCard from '@/components/cards/FloatingCard.vue'

export default {
  name:  'ProblemCard',
  props: {
    problem: {
      type:     Object,
      required: true,
      default:  () => {},
    },
  },
  components: {
    FloatingCard,
  },
  computed: {
    ...mapState('problem', ['levelOption']),
  },
}
</script>

<style lang="scss" scoped>
.problem-card {
  // Add this attribute to make v-overlay's `absolute` prop work
  position: relative;
}
</style>
