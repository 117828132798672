<template lang="pug">
one-column-layout
  //- List header
  //- TODO: use heading to implement
  v-container.pa-0.mb-5(fluid)
    v-row
      v-col(cols="12" sm="6" md="7" lg="8")
        //- Problem title
        h2.text-truncate.font-weight-medium {{ title }}
      v-spacer
      v-col.text-center.text-sm-end(cols="12" sm="6" md="5" lg="4")
        //- Status filter
        list-filter(
          v-model="filter.value"
          :options="filter.options"
          @focus="filter.isOpen = true"
          @blur="filter.isOpen = false")
    v-row(dense)
      v-col
        v-divider
    v-row.submissions
      v-col
        //- Submission list
        v-overlay.ma-0(absolute :value="filter.isOpen")
        card-list(:items="visibleSubmissions")
          template(v-slot:default="{item}")
            submission-card(:submission="item")
</template>

<script>
import axios from '@/plugins/axios.js'
import store from '@/store'
import { mapMutations, mapState } from 'vuex'
import OneColumnLayout from '@/layouts/OneColumnLayout.vue'
import ListFilter from '@/components/common/ListFilter.vue'
import CardList from '@/components/lists/CardList.vue'
import SubmissionCard from '@/components/cards/SubmissionCard.vue'
import Loading from '@/components/common/Loading.vue'

export default {
  components: {
    OneColumnLayout,
    ListFilter,
    CardList,
    SubmissionCard,
    Loading,
  },
  data () {
    return {
      title:       '',
      submissions: [],
      filter:      {
        name:    'status',
        isOpen:  false,
        options: [
          'All',
          'Accepted',
          'Wrong Answer',
          'Compile Error',
          'Time Limit Exceeded',
          'Memory Limit Exceeded',
          'Runtime Error',
        ],
        value: '',
      },
    }
  },
  computed: {
    ...mapState('problem', ['problems']),
    // Submissions filtered by status
    visibleSubmissions () {
      const tmp = this.submissions.slice()
      if(this.filter.value === 'All') {
        return tmp
      }
      else {
        return tmp.filter(submission => submission.status === this.filter.value)
      }
    },
  },
  methods: {
    ...mapMutations('feature', ['setPageLoading', 'setNotification']),
    setSubmission (data, error) {
      if (error) {
        this.setNotification({
          isOpen:  true,
          type:    'error',
          message: error,
        })
        return
      }

      this.submissions = data.submissions
      this.submissions.forEach(submission => {
        submission.timestamp = new Date(submission.timestamp)
      })
      this.submissions = this.submissions.sort((a, b) => b.timestamp - a.timestamp)

      this.title = data.title
      this.setTitle(`Submissions - ${this.title}`)

      this.setPageLoading(false)
    },
  },
  beforeRouteEnter (to, _, next) {
    store.commit('feature/setPageLoading', true)
    Promise.all([
      axios.get(`/user/submission/${to.params.id}`),
      axios.get(`/problems/${to.params.id}`),
    ])
      .then(results => {
        const submissions = results[0].data.submissionInfo
        const problem = results[1].data

        to.meta.breadcrumbs = to.meta._breadcrumbs.concat([
          {
            text:     problem.title,
            to:       `/problems/${problem.id}`,
            disabled: false,
          },
          {
            text:     'Submission',
            disabled: true,
          },
        ])

        next(vm => vm.setSubmission({
          submissions,
          title: problem.title,
        }, null))
      })
      .catch(error => {
        if(error.response.status === 404) {
          next('/notFound')
        }
        else {
          next(vm => vm.setSubmission(null, error.response.data))
        }
      })
  },
  beforeRouteUpdate (to, _, next) {
    this.setPageLoading(true)
    Promise.all([
      axios.get(`/user/submission/${to.params.id}`),
      axios.get(`/problems/${to.params.id}`),
    ])
      .then(results => {
        const submissions = results[0].data.submissionInfo
        const problem = results[1].data

        to.meta.breadcrumbs = to.meta._breadcrumbs.concat([
          {
            text:     problem.title,
            to:       `/problems/${problem.id}`,
            disabled: false,
          },
          {
            text:     'Submission',
            disabled: true,
          },
        ])

        this.setSubmission({
          submissions,
          title: problem.title,
        }, null)
        next()
      })
      .catch(error => {
        if(error.response.status === 404) {
          next('/notFound')
        }
        else {
          this.setSubmission(null, error.response.data)
          next()
        }
      })
  },
}
</script>

<style lang="scss" scoped>
.submissions {
  // Add this attribute to make v-overlay's `absolute` prop work
  position: relative;
}
</style>
