<template lang="pug">
div.flex-center
  //- Left arrow button
  //- Go to previous page when click left arrow button
  //- Disable button when current page is the first page
  v-btn.mr-2(
    v-blur
    icon
    :disabled="value === 1"
    @click="changePage(value - 1)")
    v-icon(size="16" style="transform: rotatez(90deg)") $triangle
  //- Pages' button
  //- Change page selected button's style from circle to circle-selected,
  //- and change now page button's style from circle-selected to circle.
  //- `showPage`: determine which page is displayed
  //- `changePage`: change page when clicking other pages that is not current page
  template(v-for="page in (length === 0 ? 1 : length)")
    v-btn.mx-1(
      v-show="showPage(page)"
      v-blur
      fab
      small
      :light="page === value"
      :outlined="page !== value"
      @click="changePage(page)")
        span.page-number {{ page }}
    //- Show ellipsis if page is equal to leftEllipsis or rightEllipsis
    span.page-number.mx-1(v-show="page === leftEllipsis || page === rightEllipsis") ...
  //- Right arrow button
  //- Go to next page when click right arrow button
  //- Disable button when current page is the last page
  v-btn.ml-2(
    v-blur
    icon
    :disabled="value === length || length <= 1"
    @click="changePage(value + 1)")
    v-icon(size="16" style="transform: rotatez(-90deg)") $triangle
</template>

<script>
export default {
  props: {
    length: {
      type:     Number,
      required: true,
      default:  1,
    },
    value: {
      type:     Number,
      required: true,
      default:  1,
    },
  },
  data () {
    return {
      leftEllipsis:  0,
      rightEllipsis: 0,
    }
  },
  computed: {
    leftPage () {
      return this.value - 1
    },
    rightPage () {
      return this.value + 1
    },
  },
  methods: {
    // Change page if the target page is not current page
    // and trigger input event for `v-model` on this component.
    changePage (target) {
      if(target >= 1 && target <= this.length && this.value != target) {
        this.$emit('input', target)
      }
    },
    // determine which page is displayed
    showPage (page) {
      if(this.value === page) {
        return true
      }
      // The first page and the last page must be displayed
      else if(page === 1 || page === this.length) {
        return true
      }
      // If current page is at the start, culculate left & right ellipsis
      else if(this.value === 1 && (page === 2 || page === 3)) {
        this.leftEllipsis = 0
        this.rightEllipsis = this.length === 4 ? 0 : 3
        return true
      }
      // If current page is at the end, culculate left & right ellipsis
      else if(this.value === this.length && (page === this.length - 1 || page === this.length - 2)) {
        this.leftEllipsis = this.length - 3 === 1 ? 0 : this.length - 3
        this.rightEllipsis = 0
        return true
      }
      // If page is equal to left or right page, culculate left & right ellipsis
      else if(page === this.leftPage || page === this.rightPage) {
        this.leftEllipsis = this.value - 2 === 1 ? 0 : this.value - 2
        this.rightEllipsis = this.value + 1 === this.length || this.value + 2 === this.length ? 0 : this.value + 1
        return true
      }
      return false
    },
  },
}
</script>

<style lang="scss" scoped>
.page-number {
  font-size: 16px;
}
</style>
