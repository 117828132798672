<template lang="pug">
two-columns-layout
  //- Notice header
  heading Frequently Asked Question
  rich-text-editor(
    v-model="faq"
    @change="formDirty = true")
  primary-button(:isWaiting="isUpdating" @click="updateFAQ") Update
  //- Menu panel
  template(v-slot:aside)
    manage-menu
</template>

<script>
import axios from '@/plugins/axios.js'
import { mapMutations } from 'vuex'
import TwoColumnsLayout from '@/layouts/TwoColumnsLayout.vue'
import Heading from '@/components/common/Heading.vue'
import ManageMenu from '@/components/menus/ManageMenu.vue'
import RichTextEditor from '@/components/form/RichTextEditor.vue'
import PrimaryButton from '@/components/buttons/PrimaryButton.vue'

export default {
  components: {
    TwoColumnsLayout,
    Heading,
    ManageMenu,
    RichTextEditor,
    PrimaryButton,
  },
  data () {
    return {
      isUpdating: false,
      faq:        '',
      formDirty:  false,
    }
  },
  methods: {
    ...mapMutations('feature', ['setNotification']),
    updateFAQ () {
      this.isUpdating = true
      axios.put('/static/faq', {
        content: this.faq,
      })
        .then(() => {
          this.formDirty = false
          this.setNotification({
            isOpen:  true,
            type:    'success',
            message: 'FAQ is updated',
          })
        })
        // If there is any error other than 404, show notification
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
        })
        .finally(() => {
        // Clear loading state
          this.isUpdating = false
        })
    },
  },
  mounted () {
    axios.get('/static/faq')
      .then(res => {
        this.faq = res.data
        this.formDirty = false
      })
      // If there is any error other than 404, show notification
      .catch(error => {
        this.setNotification({
          isOpen:  true,
          type:    'error',
          message: error.response.data,
        })
      })
  },
  beforeRouteLeave (to, from, next) {
    if(this.formDirty && !this.confirmRouteChange()) {
      next(false)
    }
    else {
      next()
    }
  },
}
</script>
