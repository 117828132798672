import { render, staticRenderFns } from "./Problems.vue?vue&type=template&id=b7a7b166&scoped=true&lang=pug"
import script from "./Problems.vue?vue&type=script&lang=js"
export * from "./Problems.vue?vue&type=script&lang=js"
import style0 from "./Problems.vue?vue&type=style&index=0&id=b7a7b166&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b7a7b166",
  null
  
)

export default component.exports