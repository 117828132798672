<template lang="pug">
two-columns-layout
  heading Third Party Account Authentication
  v-container(fluid)
    v-row
      v-col
        p Connect your github or gitlab account to upload your code from your git repository!
    v-row
      v-col(cols="auto")
        white-button.mr-5(@click="authenticate('https://github.com/login/oauth/authorize?client_id=2cf21a71cbb1bdf4e7b3&scope=repo')")
          v-icon.mr-3 mdi-github
          | Authenticate for github
      v-col(cols="auto")
        white-button(@click="authenticate(`https://gitlab.com/oauth/authorize?client_id=c5fb0c9ef53e72a5606077f04d38e48c718b22057b604c60111d1d19d78b2cd5&redirect_uri=${origin}/user/gitlab/oauth&response_type=code&scope=api`)")
          v-icon.mr-3 mdi-gitlab
          | Authenticate for gitlab
  //- Menu panel
  template(v-slot:aside)
    setting-menu
</template>

<script>
import TwoColumnsLayout from '@/layouts/TwoColumnsLayout.vue'
import Heading from '@/components/common/Heading.vue'
import WhiteButton from '@/components/buttons/WhiteButton.vue'
import SettingMenu from '@/components/menus/SettingMenu.vue'

export default {
  components: {
    TwoColumnsLayout,
    Heading,
    WhiteButton,
    SettingMenu,
  },
  data () {
    return {
      origin: window.location.origin,
    }
  },
  methods: {
    authenticate (url) {
      window.open(url)
    },
  },
}
</script>
