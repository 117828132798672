<template lang="pug">
v-select(
  outlined
  clearable
  deletable-chips
  :multiple="multiple"
  :small-chips="multiple"
  :menu-props="{'content-class':'dropdown-menu scrollable', offsetY: true}"
  :label="label"
  :items="items"
  :value="value"
  :rules="activeRules"
  @change="change")
  template(v-slot:prepend-item)
    slot(name="prepend-item")
</template>

<script>
export default {
  name:  'DropdownList',
  props: {
    items: {
      type:     Array,
      required: true,
    },
    value: {
      type:     [String, Array],
      required: false,
      default:  null,
    },
    label: {
      type:     String,
      required: false,
      default:  '',
    },
    required: {
      type:     Boolean,
      required: false,
      default:  false,
    },
    multiple: {
      type:     Boolean,
      required: false,
      default:  false,
    },
    rules: {
      type:     Array,
      required: false,
      default:  () => [],
    },
  },
  data () {
    return {
      validations: {
        required: value => {
          if(this.multiple) {
            return value.length > 0 || 'Required'
          }
          else{
            return !!value || 'Required'
          }
        },
      },
    }
  },
  computed: {
    activeRules () {
      let rules = []
      if(this.required) {
        rules.push(this.validations.required)
      }
      rules = rules.concat(this.rules)
      return rules
    },
  },
  methods: {
    change (value) {
      this.$emit('input', value)
    },
  },
}
</script>

<style lang="scss">
// The menu inside the drop down list is outside of scss' "scoped" scope,
// so we need to separate its style from the "scoped" block
.dropdown-menu {
  box-shadow: 2px 2px 6px #F9F9F940;
  border: 1px solid #F9F9F9;
  border-radius: 5px;
}
</style>
