<template lang="pug">
div(v-if="$vuetify.breakpoint.mdAndUp")
  multipane.problem-layout(layout="vertical")
    //- Problem basic information
    floating-card.information-pane(:style="paneStyle")
      scroll-bar.pr-3(height="100%")
        slot(name="info")
    multipane-resizer
    div.editor-pane(:style="paneStyle")
      scroll-bar.pr-3(height="100%")
        slot(name="editor")
div.problem-layout-mobile(v-else)
  div.mb-10
    slot(name="info")
  slot(name="editor")
</template>

<script>
import { Multipane, MultipaneResizer } from 'vue-multipane'
import OneColumnLayout from '@/layouts/OneColumnLayout.vue'
import FloatingCard from '@/components/cards/FloatingCard.vue'
import ScrollBar from '@/components/common/ScrollBar.vue'

export default {
  components: {
    Multipane,
    MultipaneResizer,
    OneColumnLayout,
    FloatingCard,
    ScrollBar,
  },
  computed: {
    paneStyle () {
      return {
        width:    '50%',
        minWidth: '30%',
        maxWidth: '70%',
        height:   '100%',
      }
    },
  },
}
</script>

<style lang="scss">
$margin: 28px;
$nav-bar-height: 126px;
$resizer-handler-width: 4px;
$resizer-handler-height: 40px;
$breadcrumbs-height: 44px;
$mobile-min-width: 320px;

.problem-layout {
  margin: 0 $margin;
  padding-bottom: $margin;
  width: calc(100% - #{$margin} * 2);
  height: calc(100vh - #{$nav-bar-height} - #{$breadcrumbs-height});

  .editor-pane {
    flex-grow: 1;
  }

  .multipane-resizer {
    margin: 0 8px;
    left: 0;
    position: relative;
    transition: all .5s;

    &:before {
      display: block;
      content: "";
      width: $resizer-handler-width;
      height: $resizer-handler-height;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: calc(-#{$resizer-handler-height} / 2);
      margin-left: calc(-#{$resizer-handler-width} / 2);
      border-left: 1px solid #B5B5B5;
      border-right: 1px solid #B5B5B5;
      transition: all .5s;
    }

    &:hover, &:active {
      background-color: #B5B5B580;

      &:before {
        border-color: #30333F;
      }
    }
  }
}

.problem-layout-mobile {
  margin: $margin;
  padding: 0;
  min-width: $mobile-min-width;
  min-height: calc(100vh - 126px - #{$margin} * 2);
}
</style>
