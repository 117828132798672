<template lang="pug">
v-container#container(fluid)
</template>

<script>
import * as PIXI from 'pixi.js'
import IconButton from '@/components/story/components/IconButton.js'
import Dialogue from '@/components/story/components/Dialogue.js'
import MapScene from '@/components/story/scenes/MapScene.js'
import LabScene from '@/components/story/scenes/LabScene.js'
import MenuScene from '@/components/story/scenes/MenuScene.js'

export default {
  data () {
    return {
      app:        null,
      dialogue:   null,
      sceneStack: [],
      scenes:     null,
      sceneLock:  false,
    }
  },
  computed: {
    width () {
      if (window.innerWidth / (window.innerHeight - 48) > 16 / 9) {
        return ((window.innerHeight - 48) * 16) / 9
      }
      else {
        return window.innerWidth
      }
    },
    height () {
      if (window.innerWidth / (window.innerHeight - 48) > 16 / 9) {
        return window.innerHeight - 48
      }
      else {
        return (window.innerWidth * 9) / 16
      }
    },
  },
  mounted () {
    // Create Pixi application and scale it to maximum size under 16:9
    this.app = new PIXI.Application({
      width:  this.width,
      height: this.height,
    })
    PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.NEAREST
    this.$el.appendChild(this.app.view)

    // Instantiate scenes
    this.scenes = {
      MapScene:  new MapScene(this.app),
      LabScene:  new LabScene(this.app),
      MenuScene: new MenuScene(this.app),
    }

    // Add MapScene
    // TODO: Add staring scene instead
    this.addScene(this.scenes['MapScene'])

    // Add menu button
    const menuButton = new IconButton({
      name:    'menuButton',
      icon:    require('@/assets/story/menu.png'),
      handler: () => {
        window.dispatchEvent(
          new CustomEvent('add-scene', { detail: {
            name:   'MenuScene',
            option: {},
          } }),
        )
      },
    })
    menuButton.position.set(16, 16)
    this.app.stage.addChild(menuButton)

    // Add dialogue
    this.dialogue = new Dialogue(this.app)
    this.app.stage.addChild(this.dialogue.container)

    // Add scene changing event listeners
    window.addEventListener('add-scene', (e) => {
      this.addScene(this.scenes[e.detail.name], e.detail.option)
    })
    window.addEventListener('remove-scene', () => {
      this.removeScene()
    })
  },
  methods: {
    // Load and add new scene onto scene stack
    // For each type of scenes, you cannot add more than one of it onto the stack
    async addScene (newScene, option) {
      if (this.sceneLock === false) {
        // Acquire lock
        this.sceneLock = true
        newScene.load(option)
        if (this.sceneStack.length > 0) {
          await this.sceneStack[this.sceneStack.length - 1].fadeOut()
        }
        this.sceneStack.push(newScene)
        await newScene.fadeIn()
        // Release lock
        this.sceneLock = false
      }
    },
    // Remove currently active scene and back to previous one
    async removeScene () {
      if (this.sceneLock === false && this.sceneStack.length > 1) {
        // Acquire lock
        this.sceneLock = true
        await this.sceneStack[this.sceneStack.length - 1].fadeOut()
        await this.sceneStack[this.sceneStack.length - 2].fadeIn()
        const removedScene = this.sceneStack.pop()
        removedScene.unload()
        // Release lock
        this.sceneLock = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#container {
  display: grid;
  place-content: center;
  height: calc(100vh - 48px);
}
</style>
