<template lang="pug">
two-columns-layout
  v-form(ref="form")
    //- Form heading and buttons
    heading Problem Information
      template(v-slot:action)
        div.flex-center.flex-nowrap
          //- Hide / unhide button
          v-btn.mr-5(icon @click.prevent="problem.hidden = !problem.hidden")
            v-icon(
              v-if="problem.hidden"
              size="32"
              color="#F9F9F9B3") $eye-close
            v-icon(v-else size="32") $eye-open
          //- Delete problem button, only clickable in update page
          v-btn.mr-5(
            icon
            :loading="isDeleting"
            :disabled="isDeleting || action === 'create'"
            @click.prevent="isDeleting = true")
            v-icon(size="32" color="#FF6E6E") $trashcan
    p Fill the basic information for students to introduce the problem.
    div.d-flex
      //- Problem title
      text-field.mr-6(
        v-model="problem.title"
        label="Title"
        required)
      dropdown-list.flex-grow-0(
        v-model="chapter"
        :items="chapters"
        label="Chapter")
    div.d-flex
      dropdown-list.flex-grow-0.mr-6(
        v-model="problem.level"
        :items="Object.keys(levelOption)"
        label="Difficulty"
        required)
      dropdown-list(
        v-model="problem.tags"
        :items="tags"
        label="Tags"
        multiple)
        template(v-slot:prepend-item)
          v-list-item(@click="isAddingTag = true")
            v-list-item-action
              v-icon mdi-plus
            v-list-item-content
              v-list-item-title Create New Tag
          v-divider
    div.d-flex
      text-field.mr-6(
        v-model="problem.memLimit"
        label="Memory Limit (KB)"
        required
        :rules="[isInteger, isValidRangeMemLimit]")
      text-field(
        v-model="problem.timeLimit"
        label="Time Limit (s)"
        required
        :rules="[isInteger, isValidRangeTimeLimit]")
    rich-text-editor.mb-6(
      v-model="problem.description"
      label="Description")
    heading Problem Validation
    p Fill the critical information about the problem for students to verify if there are working in correct way.
    h3 Input / Output Format Description
    p Please describe the format or the representation of input and output.
    div.d-flex
      text-editor.mr-6(
        v-model="problem.inputFormat"
        label="Input Format"
        required)
      text-editor(
        v-model="problem.outputFormat"
        label="Output Format"
        required)
    div.d-flex
      text-field(
        v-model="problem.hint"
        label="Hint")
    h3 Loader code
    p Please fill in your problem's execution part.
    div.d-flex
      text-editor(
        v-model="problem.loaderCode"
        label="Loader Code")
    h3 Template code
    p Please fill the prototype of the part you want student to implement.
    div.d-flex
      text-editor(
        v-model="problem.templateCode"
        label="Template Code"
        required)
    h3 Testcases
    p Upload testcase files. Please double confirm that the input / output files match each other.
    div.d-flex
      file-input.mr-6(
        v-model="inputFiles"
        label="New Input File")
      file-input(
        v-model="outputFiles"
        label="New Output File")
    div.testcases.mt-5
      div.testcases-header
        span Index
        span Sample
        span Input
        span Output
        span Remove
      div.testcases-body
        p.ma-0.text-center(v-if="problem.testcases.length === 0 && newTestcases.length === 0") No file uploaded
        template(v-else)
          //- Existing testcases
          div.existing-testcase(
            v-for="(testcase, index) of problem.testcases"
            :key="`file-${index}`"
            :style="{'opacity': testcase.removed ? 0.5 : 1}")
            //- Index
            span # {{ index + 1 }}
            //- Is this testcase marked as sample
            v-simple-checkbox(v-model="testcase.isSample")
            //- Testing input
            v-btn(
              text
              :disabled="!testcase.hasInput"
              @click="openTestcaseFile('input', testcase.id)") {{ testcase.hasInput ? testcase.id : 'empty' }}
            //- Testing output (generated by server)
            v-btn(
              text
              @click="openTestcaseFile('output', testcase.id)") {{ testcase.id }}
            //- Remove testcase button
            v-btn(icon @click="testcase.removed = !testcase.removed")
              v-icon(v-if="testcase.removed" color="#52CC75") mdi-restore
              v-icon(v-else color="#FF6E6E") $trashcan
          //- New testcases
          div.new-testcase(
            v-for="(testcase, index) of newTestcases"
            :key="`file-new-${index}`")
            //- Index
            span # {{ index + problem.testcases.length + 1 }}
            //- Is this testcase marked as sample
            v-simple-checkbox(v-model="testcase.isSample" color="#52CC75")
            //- Testing input
            span {{ testcase.input }}
            //- Testing output
            span {{ testcase.output }}
            //- Remove testcase button
            v-btn(icon @click="removeNewTestcase(index)")
              v-icon(color="#FF6E6E") $trashcan
  div.d-flex.mt-10
    //- Submit button
    primary-button.mr-9(
      :isWaiting="isLoading"
      @click="submitForm()") {{ action }}
    //- Cancel button
    primary-button(
      hollow
      :isWaiting="isLoading"
      @click="goToProblemList()") Cancel
  //- Confirm delete modal
  modal(:isOpen="isDeleting")
    template(v-slot:title)
      span Delete {{ problem.index }}
    template(v-slot:content)
      p Do you really want to delete {{ problem.index }}?
    template(v-slot:actions)
      modal-button.mr-5(@click="deleteProblem()") Delete
      modal-button(hollow @click="isDeleting = false") Cancel
  //- Create new tag modal
  modal(:isOpen="isAddingTag")
    template(v-slot:title)
      span Create new tag
    template(v-slot:content)
      p Enter new tag name:
      v-text-field(
        color="#1F2327"
        v-model="newTag"
        label="Tag"
        dense
        outlined
        required
        :max-length="30")
    template(v-slot:actions)
      modal-button.mr-5(@click="createNewTag()") Create
      modal-button(hollow @click="isAddingTag = false") Cancel
  //- Menu panel
  template(v-slot:aside)
    manage-menu
</template>

<script>
import axios from '@/plugins/axios.js'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import TwoColumnsLayout from '@/layouts/TwoColumnsLayout.vue'
import Heading from '@/components/common/Heading.vue'
import ManageMenu from '@/components/menus/ManageMenu.vue'
import PrimaryButton from '@/components/buttons/PrimaryButton.vue'
import TextField from '@/components/form/TextField.vue'
import TextEditor from '@/components/form/TextEditor.vue'
import RichTextEditor from '@/components/form/RichTextEditor.vue'
import DropdownList from '@/components/form/DropdownList.vue'
import FileInput from '@/components/form/FileInput.vue'
import Modal from '@/components/common/Modal.vue'
import ModalButton from '@/components/buttons/ModalButton.vue'

export default {
  components: {
    TwoColumnsLayout,
    Heading,
    ManageMenu,
    PrimaryButton,
    TextField,
    TextEditor,
    RichTextEditor,
    DropdownList,
    FileInput,
    Modal,
    ModalButton,
  },
  data () {
    return {
      action:      'update',
      isLoading:   true,
      isDeleting:  false,
      isAddingTag: false,
      problem:     {
        id:           this.$route.params.id,
        title:        '',
        description:  '',
        inputFormat:  '',
        outputFormat: '',
        hint:         '',
        level:        '',
        tags:         [],
        timeLimit:    1,
        memLimit:     5000,
        loaderCode:   '',
        templateCode: '#include<stdio.h>\n\nint main () {\n    return 0;\n}\n',
        hidden:       true,
        testcases:    [],
      },
      chapter:      '',
      newTag:       '',
      newTags:      [],
      // New input file array, containing file binary
      inputFiles:   [],
      // New output file array, containing file binary
      outputFiles:  [],
      // New files array, containing file name and status
      newTestcases: [],
      formDirty:    false,
    }
  },
  computed: {
    ...mapState('problem', ['levelOption']),
    ...mapGetters('problem', { existingTag: 'tags' }),
    ...mapGetters('chapter', ['chapters']),
    tags () {
      return [...this.existingTag, ...this.newTags]
    },
  },
  watch: {
    inputFiles () {
      this.setNewTestcases()
    },
    outputFiles () {
      this.setNewTestcases()
    },
    problem: {
      handler () {
        this.formDirty = true
      },
      deep: true,
    },
    newTestcases: {
      handler () {
        this.formDirty = true
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations('feature', ['setNotification']),
    ...mapActions('problem', []),
    ...mapActions('problem', {
      _createProblem:        'createProblem',
      _updateProblem:        'updateProblem',
      _deleteProblem:        'deleteProblem',
    }),
    // Set problem information
    setProblem () {
      // If this problem has index, it means that this is an existing problem
      // and user wnat to update it. Set form data to the problem's data.
      if(this.problem.id) {
        axios.get(`/problems/${this.problem.id}`)
          .then(res => {
            this.problem = {
              ...this.problem,
              ...res.data,
              testcases: res.data.testcases.map(testcase => ({
                ...testcase,
                removed: false,
              })),
            }
            this.chapter = this.problem.chapter ? this.problem.chapter.index : ''
          })
          // If there is any error, show notification and clear loading state
          .catch(error => {
            this.setNotification({
              isOpen:  true,
              type:    'error',
              message: error.response.data,
            })
          })
          .finally(() => {
            this.isLoading = false
          })
      }
      // Else, it means that user is in create problem page,
      // set action to 'create'
      else {
        this.isLoading = false
        this.action = 'create'
      }
    },
    // Return to problem list page
    goToProblemList () {
      this.$router.push('/manage/problems').catch(()=>{})
    },
    // Validate if the value is integer value
    isInteger (value) {
      return Number.isInteger(Number(value)) || 'Judge time should be an integer'
    },
    // Validate if the memory limit field is in the appropriate range
    isValidRangeMemLimit (value) {
      return Number(value) >= 5000 || 'At least provide 5000 KB memory'
    },
    // Validate if the time limit field is in the appropriate range
    isValidRangeTimeLimit (value) {
      return Number(value) >= 1 || 'At least provide 1 second judge time'
    },
    // Create new tag option
    createNewTag () {
      this.newTags.push(this.newTag)
      this.newTag = ''
      this.isAddingTag = false
    },
    // Open testcase file in new tab
    openTestcaseFile (type, id) {
      // Open testcase file in a new tab
      window.open(`${window.location.origin}/problems/testcase/${type}/${id}`)
    },
    // Set `newTestcases` field using `inputFiles` and `outputFiles`
    setNewTestcases () {
      // Make sure input file array and output file array have the same length
      const maxLength = Math.max(this.inputFiles.length, this.outputFiles.length)
      this.newTestcases = []
      for (let i = 0; i < maxLength; ++i) {
        this.newTestcases.push({
          input:    this.inputFiles[i] ? this.inputFiles[i].name : 'empty',
          output:   this.outputFiles[i] ? this.outputFiles[i].name : 'empty',
          isSample: false,
        })
      }
    },
    // Remove a testcase from `newTestcases`
    removeNewTestcase (index) {
      // Remove its input file (if it has input file)
      if(this.inputFiles.length > index) {
        this.inputFiles.splice(index, 1)
      }
      // Remove its output file (if it has output file)
      if(this.outputFiles.length > index) {
        this.outputFiles.splice(index, 1)
      }
    },
    // Submit form
    submitForm () {
      // Validate each field before submitting
      if(!this.$refs.form.validate()) {
        this.setNotification({
          isOpen:  true,
          type:    'warning',
          message: 'Please ensure you filled in every field correctly',
        })
        return
      }

      // Use FormData to create / update problem, so we can handle file uploading
      const formData = new FormData()

      // Append premitive type field
      Object.keys(this.problem).forEach(field => {
        const type = typeof this.problem[field]
        if (type === 'string' || type === 'number' || type === 'boolean') {
          formData.append(field, this.problem[field])
        }
      })

      // Append chapter
      formData.append('chapter', this.chapter)

      // Append tag as array
      this.problem.tags.forEach(tag => {
        formData.append('tags', tag)
      })

      // Look through existing testcases and set / clear sample mark and removed flag
      this.problem.testcases.forEach(testcase => {
        if (testcase.removed)
          formData.append('deleteTestcases', testcase.id)
        if (testcase.isSample)
          formData.append('sampleMarks', testcase.id)
        else
          formData.append('deleteSamples', testcase.id)
      })

      // Append input / output files. Files that have same index are paired
      this.newTestcases.forEach((testcase, index) => {
        // If this testcase is marked as sample, also put it into sampleMarks
        if (testcase.isSample)
          formData.append('sampleMarks', testcase.output)
        if (testcase.input !== 'empty')
          formData.append('input', this.inputFiles[index], testcase.input)
        formData.append('output', this.outputFiles[index], testcase.output)
      })

      // Decide which action to do
      this[`${this.action}Problem`](formData)
    },
    // Create problem
    createProblem (data) {
      // Set loading state
      this.isLoading = true

      // Create problem
      this._createProblem(data)
        // If success, show success message and return to problem list
        .then(() => {
          this.formDirty = false
          this.setNotification({
            isOpen:  true,
            type:    'success',
            message: 'New problem created!',
          })
          this.goToProblemList()
        })
        // If there is any error, show notification and clear loading state
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
          this.isLoading = false
        })
    },
    // Update problem
    updateProblem (data) {
      // Set loading state
      this.isLoading = true

      // Update problem
      this._updateProblem({
        id:      this.problem.id,
        payload: data,
      })
        // If success, reset problem data and show success message
        .then(data => {
          this.problem = {
            ...this.problem,
            ...data,
          }
          this.chapter = this.problem.chapter ? this.problem.chapter.index : ''
          this.newTags = []
          this.inputFiles = [],
          this.outputFiles = [],
          this.newTestcases = [],
          this.formDirty = false

          this.setNotification({
            isOpen:  true,
            type:    'success',
            message: 'Problem information updated!',
          })
        })
        // If there is any error, show notification
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
        })
        // Clear loading state
        .finally(() => {
          this.isLoading = false
        })
    },
    // Delete problem
    deleteProblem () {
      // Delete problem
      this._deleteProblem({
        id: this.problem.id,
      })
        // If success, show success message and return to problem list
        .then(() => {
          this.formDirty = false
          this.setNotification({
            isOpen:  true,
            type:    'success',
            message: 'Problem deleted!',
          })
          this.goToProblemList()
        })
        // If there is any error, show notification and clear deleting state
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
          this.isDeleting = false
        })
    },
  },
  mounted () {
    this.setProblem()
  },
  beforeRouteLeave (to, from, next) {
    if(this.formDirty && !this.confirmRouteChange()) {
      next(false)
    }
    else {
      next()
    }
  },
}
</script>

<style lang="scss" scoped>
.testcases-header,
.existing-testcase,
.new-testcase {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 4fr 4fr 1fr;
  justify-items: center;
  align-items: center;
  transition: opacity .12s;
}

.testcases-header {
  background-color: #F9F9F9;
  color: #30333F;
  font-weight: 500;
  border-radius: 5px 5px 0px 0px;
  padding: 6px;
}

.testcases-body {
  border: 1px solid #F9F9F9;
  border-radius: 0px 0px 5px 5px;
  padding: 16px 0px;
}

.new-testcase {
  color: #52CC75
}
</style>
