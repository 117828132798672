<template lang="pug">
div.two-columns-layout
  bread-crumbs.breadcrumb
  div.content
    //- Main content
    slot
  //- Aside
  aside
    floating-card
      slot(name="aside")
</template>

<script>
import BreadCrumbs from '@/components/common/BreadCrumbs.vue'
import FloatingCard from '@/components/cards/FloatingCard.vue'

export default {
  name:       'TwoColumnsLayout',
  components: {
    BreadCrumbs,
    FloatingCard,
  },
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

$margin: 28px;
$aside-width: 320px;
$nav-bar-height: 126px;

.two-columns-layout {
  padding: $margin;
  width: 100%;
  min-width: calc(#{$aside-width} + #{$margin} * 2);
  min-height: calc(100vh - #{$nav-bar-height} - #{$margin} * 2);
  display: grid;
  justify-content: center;

  grid-template-rows: min-content auto;
  grid-template-columns: minmax(auto, 1264px) $aside-width;
  grid-template-areas:
  "breadcrumbs breadcrumbs"
  "content aside";
  column-gap: $margin;

  @media only screen and (max-width: #{map-get($grid-breakpoints, "md")}) {
    grid-template-rows: min-content min-content $margin auto;
    grid-template-columns: 100%;
    grid-template-areas:
    "breadcrumbs"
    "aside"
    "."
    "content";
  }

  .breadcrumbs {
    grid-area: breadcrumbs;
  }

  .content {
    grid-area: content;
  }

  aside {
    grid-area: aside;
    height: 100%;
    position: relative;

    & > .floating-card {
      position: sticky;
      top: $nav-bar-height;
    }
  }
}
</style>
