<template lang="pug">
one-column-layout
  heading Frequently asked questions
  div.rich-text(v-if="faq" v-html="faq")
  loading(v-else)
</template>

<script>
import axios from '@/plugins/axios.js'
import { mapMutations } from 'vuex'
import OneColumnLayout from '@/layouts/OneColumnLayout.vue'
import Heading from '@/components/common/Heading.vue'
import Loading from '@/components/common/Loading.vue'

export default {
  components: {
    OneColumnLayout,
    Heading,
    Loading,
  },
  data () {
    return {
      faq: '',
    }
  },
  methods: {
    ...mapMutations('feature', ['setNotification']),
  },
  mounted () {
    axios.get('/static/faq')
      .then(res => {
        this.faq = res.data
      })
      // If there is any error other than 404, show notification
      .catch(error => {
        this.setNotification({
          isOpen:  true,
          type:    'error',
          message: error.response.data,
        })
      })
  },
}
</script>
