<template lang="pug">
div.container
  date-time-picker(
    v-model="date"
    label="Date time picker"
    required)
  dropdown-list(
    v-model="dropdown"
    label="Drop-down list"
    :items="fakeSelectData"
    required)
  file-input(
    v-model="files"
    label="File input")
  problem-picker(
    v-model="problems"
    label="Problem picker"
    required
    :items="fakeData"
    :rules="[lala]")
  rich-text-editor(
    v-model="richText"
    label="Rich text editor")
  text-editor(
    v-model="longText"
    label="Text editor"
    required)
  text-field(
    v-model="shortText"
    label="Text field"
    required
    :max-length="20")
</template>

<script>
import DateTimePicker from '@/components/form/DateTimePicker.vue'
import DropdownList from '@/components/form/DropdownList.vue'
import FileInput from '@/components/form/FileInput.vue'
import ProblemPicker from '@/components/form/ProblemPicker.vue'
import RichTextEditor from '@/components/form/RichTextEditor.vue'
import TextEditor from '@/components/form/TextEditor.vue'
import TextField from '@/components/form/TextField.vue'

export default {
  data () {
    return {
      date:      null,
      dropdown:  '',
      files:     [],
      problems:  [],
      richText:  '',
      longText:  '',
      shortText: '',
    }
  },
  components: {
    DateTimePicker,
    DropdownList,
    FileInput,
    ProblemPicker,
    RichTextEditor,
    TextEditor,
    TextField,
  },
  watch: {
    date (val) {
      console.log(val)
    },
    dropdown (val) {
      console.log(val)
    },
    files (val) {
      console.log(val)
    },
    problems (val) {
      console.log(val)
    },
    richText (val) {
      console.log(val)
    },
    longText (val) {
      console.log(val)
    },
    shortText (val) {
      console.log(val)
    },
  },
  methods: {
    lala (value) {
      return value.length === 6 || 'Must select 6 problems'
    },
  },
  computed: {
    fakeSelectData () {
      return [
        'Balanced Binary Tree',
        'Populating Next Right Pointers in Each Node',
        'Kth Smallest Element in a BST',
        'Binary Tree Paths',
        'Binary Tree Tilt',
        'Two Sum IV - Input is a BST',
        'Second Minimum Node In a Binary Tree',
        'Leaf-Similar Trees',
        'Linked List Cycle',
        'Linked List Cycle II',
      ]
    },
    fakeData () {
      return [
        {
          id:    '1',
          title:       'Balanced Binary Tree',
          level: 'Easy',
        },
        {
          id:    '2',
          title:       'Populating Next Right Pointers in Each Node',
          level: 'Medium',
        },
        {
          id:    '3',
          title:       'Populating Next Right Pointers in Each Node',
          level: 'Easy',
        },
        {
          id:    '4',
          title:       'Kth Smallest Element in a BST',
          level: 'Hard',
        },
        {
          id:    '5',
          title:       'Binary Tree Paths',
          level: 'Easy',
        },
        {
          id:    '6',
          title:       'Binary Tree Tilt',
          level: 'Medium',
        },
        {
          id:    '7',
          title:       'Two Sum IV - Input is a BST',
          level: 'Medium',
        },
        {
          id:    '8',
          title:       'Second Minimum Node In a Binary Tree',
          level: 'Easy',
        },
        {
          id:    '9',
          title:       'Leaf-Similar Trees',
          level: 'Medium',
        },
        {
          id:    '10',
          title:       'Linked List Cycle',
          level: 'Easy',
        },
        {
          id:    '11',
          title:       'Linked List Cycle II',
          level: 'Hard',
        },
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  width: 800px;

  * {
    margin: 10px;
  }
}
</style>
