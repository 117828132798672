<template lang="pug">
v-app
  navigation-bar
  //- Application content
  v-main.pt-0
    vue-page-transition(name="fade")
      v-overlay(
        v-if="pageLoading"
        :value="pageLoading"
        opacity="1")
        loading(size="100")
    vue-page-transition(name="fade")
      router-view
  //- TODO: Finish footer
  //- footer
    div Powered by IMSLab
  //- Notification snackbar at screen bottom
  v-snackbar(
    bottom
    center
    v-model="notification.isOpen"
    :color="notification.color[notification.type]"
    :timeout="notification.timeout")
    span {{ notification.message }}
    template(v-slot:action="{ attrs }")
      v-btn(
        text
        v-bind="attrs"
        @click="setNotification({ isOpen: false })") Close
  //- Message popup
  modal(
    :isOpen="popup.isOpen"
    :color="popup.color[popup.type]")
    template(v-slot:title)
      span {{ popup.title }}
    template(v-slot:content)
      div.rich-text.light.popup-content(v-html="popup.content")
    template(v-slot:actions)
      modal-button(
        :color="popup.color[popup.type]"
        @click="setPopup({ isOpen: false })") OK
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import PrimaryButton from '@/components/buttons/PrimaryButton.vue'
import User from '@/components/common/User.vue'
import Notifications from '@/components/common/Notifications.vue'
import Modal from '@/components/common/Modal.vue'
import ModalButton from '@/components/buttons/ModalButton.vue'
import NavigationBar from '@/components/common/NavigationBar.vue'
import Loading from '@/components/common/Loading.vue'

export default {
  name:       'App',
  components: {
    PrimaryButton,
    User,
    Notifications,
    Modal,
    ModalButton,
    NavigationBar,
    Loading,
  },
  computed: {
    ...mapState('feature', ['pageLoading', 'notification', 'popup']),
  },
  methods: {
    ...mapMutations('feature', ['setNotification', 'setPopup']),
  },
}
</script>

<style lang="scss">
#app {
  min-height: 100vh;
  padding-top: 126px;

  .v-application--wrap {
    min-height: unset;
  }
}

.page-loading {
  height: calc(100vh - 126px);
}

footer {
  padding: 1.5rem 1rem;
  background-color: #30333F;
  text-align: center;
}

.popup-content {
  max-width: 90%;
}
</style>
