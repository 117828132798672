<template lang="pug">
div.tile-list
  template(v-if="Object.keys(items).length")
    template(v-for="(item, key) in items")
      slot(:item="item")
  template(v-else)
    p {{ noResultMessage }}
</template>

<script>
export default {
  name:       'TileList',
  props: {
    items: {
      type:     Object,
      required: true,
      default:  () => {},
    },
    noResultMessage: {
      type:     String,
      required: false,
      default:  'No result',
    },
  },
}
</script>

<style lang="scss" scoped>
.tile-list {
  width: 100%;
  margin-bottom: 44px;
  display: grid;
  row-gap: 40px;
  column-gap: 15px;
  grid-template-columns: repeat(auto-fill, 274px);
  justify-content: space-between;
}

@media only screen and (max-width: 718px) {
  .tile-list {
    justify-content: center;
  }
}
</style>
