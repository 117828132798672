// Import Vue
import Vue from 'vue'

// Import page transition plugin
import VuePageTransition from 'vue-page-transition'

// Import Tiptag, a rich-text editor plugin
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'

// Import vuetify form validator
import Vuelidate from 'vuelidate'

// Import socket.io
import { io } from 'socket.io-client'

// Import root component or tools
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

// Import global styling
import './styles/main.scss'

// `Vue.use()` global method has to be done before starting app by calling `new Vue()`.
// Use page transition plugin
Vue.use(VuePageTransition)
// Use vuetify form validator
Vue.use(Vuelidate)
// Use tiptap vuetify plugin
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdi',
})

// Add socket connection to application prototype so every component can access it
Vue.prototype.$socket = io('wss://ckj.imslab.org', {
  transports: ['websocket'],
})

Vue.directive('blur', {
  inserted (el) {
    el.onfocus = (ev) => ev.target.blur()
  },
})

// Global mixin
Vue.mixin({
  // Set page title for each page
  mounted () {
    if (this.$parent && this.$parent.$options.name === 'vue-page-transition') {
      if (this.$route.meta.title) {
        this.setTitle(this.$route.meta.title)
      }
    }
  },
  // Define helper function for date-string conversion
  methods: {
    setTitle (title) {
      document.title = `${title} - CK Judge`
    },
    isValidDate (date) {
      return date instanceof Date && !isNaN(date)
    },
    dateToString (date) {
      return this.isValidDate(date) ? date.toLocaleString('zh-TW', { hourCycle: 'h23' }) : ''
    },
    dateToDateString (date) {
      return this.isValidDate(date) ? date.toLocaleDateString('zh-TW', {
        year:  'numeric',
        month: '2-digit',
        day:   '2-digit',
      }) : ''
    },
    stringToDate (string) {
      const date = new Date(string)
      return this.isValidDate(date) ? date : null
    },
    confirmRouteChange () {
      return window.confirm('Do you sure you want to leave? Changes you made may not be saved.')
    },

  },
})

// Prevent the production tip on Vue startup.
Vue.config.productionTip = false

// Start app
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
