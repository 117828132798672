<template lang="pug">
v-container.pa-0(fluid)
  v-row(
    v-for="(item, index) in currentItems"
    :key="`card-${index}`")
    v-col
      slot(:item="item")
  v-row(v-if="!currentItems.length")
    v-col
      p.text-center {{ noResultMessage }}
  v-row
    v-spacer
    v-col(cols="auto")
      pagination(v-model="currentPage" :length="pages")
</template>

<script>
import Pagination from '@/components/common/Pagination.vue'

export default {
  name:       'CardList',
  components: {
    Pagination,
  },
  props: {
    items: {
      type:     Array,
      required: true,
      default:  null,
    },
    noResultMessage: {
      type:     String,
      required: false,
      default:  'No result',
    },
  },
  data () {
    return {
      itemsPerPage: 12,
      currentPage:  1,
    }
  },
  computed: {
    pages () {
      return Math.ceil(this.items.length / this.itemsPerPage)
    },
    currentItems () {
      return this.items.slice(
        (this.currentPage - 1) * this.itemsPerPage,
        this.currentPage * this.itemsPerPage)
    },
  },
  watch: {
    pages () {
      this.currentPage = 1
    },
  },
}
</script>
