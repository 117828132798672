import * as PIXI from 'pixi.js'
import Scene from '@/components/story/prototypes/Scene.js'
import TextButton from '@/components/story/components/TextButton.js'

class MapScene extends Scene {
  constructor (app) {
    super(app)
    this.fontSize = 24
    this.padding = 16
    this.labList = [[
      {
        text: '數位晶片設計實驗室',
        id:   '65A01',
      },
      {
        text: '電腦架構與晶片設計實驗室',
        id:   '65A02',
      },
      {
        text: '分散式計算實驗室',
        id:   '65A03',
      },
      {
        text: '智慧化行動服務實驗室',
        id:   '65A04',
      },
    ], [
      {
        text: '高速計算及智慧視覺系統實驗室',
        id:   '65901',
      },
      {
        text: '生醫影像實驗室',
        id:   '65902',
      },
      {
        text: '智慧型知識管理實驗室',
        id:   '65903',
      },
      {
        text: '機器人實驗室',
        id:   '65904',
      },
      {
        text: '創新系統軟體應用實驗室',
        id:   '65912',
      },
    ]]
  }

  load () {
    super.load()
    this.container.name = 'MapScene'

    this.labList.forEach((floor, idx) => {
      const card = new PIXI.Container()
      const pane = new PIXI.Graphics()
      pane.lineStyle(2, 0x647086, 1)
      pane.beginFill(0x262d37, 1)
      pane.drawRoundedRect(0, 0, Math.min(this.app.view.width * 0.8, 800), this.padding * 3 + this.fontSize * 2, 16)
      pane.endFill()
      card.addChild(pane)

      let cursor = [this.padding, this.padding]
      floor.forEach(lab => {
        const labText = new TextButton({
          text:  lab.text,
          style: {
            fontSize: this.fontSize,
            fill:     '#f2f2f2',
          },
          handler: () => {
            window.dispatchEvent(new CustomEvent('add-scene', {
              detail: {
                name:   'LabScene',
                option: { id: lab.id },
              },
            }))
          },
        })
        if (cursor[0] + lab.text.length * this.fontSize + this.padding > pane.width - this.padding) {
          cursor[0] = this.padding
          cursor[1] += this.fontSize + this.padding
        }
        labText.position.set(...cursor)
        cursor[0] += lab.text.length * this.fontSize + this.padding
        card.addChild(labText)
      })
      card.position.set((this.app.view.width - card.width) / 2, this.padding + (card.height + this.padding) * idx)
      this.container.addChild(card)
      this.app.stage.addChild(this.container)
    })
  }
}

export default MapScene

// 數位晶片設計實驗室(65A01)
// 電腦架構與晶片設計實驗室(65A02)
// 分散式計算實驗室(65A03)
// 智慧化行動服務實驗室(65A04)
// 高速計算及智慧視覺系統實驗室(65901)
// 生醫影像實驗室(65902)
// 智慧型知識管理實驗室(65903)
// 機器人實驗室(65904)
// 創新系統軟體應用實驗室(65912)
// 多媒體人機通訊實驗室(65801)
// 多語心智服務實驗室(65802)
// 互連網路暨高效率計算實驗室(65803)
// 生醫超音波系統實驗室(65804)
// 電腦繪圖研究群/視覺系統實驗室(65701)
// 視覺系統實驗室(65702)
// 可靠計算及網路實驗室(65703)
// 前瞻系統研究實驗室(65704)
// 音樂多媒體系統實驗室(65707)
// 生醫暨語言資訊實驗室(65708)
// 多媒體與電腦視覺實驗室(65601)
// 前瞻網路資料庫實驗室/分散式帳本實驗室(65602)
// 量子資訊與網路安全實驗室(65603)
// 智慧型資訊擷取實驗室(65604)
// 塵間感知與雲端計算實驗室(65607)
// 精準醫療與網路系統實驗室(65501)
// 計算機網路架構實驗室(65502)
// 高速網路實驗室(65503)
// 智慧型系統暨媒體處理實驗室(65507)
// 軟體工程實驗室(75512)
// 聯網型嵌入式應用與技術實驗室(65402)
// 智慧型數位影像處理實驗室(65403)
// 軟體發展實驗室(65407)
// 作業系統與嵌入式系統實驗室(65409)
// 神經運算與腦機界面實驗室(65301)
// 高等資料系統(ADS)實驗室(65302)
// 多媒體行動電腦網路實驗室(65303)
// 企業工程與整合實驗室(95604)
// 生產力最佳化實驗室(95608)
// 自動化實驗室(95619)
// 製造管理實驗室(95623)
// 資訊與機電整合實驗室(95507)
// 製造與行動資料庫實驗室(95510)
// 智慧生產實驗室(95522)
// 應用系統設計與智慧整合實驗室(95523)
