<template lang='pug'>
one-column-layout
  heading {{ title }}
  //- TODO: implement post component
  //- TODO: add pagination to post list
  floating-card.mb-10(v-if="posts[bonusId]")
    v-container.pa-0(fluid)
      v-row.flex-nowrap(align="center")
        v-col(cols="auto")
          router-link(:to="`/profile/${posts[bonusId].author}`")
            v-avatar(size="48")
              //- Display avatar if it is existing
              img(
                v-if="users[posts[bonusId].author].avatar"
                :src="users[posts[bonusId].author].avatar"
                :alt="posts[bonusId].author")
              //- Else, display account icon
              v-icon(v-else size="48" color="#B5B5B5") $profile
        v-col
          p.mb-1.text-h6.text-truncate
            router-link(:to="`/profile/${posts[bonusId].author}`") {{ posts[bonusId].author }}
          p.mb-0.text-truncate {{ dateToString(posts[bonusId].createdAt) }}
      v-row
        v-col
          v-divider
      v-row
        v-col
          div.rich-text(v-html="posts[bonusId].content")
  div.mb-10
    heading Write an answer
    rich-text-editor(
      v-model="answer.content"
      @change="formDirty = true")
    primary-button(:isWaiting="isReplying" @click="reply()") Post
  heading Answers
  v-container.pa-0(fluid)
    v-row(v-if="replyIds.length === 0")
      v-col
        p No one answer yet!! Try to be the first :D
    v-row(
      v-else
      v-for="(replyId, index) in replyIds"
      :key="`reply-${replyId}`")
      v-col(cols="12")
        floating-card
          v-container.pa-0(fluid)
            v-row(align="center")
              v-col(cols="auto")
                span.text-h5 # {{ index + 1 }}
              v-col(cols="auto")
                router-link(:to="`/profile/${posts[replyId].author}`")
                  v-avatar(size="48")
                    //- Display avatar if it is existing
                    img(
                      v-if="users[posts[replyId].author].avatar"
                      :src="users[posts[replyId].author].avatar"
                      :alt="posts[replyId].author")
                    //- Else, display account icon
                    v-icon(v-else size="48" color="#B5B5B5") $profile
              v-col(cols="auto")
                p.mb-1.text-h6.text-truncate
                  router-link(:to="`/profile/${posts[replyId].author}`") {{ posts[replyId].author }}
                p.mb-0.text-truncate {{ dateToString(posts[replyId].createdAt) }}
              v-spacer
              v-col(cols="auto")
                vote-buttons(
                  :likeCount="likeCount(replyId)"
                  :dislikeCount="dislikeCount(replyId)"
                  :userVote="userVote(replyId)"
                  :isVoting="isVoting === replyId"
                  @vote="votePost(replyId, $event)")
            v-row
              v-col
                v-divider
            v-row
              v-col
                div.rich-text(v-html="posts[replyId].content")
            v-row(v-if="posts[replyId].author === username")
              v-col(cols="auto")
                v-btn(
                  text
                  color="#FF6E6E"
                  @click="isDeleting = replyId")
                  v-icon.mr-2(color="#FF6E6E") $trashcan
                  span Delete
  //- Confirm delete modal
  modal(:isOpen="!!isDeleting")
    template(v-slot:title)
      span Delete your post
    template(v-slot:content)
      p Do you really want to delete your reply?
    template(v-slot:actions)
      modal-button.mr-5(@click="removePost()") Delete
      modal-button(hollow @click="isDeleting = ''") Cancel
</template>

<script>
import axios from '@/plugins/axios.js'
import store from '@/store'
import { mapMutations, mapState } from 'vuex'
import OneColumnLayout from '@/layouts/OneColumnLayout.vue'
import Loading from '@/components/common/Loading.vue'
import Heading from '@/components/common/Heading.vue'
import FloatingCard from '../components/cards/FloatingCard.vue'
import RichTextEditor from '@/components/form/RichTextEditor.vue'
import PrimaryButton from '@/components/buttons/PrimaryButton.vue'
import VoteButtons from '../components/buttons/VoteButtons.vue'
import Modal from '@/components/common/Modal.vue'
import ModalButton from '@/components/buttons/ModalButton.vue'

export default {
  components: {
    OneColumnLayout,
    Loading,
    Heading,
    FloatingCard,
    RichTextEditor,
    PrimaryButton,
    VoteButtons,
    Modal,
    ModalButton,
  },
  data () {
    return {
      isReplying:   false,
      isSubscribed: false,
      isVoting:     '',
      isDeleting:   '',
      bonusId:      '',
      title:        '',
      replyIds:     [],
      posts:        {},
      users:        {},
      answer:       {
        content: '',
      },
      formDirty: false,
    }
  },
  computed: {
    ...mapState('user', ['username']),
    likeCount () {
      return postId => this.posts[postId] ?
        this.posts[postId].votes.filter(vote => vote.voteType === 'like').length :
        0
    },
    dislikeCount () {
      return postId => this.posts[postId] ?
        this.posts[postId].votes.filter(vote => vote.voteType === 'dislike').length :
        0
    },
    userVote () {
      return postId => {
        if(this.posts[postId]) {
          const vote = this.posts[postId].votes
            .filter(vote => !!vote.voter)
            .find(vote => vote.voter.username === this.username)
          return vote ? vote.voteType : ''
        }
        return ''
      }
    },
  },
  methods: {
    ...mapMutations('feature', ['setPageLoading', 'setNotification']),
    setBonusPost (post, error) {
      if (error) {
        this.setNotification({
          isOpen:  true,
          type:    'error',
          message: error,
        })
        return
      }

      // Set up this bonus' basic information
      this.bonusId = post.bonus.id
      this.title = post.bonus.title
      this.replyIds = post.bonus.replies.map(reply => reply.id)

      // Set page title
      this.setTitle(`${this.title} - Bonus`)

      // Set up posts (include bonus itself and its replies)
      const posts = {}
      posts[this.bonusId] = {
        author:    post.bonus.author.username,
        content:   post.bonus.content,
        votes:     post.bonus.votes,
        createdAt: new Date(post.bonus.createdAt),
      }
      post.bonus.replies.forEach(reply => {
        posts[reply.id] = {
          author:    reply.author.username,
          content:   reply.content,
          votes:     reply.votes,
          createdAt: new Date(reply.createdAt),
        }
      })
      this.posts = posts

      // Set up users information
      const users = {}
      post.users.forEach(user => {
        users[user.username] = user
      })
      this.users = users

      this.setPageLoading(false)
    },
    reply () {
      this.isReplying = true
      axios.post(`/bonuses/${this.bonusId}/replies`, this.answer)
        .then(() => axios.get(`/bonuses/${this.$route.params.id}`))
        .then(res => {
          const post = res.data
          this.setBonusPost(post, null)

          this.answer.content = ''
          this.formDirty = false

          this.setNotification({
            isOpen:  true,
            type:    'success',
            message: 'Your answer is posted!',
          })
        })
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
        })
        .finally(() => {
          this.isReplying = false
        })
    },
    votePost (postId, voteType) {
      this.isVoting = postId

      const route = (postId === this.bonusId) ?
        `/bonuses/${this.bonusId}/votes` :
        `/bonuses/${this.bonusId}/replies/${postId}/votes`

      axios.patch(route, {
        voteType: this.userVote(postId) === voteType ? '' : voteType,
      })
        .then(res => {
          this.posts[postId].votes = res.data.votes
        })
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
        })
        .finally(() => {
          this.isVoting = ''
        })
    },
    removePost () {
      axios.delete(`/bonuses/${this.bonusId}/replies/${this.isDeleting}`)
        .then(() => {
          const replyIndex = this.replyIds.findIndex(id => id === this.isDeleting)
          this.replyIds.splice(replyIndex, 1)
          delete this.posts[this.isDeleting]

          this.setNotification({
            isOpen:  true,
            type:    'success',
            message: 'Your post is deleted',
          })
        })
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
        })
        .finally(() => {
          this.isDeleting = ''
        })
    },
  },
  beforeRouteEnter (to, _, next) {
    store.commit('feature/setPageLoading', true)
    axios.get(`/bonuses/${to.params.id}`)
      .then(res => {
        const post = res.data
        to.meta.breadcrumbs = to.meta._breadcrumbs.concat([
          {
            text:     post.bonus.title,
            disabled: true,
          },
        ])
        next(vm => vm.setBonusPost(post, null))
      })
      .catch(error => {
        if(error.response.status === 404) {
          next('/notFound')
        }
        else {
          next(vm => vm.setBonusPost(null, error.response.data))
        }
      })
  },
  beforeRouteUpdate (to, _, next) {
    this.setPageLoading(true)
    axios.get(`/bonuses/${to.params.id}`)
      .then(res => {
        const post = res.data
        to.meta.breadcrumbs = to.meta._breadcrumbs.concat([
          {
            text:     post.bonus.title,
            disabled: true,
          },
        ])
        this.setBonusPost(post, null)
        next()
      })
      .catch(error => {
        if(error.response.status === 404) {
          next('/notFound')
        }
        else {
          this.setBonusPost(null, error.response.data)
          next()
        }
      })
  },
  beforeRouteLeave (to, from, next) {
    if(this.formDirty && !this.confirmRouteChange()) {
      next(false)
    }
    else {
      next()
    }
  },
}
</script>
