<template lang="pug">
two-columns-layout
  //- List heading (toolbar)
  heading
    search-bar(v-model="keyword")
    template(v-slot:action)
      primary-button(to="/discussion/ask") {{ $vuetify.breakpoint.smAndUp ? 'Ask Question' : 'Ask' }}
  //- Discussion post list
  card-list(:items="visibleDiscussions")
    template(v-slot:default="{item}")
      discussion-card(:discussion="item")
  //- Notice of discussion forum
  template(v-slot:aside)
    scroll-bar.pr-3(max-height="70vh")
      h2.mb-3 Notice
      div.rich-text(v-if="notice" v-html="notice")
      loading(v-else)
</template>

<script>
import axios from '@/plugins/axios.js'
import store from '@/store'
import { mapMutations } from 'vuex'
import TwoColumnsLayout from '@/layouts/TwoColumnsLayout.vue'
import Heading from '@/components/common/Heading.vue'
import SearchBar from '@/components/common/SearchBar.vue'
import ScrollBar from '@/components/common/ScrollBar.vue'
import PrimaryButton from '@/components/buttons/PrimaryButton.vue'
import CardList from '@/components/lists/CardList.vue'
import DiscussionCard from '@/components/cards/DiscussionCard.vue'
import Loading from '@/components/common/Loading.vue'

export default {
  components: {
    TwoColumnsLayout,
    Heading,
    SearchBar,
    ScrollBar,
    PrimaryButton,
    CardList,
    DiscussionCard,
    Loading,
  },
  data () {
    return {
      notice:      '',
      keyword:     '',
      discussions: [],
    }
  },
  computed: {
    visibleDiscussions () {
      let tmp = this.discussions.slice()
      if(this.keyword !== '') {
        tmp = tmp.filter(discussion => {
          return discussion.title.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1
        })
      }
      tmp = tmp.sort((a, b) => {
        if(a.pinned !== b.pinned) {
          return b.pinned - a.pinned
        }
        else {
          return b.createdAt - a.createdAt
        }
      })
      return tmp
    },
  },
  methods: {
    ...mapMutations('feature', ['setPageLoading', 'setNotification']),
    setDiscussions (discussions, error) {
      if (error) {
        this.setNotification({
          isOpen:  true,
          type:    'error',
          message: error,
        })
        return
      }

      this.discussions = discussions
      this.discussions.forEach(post => {
        post.createdAt = new Date(post.createdAt)
      })

      this.setPageLoading(false)
    },
  },
  mounted () {
    // Fetch notice of discussion forum
    axios.get('/static/discussion')
      .then(res => {
        this.notice = res.data
      })
      // If request failed, display error message
      .catch(error => {
        this.setNotification({
          isOpen:  true,
          type:    'error',
          message: error.response.data,
        })
      })
  },
  beforeRouteEnter (to, from, next) {
    store.commit('feature/setPageLoading', true)
    axios.get('/discussions')
      .then(res => {
        next(vm => vm.setDiscussions(res.data.discussions, null))
      })
      .catch(error => {
        next(vm => vm.setDiscussions(null, error.response.data))
      })
  },
}
</script>
