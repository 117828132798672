<template lang="pug">
v-file-input(
  multiple
  outlined
  counter
  prepend-inner-icon="$file"
  :prepend-icon="''"
  :show-size="1000"
  :label="label"
  :value="value"
  @change="change")
</template>

<script>
export default {
  name:  'FileInput',
  props: {
    value: {
      type:     Array,
      required: false,
      default:  null,
    },
    label: {
      type:     String,
      required: false,
      default:  '',
    },
  },
  methods: {
    change (value) {
      this.$emit('input', value)
    },
  },
}
</script>
