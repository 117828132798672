<template lang="pug">
div
  two-columns-layout(style="min-height: auto")
    //- Announcement section
    section.mb-11
      heading Announcement
      floating-card
        div.rich-text(v-html="exam.announcement")
    //- Questions section
    section.mb-11
      heading Problems
      div(v-if="problems.length === 0") No question available
      //- Problem cards, load problem content on click
      exam-problem-card.mb-5(
        v-else
        v-for="(problem, idx) in problems"
        :key="`problem-card-${idx}`"
        :problem="problem"
        @click="selectProblem(problem.id)")
    //- Info card
    template(v-slot:aside)
      //- Title and count down
      div.mb-10
        h2 {{ exam.title }}
        h3.font-weight-medium Time remaining: {{ remainTimeString }}
      //- Progress
      progress-ring.mx-auto.mb-5(
        :progress="chapterProgress(exam.index)"
        :color="exam.position"
        :size="170")
      //- p.text-center.mb-5 (Grade: {{ score }} pts)
      //- If this exam is not closed, display finish button
      div.text-center(v-if="!examClosed")
        primary-button(@click="isSubmitDialogOpen = true") Finish
      //- Else, display deadline information
      div(v-else)
        p.font-size-20.mb-2 This exam is closed.
        p.ma-0 You finished this exam. You can still submit your code, but the score will not be updated.
  div.problem-section
    v-overlay(
      :value="isFetchingProblem || !currentProblem.id"
      absolute
      opacity="0.75")
      loading(v-if="isFetchingProblem" size="80")
      p.text-h3(v-else) No problem selected
    problem-layout#problem
      template(v-slot:info)
        problem-information(:problem="currentProblem")
      template(v-slot:editor)
        code-editor(
          :problemId="currentProblem.id"
          :defaultCode="currentProblem.templateCode")
          //- @judged="updateScore()")
  //- Token prompt dialog for exam problem
  modal(:isOpen="verification.isOpen")
    template(v-slot:title)
      span Require Verification
    template(v-slot:content)
      p Please enter your verification token to start exam.
      p You can acquire the token from TAs.
      p Note that this is a one-time token, please do not give it to anyone.
      v-text-field(
        color="#1F2327"
        v-model="verification.token"
        label="Token"
        outlined
        required
        :error-messages="verification.error"
        :error="verification.error !== ''")
    template(v-slot:actions)
      modal-button(@click="sendToken()") Verify
  //- Submit confirm dialog
  modal(:isOpen="isSubmitDialogOpen")
    template(v-slot:title)
      span Finish the exam
    template(v-slot:content)
      p After you finish this exam, the score will not be updated anymore.
      p Are you sure you want to finish?
    template(v-slot:actions)
      modal-button.mr-5(@click="submitExam()") Yes
      modal-button(hollow @click="isSubmitDialogOpen = false") No
  //- TODO: grade sheet
</template>

<script>
import axios from '@/plugins/axios.js'
import store from '@/store'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import TwoColumnsLayout from '@/layouts/TwoColumnsLayout.vue'
import Heading from '@/components/common/Heading.vue'
import ProgressRing from '@/components/common/ProgressRing.vue'
import PrimaryButton from '@/components/buttons/PrimaryButton.vue'
import FloatingCard from '@/components/cards/FloatingCard.vue'
import ExamProblemCard from '@/components/cards/ExamProblemCard.vue'
import Loading from '@/components/common/Loading.vue'
import ProblemLayout from '@/layouts/ProblemLayout.vue'
import ProblemInformation from '@/components/problem/ProblemInformation.vue'
import CodeEditor from '@/components/problem/CodeEditor.vue'
import Modal from '@/components/common/Modal.vue'
import ModalButton from '@/components/buttons/ModalButton.vue'

import * as easings from 'vuetify/lib/services/goto/easing-patterns'

function toDate (string) {
  return string ? new Date(string) : null
}

export default {
  components: {
    TwoColumnsLayout,
    Heading,
    ProgressRing,
    PrimaryButton,
    FloatingCard,
    ExamProblemCard,
    Loading,
    ProblemLayout,
    ProblemInformation,
    CodeEditor,
    Modal,
    ModalButton,
  },
  data () {
    return {
      exam: {
        // Index of this chapter ('midterm' for example)
        index:               '',
        // Title of this chapter ('Midterm' for example)
        title:               '',
        // Serial number of this chapter
        position:            0,
        // Is this chapter hidden from non-admin user
        hidden:              false,
        // End time of this chapter
        dueTime:             new Date(),
        // Announcement of this exam
        announcement:        '',
        // Whether this exam requires user to enter validation code
        requireVerification: false,
      },
      // Current selected problem
      currentProblem: {
        id:           '',
        title:        'Loading...',
        description:  'Loading...',
        inputFormat:  'Loading...',
        outputFormat: 'Loading...',
        level:        'Easy',
        tags:         [],
        totalRequest: 0,
        acRequest:    0,
        timeLimit:    0,
        memLimit:     0,
        samples:      [],
        loaderCode:   '',
        templateCode: '',
        hint:         '',
      },
      // Verification card related information
      verification: {
        isOpen:         false,
        isUserVerified: false,
        token:          '',
        error:          '',
      },
      // Whether the problem fetching process is on going
      isFetchingProblem:  false,
      // Whether the submit confirmation dialog visible
      isSubmitDialogOpen: false,
      // Whether user has finished this exam
      isFinished:         false,
      // Remain exam time (in second)
      remainTime:         0,
      // User's current score
      // score:              0,
      // Transition easing pattern
      easings:            Object.keys(easings),
      // WORKAROUND: exam problem list
      problemList:        {},
    }
  },
  computed: {
    ...mapGetters('chapter', ['deadlineStatus']),
    ...mapGetters('user', ['isAdmin', 'chapterProgress']),
    ...mapGetters('problem', ['chapterProblems', 'userProblems']),
    problems () {
      return this.chapterProblems(this.exam.index)
    },
    userProgress () {
      return this.userProblems.filter(problem => problem.id in this.problemList)
    },
    // Determine whether this exam is closed.
    examClosed () {
      // Either the due time is expired, or the user has finished the exam,
      // this exam will be treated as closed.
      return this.deadlineStatus(this.exam) === 'Closed' || this.isFinished
    },
    // Determine whether the current user needs to be verified.
    userNeedsToBeVerified () {
      return !this.examClosed
        && !this.isAdmin
        && this.exam.requireVerification
        && !this.verification.isUserVerified
    },
    // Remain exam time string in "hh : mm : ss" format
    remainTimeString () {
      const hour = Math.floor(this.remainTime / 3600).toString()
      const min = Math.floor((this.remainTime % 3600) / 60).toString()
      const sec = Math.floor(this.remainTime % 60).toString()
      return `${hour.padStart(2, '0')} : ${min.padStart(2, '0')} : ${sec.padStart(2, '0')}`
    },
  },
  methods: {
    ...mapMutations('feature', ['setPageLoading', 'setNotification']),
    ...mapActions('problem', ['fetchProblems']),
    // TODO: move this function into chapter store
    fetchExam () {
      axios.get(`/exams/${this.exam.index}`)
        .then(res => {
          function toDate (string) {
            return string ? new Date(string) : null
          }

          this.exam = {
            ...this.exam,
            ...res.data,
          }
          this.exam.startTime = toDate(this.exam.startTime)
          this.exam.dueTime = toDate(this.exam.dueTime)
        })
        .catch(error=> {
          if(error.response.status !== 404) {
            this.setNotification({
              isOpen:  true,
              type:    'error',
              message: error.response.data,
            })
          }
        })
    },
    setExam (exam, error) {
      if (error) {
        this.setNotification({
          isOpen:  true,
          type:    'error',
          message: error,
        })
        return
      }

      // Set exam information
      this.exam = {
        ...this.exam,
        ...exam,
      }
      this.exam.startTime = toDate(this.exam.startTime)
      this.exam.dueTime = toDate(this.exam.dueTime)

      // Set page title
      this.setTitle(`${this.exam.index} - ${this.exam.title}`)

      // Set user state
      this.setUserState()

      // this.updateScore()

      this.setPageLoading(false)
    },
    setUserState () {
      // Get user's chapter state
      axios.get('/user/chapterState')
        .then(res => {
          const userChapterState = res.data

          // Check whether user has been verified
          this.verification.isUserVerified = userChapterState.isVerified

          // Check whether user is locked for submitting to this chapter.
          if(userChapterState.lockChapters.some(chapter => chapter.index === this.exam.index)) {
            this.isFinished = true
          }

          // Determine whether we should ask user to prompt verification token or just start counting down.
          if(this.userNeedsToBeVerified) {
            this.verification.isOpen = true
          }
          else {
            this.countDown()
          }
        })
        .catch(error=> {
          if(error.response.status !== 404) {
            this.setNotification({
              isOpen:  true,
              type:    'error',
              message: error.response.data,
            })
          }
        })
    },
    sendToken () {
      // Send token to verify
      axios.post('/user/verify/token', {
        examToken: this.verification.token,
      })
        // If success, get problems again.
        .then(() => this.fetchProblems())
        // And then set user state again,
        // this time it should not ask for token anymore
        .then(() => {
          this.verification.isOpen = false
          this.setUserState()
        })
        // If failed, display error message
        .catch(error => {
          if(error.response.status === 403) {
            this.verification.error = error.response.data
          }
        })
    },
    countDown () {
      // Start counting down only if the exam is not closed
      if(this.examClosed) {
        return
      }

      // Get current time
      const now = new Date()

      // If current time has not exceeded due time, keep counting down
      if(now < this.exam.dueTime) {
        this.remainTime = Math.floor((this.exam.dueTime - now) / 1000)

        // Sync exam information every 60 seconds.
        if(!(this.remainTime % 60)) {
          this.fetchExam()
        }

        // Count down every second
        setTimeout(this.countDown, 1000)
      }
      // Else, finish exam
      else {
        this.finishExam()
      }
    },
    // updateScore () {
    //   this.score = this.userProgress
    //     .map(x => Math.round((x.score / 100) * this.problemList[x.id]))
    //     .reduce((sum, x) => sum + x, 0)
    // },
    selectProblem (problemId) {
      this.isFetchingProblem = true
      axios.get(`/problems/${problemId}`)
        .then(res => {
          this.currentProblem = res.data
          this.$vuetify.goTo('#problem', {
            duration: 1000,
            offset:   0,
            easing:   'easeOutCubic',
          })
        })
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
        })
        .finally(() => {
          this.isFetchingProblem = false
        })
    },
    submitExam () {
      // Submit exam (stop answering the exam)
      axios.post(`/exams/${this.exam.index}/submit`)
        // If succeeded, close submit dialog and finish this exam.
        .then(() => {
          this.isSubmitDialogOpen = false
          this.finishExam()
        })
        // If there is any error, show notification
        .catch(error => {
          if(error.response.status !== 404) {
            this.setNotification({
              isOpen:  true,
              type:    'error',
              message: error.response.data,
            })
          }
        })
    },
    finishExam () {
      this.remainTime = 0
      this.isFinished = true
    },
  },
  beforeRouteEnter (to, _, next) {
    store.commit('feature/setPageLoading', true)
    axios.get(`/exams/${to.params.index}`)
      .then(res => {
        const exam = res.data
        to.meta.breadcrumbs = to.meta._breadcrumbs.concat([
          {
            text:     `${res.data.index} - ${res.data.title}`,
            disabled: true,
          },
        ])
        next(vm => vm.setExam(exam, null))
      })
      .catch(error=> {
        if(error.response.status === 404) {
          next('/notFound')
        }
        else {
          next(vm => vm.setExam(null, error.response.data))
        }
      })
  },
  beforeRouteUpdate (to, _, next) {
    this.setPageLoading(true)
    axios.get(`/exams/${to.params.index}`)
      .then(res => {
        const exam = res.data
        to.meta.breadcrumbs = to.meta._breadcrumbs.concat([
          {
            text:     `${res.data.index} - ${res.data.title}`,
            disabled: true,
          },
        ])
        this.setExam(exam, null)
        next()
      })
      .catch(error=> {
        if(error.response.status === 404) {
          next('/notFound')
        }
        else {
          this.setExam(null, error.response.data)
          next()
        }
      })
  },
}
</script>

<style lang="scss">
.problem-section {
  position: relative;
}

.font-size-20 {
  font-size: 20px !important;
}
</style>
