<template lang="pug">
div
  div.lining
  v-app-bar.ma-md-4.px-md-7.px-3(
    v-show="hasNavBar"
    app
    color="#30333F"
    elevation="24"
    :height="navBarHeight"
    v-bind:style="$vuetify.breakpoint.mdAndUp && {'border-radius': '20px 20px 5px 5px !important'}")
    v-row.flex-nowrap(align="center")
      router-link(to="/")
        v-img(src="@/assets/logo-text.png" width="203" height="49")
      v-spacer
      template(v-if="isSignin")
        div.navbar-links(v-if="$vuetify.breakpoint.mdAndUp")
          router-link.mr-8(
            to="/chapters"
            :style="{ 'color': $route.name === 'chapters' ? '#FFD06F' : '#F9F9F9' }") Chapters
          router-link.mr-8(
            to="/problems"
            :style="{ 'color': $route.name === 'problems' ? '#FFD06F' : '#F9F9F9' }") Problems
          router-link.mr-8(
            to="/discussion"
            :style="{ 'color': $route.name === 'discussion' ? '#FFD06F' : '#F9F9F9' }") Discussion
          router-link.mr-8(
            to="/bonus"
            :style="{ 'color': $route.name === 'bonus' ? '#FFD06F' : '#F9F9F9' }") Bonus
          router-link.mr-8(
            to="/faq"
            :style="{ 'color': $route.name === 'faq' ? '#FFD06F' : '#F9F9F9' }") FAQ
          notifications
          user
        template(v-else)
          notifications
          v-btn(
            v-blur
            fab
            depressed
            color="#1F2327"
            @click.stop="drawer = !drawer")
            v-icon(size="28") $menu
      primary-button(v-else to="/signin") Sign in / Sign up
  user-mobile(:value="drawer" @input="updateDrawerState")
</template>

<script>
import { mapGetters } from 'vuex'
import PrimaryButton from '@/components/buttons/PrimaryButton.vue'
import Notifications from '@/components/common/Notifications.vue'
import UserMobile from '@/components/common/UserMobile.vue'
import User from '@/components/common/User.vue'

export default {
  name:       'NavigationBar',
  data: () => ({
    drawer: false,
  }),
  components: {
    PrimaryButton,
    Notifications,
    UserMobile,
    User,
  },
  computed: {
    ...mapGetters('user', ['isSignin']),
    hasNavBar () {
      return !this.$route.meta.unauthorizedView && this.$route.name !== 'dashboard'
    },
    navBarHeight () {
      return this.$vuetify.breakpoint.mdAndUp ? 84 : 100
    },
  },
  methods: {
    updateDrawerState (newState) {
      this.drawer = newState
    },
  },
}
</script>

<style lang="scss">
$nav-bar-height: 126px;

.v-app-bar.v-app-bar--fixed {
  z-index: 10 !important;
}

.lining {
  position: fixed;
  top: 0;
  width: 100%;
  height: $nav-bar-height;
  background-color: #1F2327;
  z-index: 6;
}

.navbar-links {
  display: flex;
  align-items: center;
  a {
    text-decoration: none;
    font-size: 20px;
  }
}

</style>
