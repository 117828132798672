<template lang="pug">
floating-card.exercise-card(:to="`/homework/${exercise.chapter.index}/exercises/${exercise.id}`")
  //- Since `exercise.hidden` is not available for normal user,
  //- which means it will be `undefined`, so the `!!` is required
  //- to get the boolean value of this property properly.
  v-overlay(
    absolute
    opacity="0.75"
    :value="!!exercise.hidden")
    v-icon $eye-close
  v-container.pa-0(fluid)
    v-row(align="center")
      v-col(cols="12")
        //- Title of the exercise
        p.ma-0.text-h6.text-truncate {{ exercise.title }}
</template>

<script>
import FloatingCard from '@/components/cards/FloatingCard.vue'

export default {
  name:  'ExerciseCard',
  props: {
    exercise: {
      type:     Object,
      required: true,
      default:  () => {},
    },
  },
  components: {
    FloatingCard,
  },
}
</script>

<style lang="scss" scoped>
.exercise-card {
  // Add this attribute to make v-overlay's `absolute` prop work
  position: relative;
}
</style>
