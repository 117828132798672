import { render, staticRenderFns } from "./Triangle.vue?vue&type=template&id=02d8c52e"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports