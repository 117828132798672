<template lang="pug">
v-menu
  template(v-slot:activator="{ on, attrs, value }")
    v-btn.ml-8(
      v-bind="attrs"
      v-on="on"
      v-blur
      fab
      depressed
      color="#1F2327")
      v-avatar(size="56")
        img(v-if="avatar" :src="avatar" :alt="username")
        v-icon(v-else size="32") $profile
  floating-card.user-panel
    div.mb-6.flex-center
      //- Avatar
      v-avatar.mr-5.ml-2(size="56")
        //- Display avatar if it is existing
        img(v-if="avatar" :src="avatar" :alt="username")
        //- Else, display account icon
        v-icon(v-else) $profile
      div
        //- Username
        p.username {{ username }}
        p.student-id(v-show="studentId") {{ studentId }}
    v-divider
    v-list.mt-3.clickable-list(color="transparent" flat)
      //- Link to profile page
      v-list-item(:to="`/profile/${username}`")
        span.list-item-text Profile
      //- Link to setting page
      v-list-item(to="/setting")
        span.list-item-text Setting
      //- Link to manage page, only available for admin user
      v-list-item(v-if="permission === 0" to="/manage")
        span.list-item-text Manage
      //- Button to sign out
      v-list-item(@click="signOutOnClick")
        span.list-item-text Sign out
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import FloatingCard from '@/components/cards/FloatingCard.vue'

export default {
  name:       'User',
  components: {
    FloatingCard,
  },
  computed: {
    ...mapState('user', ['username', 'studentId', 'avatar', 'permission']),
  },
  methods: {
    ...mapActions('user', ['signOut']),
    ...mapMutations('feature', ['setNotification']),
    signOutOnClick () {
      this.signOut()
        .then(() => {
          this.setNotification({
            isOpen:  true,
            type:    'success',
            message: 'You are signed out',
          })
          this.$router.push('/about').catch(() => {})
        })
        .catch(err => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: err.response.data,
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
// Override v-menu's position to fit visual design
.v-menu__content {
  right: 56px !important;
  top: 126px !important;
  left: auto !important;
}

.user-panel {
  width: 409px;
  padding: 28px 20px 16px;

  .username {
    font-size: 20px;
    margin: 0px;
  }

  .student-id {
    color: #B5B5B5;
    margin: 0px;
  }
}
</style>
