<template lang="pug">
two-columns-layout
  heading Announcements
  card-list(:items="announcements")
    template(v-slot:default="{item}")
      announcement-card(:announcement="item")
  template(v-slot:aside)
    //- User information
    div.mb-7.d-flex.align-center
      //- Avatar
      v-avatar(size="64")
        img(v-if="avatar" :src="avatar" :alt="username")
        v-icon(v-else size="64") $profile
      div.ml-5.d-flex.flex-column.justify-center
        //- Username
        span.text-h6 {{ username }}
        //- Current grade
        //- span Grade: {{ score }} / 100
        //- TODO: replace this with score
        span {{ studentId }}
    v-divider
    //- Homework progress review
    div.mt-5(v-if="homeworkCount === 0")
      p.text-center No homework available.
    template(v-else)
      v-container.mt-3.clickable-list
        v-row(
          align="center"
          v-for="homework in visibleHomework"
          :key="`homework-progress-${homework.index}`")
          v-col(cols="4")
            router-link.text-truncate.text-decoration-none(:to="`/homework/${homework.index}`") {{ homework.index }}
          v-col(cols="8")
            router-link(:to="`/homework/${homework.index}`")
              v-progress-linear.progress-outlined(
                light
                rounded
                height="20"
                :value="Math.round(chapterProgress(homework.index) * 100)")
                template(v-slot:default="{ value }")
                  v-spacer
                  span.mr-2 {{ Math.ceil(value) }}%
      div.text-right
        router-link(to="/chapters") More...
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import TwoColumnsLayout from '@/layouts/TwoColumnsLayout.vue'
import Heading from '@/components/common/Heading.vue'
import CardList from '@/components/lists/CardList.vue'
import AnnouncementCard from '@/components/cards/AnnouncementCard.vue'
import Loading from '@/components/common/Loading.vue'

export default {
  components: {
    TwoColumnsLayout,
    Heading,
    CardList,
    AnnouncementCard,
    Loading,
  },
  computed: {
    ...mapState('announcement', ['announcements']),
    ...mapState('chapter', ['homeworkList']),
    ...mapState('user', ['username', 'studentId', 'avatar']),
    ...mapGetters('user', ['score', 'chapterProgress']),
    maxVisibleHomeworkCount () {
      return this.$vuetify.breakpoint.mdAndUp ? 6 : 3
    },
    visibleHomework () {
      const homeworkSortedByStartTime = Object.values(this.homeworkList).sort((a, b) => b.startTime - a.startTime)
      return homeworkSortedByStartTime.slice(0, this.maxVisibleHomeworkCount)
    },
    homeworkCount () {
      return Object.keys(this.homeworkList).length
    },
  },
  methods: {
    ...mapMutations('feature', ['setNotification']),
  },
}
</script>

<style lang="scss" scoped>
.progress-outlined {
  border: transparent 2px solid;
  outline: #F9F9F9 2px solid;
}
</style>
