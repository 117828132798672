<template lang="pug">
div.loading
  img(
    :width="Number(size)"
    :height="Number(size)"
    src="@/assets/loading.png")
</template>

<script>
export default {
  name:  'Loading',
  props: {
    size: {
      type:     [Number, String],
      required: false,
      default:  64,
    },
  },
}
</script>

<style lang="scss">
@keyframes loading {
  0%   {transform: rotate(0deg);}
  50%  {transform: rotate(180deg);}
  100% {transform: rotate(360deg);}
}

.loading {
  display: flex;
  justify-content: space-around;

  img {
    animation-name: loading;
    animation-duration: 2s;
    animation-delay: .5s;
    animation-iteration-count: infinite;
  }
}
</style>
