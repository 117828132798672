<template lang="pug">
  v-container
    v-alert(v-if="alert.display" type="error") {{ alert.message }}
    v-row(justify="start")
      v-col(v-if="games.length === 0" cols="12")
        v-row(justify="center" align="center" style="height: calc(100vh - 96px)")
          h1(class="grey--text") No Game Is Available Now.
      v-col(v-for="(game, index) of games" :key="`game-item-${game.name}-${index}`" cols="12" xl="3" lg="4" md="6" sm="6")
        v-hover(v-slot:default="{ hover }")
          v-card(class="mx-auto" max-width="344" :elevation="hover ? 12 : 2")
            v-list-item(three-line)
              v-list-item-content
                div(class="overline mb-4") Game # {{ index+1 }}
                v-list-item-title(class="headline mb-1") {{ game.name }}
                v-list-item-subtitle {{game.description}}

              v-img(alt="headshot"
                    v-bind:src="require('../' + index + '.png')"
                    aspect-ratio="1.7"
                    contain)
              //- v-list-item-avatar(tile size="80" color="grey")
            v-card-actions
              v-spacer
              router-link(tag="span" :to="`/game/${game._id}`")
                v-btn(text color="primary") Detail

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'GameList',
  data () {
    return {
      alert: {
        display: false,
        message: '',
      },
    }
  },
  computed: {
    ...mapGetters('game', { games: 'getGames' }),
  },
  async beforeMount () {
    try {
      await this.fetchGames()
    }
    catch (error) {
      this.alert.display = true
      this.alert.message = error.message
    }
  },
  methods: { ...mapActions('game', ['fetchGames']) },
}
</script>
