import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import Ripple from 'vuetify/lib/directives/ripple'
import AlarmIcon from '@/components/icons/Alarm.vue'
import BellIcon from '@/components/icons/Bell.vue'
import BellOffIcon from '@/components/icons/BellOff.vue'
import BellOutlinedIcon from '@/components/icons/BellOutlined.vue'
import BiographyIcon from '@/components/icons/Biography.vue'
import ChatIcon from '@/components/icons/Chat.vue'
import CodeIcon from '@/components/icons/Code.vue'
import CopyIcon from '@/components/icons/Copy.vue'
import DislikeIcon from '@/components/icons/Dislike.vue'
import DownloadIcon from '@/components/icons/Download.vue'
import EmailIcon from '@/components/icons/Email.vue'
import EyeCloseIcon from '@/components/icons/EyeClose.vue'
import EyeOpenIcon from '@/components/icons/EyeOpen.vue'
import FileIcon from '@/components/icons/File.vue'
import HomeIcon from '@/components/icons/Home.vue'
import LikeIcon from '@/components/icons/Like.vue'
import LinkIcon from '@/components/icons/Link.vue'
import LockIcon from '@/components/icons/Lock.vue'
import MenuIcon from '@/components/icons/Menu.vue'
import ProfileIcon from '@/components/icons/Profile.vue'
import ReplyIcon from '@/components/icons/Reply.vue'
import SearchIcon from '@/components/icons/Search.vue'
import SortIcon from '@/components/icons/Sort.vue'
import SpeakerIcon from '@/components/icons/Speaker.vue'
import StudentIcon from '@/components/icons/Student.vue'
import TrashcanIcon from '@/components/icons/Trashcan.vue'
import TriangleIcon from '@/components/icons/Triangle.vue'
import UnlockIcon from '@/components/icons/Unlock.vue'
import UploadIcon from '@/components/icons/Upload.vue'

Vue.use(Vuetify, { directives: { Ripple } })

export default new Vuetify({
  theme: {
    dark:   true,
    themes: {
      dark: {
        primary:   '#F9F9F9',
        secondary: '#FFD06F',
        accent:    '#FFD06F',
      },
      light: {
        primary:   '#1F2327',
        secondary: '#1F2327',
        accent:    '#1F2327',
      },
    },
  },
  icons: {
    values: {
      'alarm': {
        component: AlarmIcon,
      },
      'bell': {
        component: BellIcon,
      },
      'bell-off': {
        component: BellOffIcon,
      },
      'bell-outlined': {
        component: BellOutlinedIcon,
      },
      'biography': {
        component: BiographyIcon,
      },
      'chat': {
        component: ChatIcon,
      },
      'code': {
        component: CodeIcon,
      },
      'copy': {
        component: CopyIcon,
      },
      'dislike': {
        component: DislikeIcon,
      },
      'download': {
        component: DownloadIcon,
      },
      'email': {
        component: EmailIcon,
      },
      'eye-close': {
        component: EyeCloseIcon,
      },
      'eye-open': {
        component: EyeOpenIcon,
      },
      'file': {
        component: FileIcon,
      },
      'home': {
        component: HomeIcon,
      },
      'like': {
        component: LikeIcon,
      },
      'link': {
        component: LinkIcon,
      },
      'lock': {
        component: LockIcon,
      },
      'menu': {
        component: MenuIcon,
      },
      'profile': {
        component: ProfileIcon,
      },
      'reply': {
        component: ReplyIcon,
      },
      'search': {
        component: SearchIcon,
      },
      'sort': {
        component: SortIcon,
      },
      'speaker': {
        component: SpeakerIcon,
      },
      'student': {
        component: StudentIcon,
      },
      'trashcan': {
        component: TrashcanIcon,
      },
      'triangle': {
        component: TriangleIcon,
      },
      'unlock': {
        component: UnlockIcon,
      },
      'upload': {
        component: UploadIcon,
      },
    },
  },
})
