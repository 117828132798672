<template lang="pug">
one-column-layout
  heading Ask Question
  floating-card.mb-9
    h2.mt-0 Notice
    div.rich-text(v-if="notice" v-html="notice")
    loading(v-else)
  v-form.mb-8(ref="form")
    v-container(fluid)
      v-row(dense)
        v-col(cols="12" md="9")
          text-field(
            v-model="question.title"
            label="Title"
            required
            :max-length="50")
        v-col(cols="auto" sm="6" md="3")
          dropdown-list(
            v-model="question.chapter"
            :items="chapters"
            label="Chapter")
        v-col(cols="auto" sm="6" md="auto")
          dropdown-list(
            v-model="question.errorType"
            :items="Object.keys(submissionStatusOption).slice(1)"
            label="Error Type")
      v-row(dense)
        v-col
          rich-text-editor(
            v-model="question.content"
            label="Description"
            required
            @change="formDirty = true")
      v-row
        v-col(cols="auto")
          primary-button(
            :isWaiting="isPosting"
            @click="askQuestion()") Ask question
        v-col(cols="auto")
          primary-button(hollow @click="goToDiscussions()") Cancel
        v-spacer
</template>

<script>
import axios from '@/plugins/axios.js'
import store from '@/store'
import { mapGetters, mapMutations, mapState } from 'vuex'
import OneColumnLayout from '@/layouts/OneColumnLayout.vue'
import Heading from '@/components/common/Heading.vue'
import TextField from '@/components/form/TextField.vue'
import RichTextEditor from '@/components/form/RichTextEditor.vue'
import DropdownList from '@/components/form/DropdownList.vue'
import PrimaryButton from '@/components/buttons/PrimaryButton.vue'
import Loading from '@/components/common/Loading.vue'
import FloatingCard from '@/components/cards/FloatingCard.vue'

export default {
  components: {
    OneColumnLayout,
    Heading,
    TextField,
    RichTextEditor,
    DropdownList,
    PrimaryButton,
    Loading,
    FloatingCard,
  },
  data () {
    return {
      isPosting:    false,
      notice:       '',
      action:       'update',
      question:     {
        discussionId: '',
        title:        '',
        chapter:      '',
        content:      '',
        errorType:    '',
        // TODO: remove errorMessage field
        errorMessage: '',
      },
      formDirty: false,
    }
  },
  computed: {
    ...mapGetters('chapter', ['chapters']),
    ...mapState('problem', ['submissionStatusOption']),
  },
  methods: {
    ...mapMutations('feature', ['setPageLoading', 'setNotification']),
    setQuestion (question, error) {
      if (error) {
        this.setNotification({
          isOpen:  true,
          type:    'error',
          message: error,
        })
        return
      }

      if (question) {
        this.question = question
        if(this.question.chapter) {
          this.question.chapter = this.question.chapter.index
        }
      }
      else {
        this.question = {
          discussionId: '',
          title:        '',
          chapter:      '',
          content:      `<p><strong>我遇到的問題：</strong></p><p>請在本區段詳細描述你遇到什麼問題，你做了什麼嘗試，有什麼部份你不理解的。請不要只寫「為什麼這樣不會動？」、「為什麼這樣沒有滿分？」，請讓其他人知道你已經做了什麼樣的努力（閱讀完畢後可以刪除這段文字）</p><p></p><p><strong>造成問題的程式碼區段：</strong></p><pre><code>// 請將造成問題的程式碼區段貼在本區塊。
  // 請不要貼入完整的程式碼，以免抄襲爭議。
  // 如果你需要更多的程式碼區段，請先貼上你的程式碼，接著選取你貼上的程式碼，然後點選上方工具列的 &lt;&gt; 按鈕，左側的 &lt;&gt; 可以標示單行程式碼，右側的 &lt;&gt; 可以標示程式碼區段。
  // 閱讀完畢後可以刪除這段文字。</code></pre>`,
          errorType:    '',
          // TODO: remove errorMessage field
          errorMessage: '',
        },
        this.action = 'create'
      }

      this.setPageLoading(false)
    },
    goToDiscussions (id) {
      this.$router.push(`/discussion${id ? ('/' + id) : ''}`).catch(()=>{})
    },
    askQuestion () {
      // Validate each field before submitting
      if(!this.$refs.form.validate()) {
        this.setNotification({
          isOpen:  true,
          type:    'warning',
          message: 'Please ensure you filled in every field correctly',
        })
        return
      }
      if(!this.question.content) {
        this.setNotification({
          isOpen:  true,
          type:    'warning',
          message: 'Description is required',
        })
        return
      }

      if(this.action === 'create') {
        this.createPost()
      }
      else if(this.action === 'update') {
        this.updatePost()
      }
    },
    createPost () {
      this.isPosting = true
      axios.post('/discussions', this.question)
        .then(() => {
          this.formDirty = false
          this.setNotification({
            isOpen:  true,
            type:    'success',
            message: 'Question created! Hope someone can help :/',
          })
          this.goToDiscussions()
        })
        .catch(err => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: err.response.data,
          })
        })
        .finally(() => {
          this.isPosting = false
        })
    },
    updatePost () {
      this.isPosting = true
      axios.patch(`/discussions/${this.question.discussionId}`, this.question)
        .then(() => {
          this.formDirty = false
          this.setNotification({
            isOpen:  true,
            type:    'success',
            message: 'Your post is updated.',
          })
          this.goToDiscussions(this.question.discussionId)
        })
        .catch(err => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: err.response.data,
          })
        })
        .finally(() => {
          this.isPosting = false
        })
    },
  },
  mounted () {
    axios.get('/static/discussion')
      .then(res => {
        this.notice = res.data
      })
      // If request failed, display error message
      .catch(error => {
        this.setNotification({
          isOpen:  true,
          type:    'error',
          message: error.response.data,
        })
      })
  },
  beforeRouteEnter (to, from, next) {
    store.commit('feature/setPageLoading', true)
    if(to.params.id) {
      axios.get(`/discussions/${to.params.id}`)
        .then(res => {
          next(vm => vm.setQuestion(res.data.discussion, null))
        })
        .catch(error => {
          next(vm => vm.setQuestion(null, error.response.data))
        })
    }
    // Else, it means user is creating a discussion post
    else {
      next(vm => vm.setQuestion(null, null))
    }
  },
  beforeRouteUpdate (to, from, next) {
    this.setPageLoading(true)
    if(to.params.id) {
      axios.get(`/discussions/${to.params.id}`)
        .then(res => {
          this.setQuestion(res.data.discussion, null)
          next()
        })
        .catch(error => {
          this.setQuestion(null, error.response.data)
          next()
        })
    }
    // Else, it means user is creating a discussion post
    else {
      this.setQuestion(null, null)
      next()
    }
  },
  beforeRouteLeave (to, from, next) {
    if(this.formDirty && !this.confirmRouteChange()) {
      next(false)
    }
    else {
      next()
    }
  },
}
</script>
