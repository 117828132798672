<template lang="pug">
div.bar.flex-center
  //- bind value to SearchBar's v-model
  input.flex-grow-1(type="text" :value="value" @input="$emit('input', $event.target.value)")
  v-icon(size="20") $search
</template>

<script>
export default {
  name:  'SearchBar',
  props: {
    value: {
      type:     String,
      required: false,
      default:  '',
    },
  },
}
</script>

<style lang="scss" scoped>
.bar {
  border: #F9F9F9 1px solid;
  padding: 3px 12px 3px 20px;
  border-radius: 18px;
  transition: box-shadow .2s ease-out;

  &:focus-within {
    box-shadow: 2px 2px 4px #F9F9F980, 2px 2px 4px #F9F9F980 inset;
  }

  input {
    border: none;
    outline: none;
    color: #F9F9F9;
    font-size: 20px;
    width: 100%;
  }
}
</style>
