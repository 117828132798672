<template lang="pug">
v-dialog(
  light
  :value="isOpen"
  persistent
  no-click-animation
  max-width="700")
  div.modal
    div.modal-title(:style="{'background-color': color}")
      slot(name="title")
    div.modal-body
      scroll-bar(max-height="70vh")
        div.modal-content
          slot(name="content")
      div.modal-actions
        slot(name="actions")
</template>

<script>
import ScrollBar from '@/components/common/ScrollBar.vue'

export default {
  name:  'Modal',
  props: {
    isOpen: {
      type:     Boolean,
      required: true,
      default:  false,
    },
    color: {
      type:     String,
      required: false,
      default:  '#30333F',
    },
  },
  components: {
    ScrollBar,
  },
}
</script>

<style lang="scss" scoped>
.modal {
  display: grid;
  grid-template-rows: 60px auto;
  grid-template-columns: auto;
  border-radius: 20px;

  .modal-title {
    display: grid;
    place-items: center;
    font-size: 20px;
    padding: 0 30px;
    border-radius: 20px 20px 0px 0px;
  }

  .modal-body {
    display: grid;
    padding: 30px 12px;
    border-radius: 0px 0px 20px 20px;
    background-color: #F9F9F9;

    .modal-content {
      display: grid;
      place-items: center;
      min-height: 10vh;
      color: #30333F;
      word-break: break-word;
    }

    .modal-actions {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
  }
}
</style>
