<template lang="pug">
floating-card(:to="`/bonus/${bonus.id}`")
  v-container.pa-0(fluid)
    v-row(align="center")
      v-col(cols="10")
        //- Bonus title
        p.mb-1.text-h6.text-truncate {{ bonus.title }}
        //- Announcer's name and announced date
        p.mb-0.text-truncate {{ bonus.author.username }} posted at {{ dateToDateString(bonus.createdAt) }}
      v-spacer
      v-col.flex-center.flex-column(cols="auto")
        //- Replies count
        v-icon.mb-1(color="#B5B5B5") $reply
        span {{ bonus.repliesCount }}
</template>

<script>
import FloatingCard from '@/components/cards/FloatingCard.vue'

export default {
  name:  'BonusCard',
  props: {
    bonus: {
      type:     Object,
      required: true,
      default:  () => {},
    },
  },
  components: {
    FloatingCard,
  },
}
</script>
