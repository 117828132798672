<template lang="pug">
div.scrollable(:style="dimension")
  slot
</template>

<script>
export default {
  name:  'ScrollBar',
  props: {
    height: {
      type:     [String, Number],
      required: false,
      default:  null,
    },
    width: {
      type:     [String, Number],
      required: false,
      default:  null,
    },
    maxHeight: {
      type:     [String, Number],
      required: false,
      default:  null,
    },
    maxWidth: {
      type:     [String, Number],
      required: false,
      default:  null,
    },
  },
  computed: {
    dimension () {
      return {
        'height':     this.parseValue(this.height),
        'width':      this.parseValue(this.width),
        'max-height': this.parseValue(this.maxHeight),
        'max-width':  this.parseValue(this.maxWidth),
      }
    },
  },
  methods: {
    parseValue (property) {
      if(typeof property === 'string') {
        return property
      }
      else if(typeof property === 'number') {
        return `${property}px`
      }
      else {
        return '100%'
      }
    },
  },
}
</script>
