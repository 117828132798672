import * as PIXI from 'pixi.js'
import Scene from '@/components/story/prototypes/Scene.js'
import TextButton from '@/components/story/components/TextButton.js'

class MenuScene extends Scene {
  load () {
    super.load()
    this.container.name = 'MenuScene'
    const text = new PIXI.Text('Menu page', new PIXI.TextStyle({
      fontSize: 24,
      fill:     '#e2e2b4',
    }))
    text.position.set((this.app.view.width - text.width) / 2, this.app.view.height / 2)
    this.container.addChild(text)

    const backButton = new TextButton({
      text:  'Close Menu',
      style: {
        fontSize: 16,
        fill:     '#e2e2b4',
      },
      handler: () => {
        window.dispatchEvent(new CustomEvent('remove-scene'))
      },
    })
    backButton.position.set((this.app.view.width - backButton.width) / 2, this.app.view.height / 2 + 48)
    this.container.addChild(backButton)

    this.app.stage.getChildByName('menuButton').visible = false
    this.app.stage.addChild(this.container)
    // TODO: load scene setting and prepare menu content
  }

  unload () {
    // Make menu button visible
    this.app.stage.getChildByName('menuButton').visible = true
    // Unload this scene
    super.unload()
  }

  // TODO: implement each page
  profile () {}
  backpack () {}
  mission () {}
  catalog () {}
  accomplishment () {}
}

export default MenuScene
