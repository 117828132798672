<template lang="pug">
v-container(fluid)
  v-row
    v-col
      div.rich-text(v-html="mcq.description")
  v-row(
    v-for="(option, index) in mcq.choices"
    :key="`MCQ-${mcq.id}-option-${option}`"
    align="center")
    v-col(:style="{backgroundColor: rowColor(index)}")
      v-checkbox.ma-0.pa-0(
        v-model="selected"
        :label="option"
        :value="index"
        :rules="[required]"
        :readonly="readonly"
        :color="checkboxColor(index)"
        :on-icon="checkboxIcon(index)"
        hide-details)
</template>

<script>
export default {
  name:  'MCQ',
  props: {
    value: {
      type:     Array,
      required: true,
      default:  () => [],
    },
    readonly: {
      type:     Boolean,
      required: false,
      default:  false,
    },
    mcq: {
      type:     Object,
      required: true,
      default:  () => {},
    },
  },
  data () {
    return {
      selected: [],
    }
  },
  computed: {
    required () {
      return this.selected.length > 0
    },
    rowColor () {
      return index => {
        if(!this.readonly) {
          return null
        }
        else {
          if (this.mcq.answer.indexOf(index) !== -1) {
            return '#52CC754D'
          }
          else if (this.value.indexOf(index) !== -1) {
            return '#FF6E6E4D'
          }
          else {
            return null
          }
        }
      }
    },
    checkboxColor () {
      return index => {
        if(!this.readonly) {
          return null
        }
        else {
          if (this.mcq.answer.indexOf(index) !== -1) {
            return '#52CC75'
          }
          else if (this.value.indexOf(index) !== -1) {
            return '#FF6E6E'
          }
          else {
            return null
          }
        }
      }
    },
    checkboxIcon () {
      return index => {
        if(!this.readonly) {
          return '$checkboxOn'
        }
        else {
          if (this.value.indexOf(index) !== -1 && this.mcq.answer.indexOf(index) === -1) {
            return 'mdi-close-box'
          }
          return '$checkboxOn'
        }
      }
    },
  },
  watch: {
    selected (value) {
      this.$emit('input', value)
    },
    value (value) {
      this.selected = value
    },
  },
  mounted () {
    if (this.readonly) {
      this.selected = this.value
    }
  },
}
</script>
