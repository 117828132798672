<template lang="pug">
floating-card
  v-container.pa-0(fluid)
    //- Basic information
    v-row(align="center")
      v-col.text-center.text-sm-start(cols="2" sm="auto")
        //- Icon indicating announcemnt type
        v-avatar(
          size="40"
          :color="types[announcement.type].color")
          v-icon.outlined(size="36") {{ types[announcement.type].icon }}
      v-col.flex-grow-1(cols="10" sm="auto")
        v-row(no-gutters)
          v-col(cols="12" md="auto")
            //- Announcement title
            p.mb-0.text-h6.text-truncate {{ announcement.title }}
          v-spacer
          v-col(cols="12" md="auto")
            //- Announced time
            span.text-subtitle-1.text-md-h6 {{ dateToDateString(announcement.timestamp) }}
    v-row
      v-col
        v-divider
    v-row
      v-col
        //- Announcement content
        div.rich-text(v-html="announcement.content")
</template>

<script>
import { mapState } from 'vuex'
import FloatingCard from '@/components/cards/FloatingCard.vue'

export default {
  name:  'AnnouncementCard',
  props: {
    announcement: {
      type:     Object,
      required: true,
      default:  () => { },
    },
  },
  components: {
    FloatingCard,
  },
  computed: {
    ...mapState('announcement', ['types']),
  },
}
</script>

<style scoped>
.outlined {
  padding: 4px;
  border: #30333F 2px solid;
}
</style>
