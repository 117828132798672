<template lang="pug">
v-card(flat color="transparent")
  v-form.d-flex.flex-column
    //- Username field
    div.mt-10.d-flex.align-start
      v-icon.mr-5(size="36" color="#B5B5B5") mdi-account-circle
      v-text-field.mt-0.pt-0(
        v-model="username"
        type="text"
        label="Username"
        required
        rounded
        light
        single-line
        height="36"
        background-color="#B5B5B5"
        :error-messages="usernameErrors")
    //- E-mail field
    div.mt-6.d-flex.align-start
      v-icon.mr-5(size="36" color="#B5B5B5") mdi-email
      v-text-field.mt-0.pt-0(
        v-model="email"
        type="email"
        label="E-mail"
        required
        rounded
        light
        single-line
        height="36"
        background-color="#B5B5B5"
        :error-messages="emailErrors")
    //- Password field
    div.mt-6.d-flex.align-start
      v-icon.mr-5(size="36" color="#B5B5B5") mdi-lock
      v-text-field.mt-0.pt-0(
        v-model="password"
        type="password"
        label="Password"
        required
        rounded
        light
        single-line
        height="36"
        background-color="#B5B5B5"
        :error-messages="passwordErrors")
    //- Comfirm password field
    div.mt-6.d-flex.align-start
      v-icon.mr-5(size="36" color="#B5B5B5") mdi-lock-check
      v-text-field.mt-0.pt-0(
        v-model="confirmPassword"
        type="password"
        label="Password Confirmation"
        required
        rounded
        light
        single-line
        height="36"
        background-color="#B5B5B5"
        :error-messages="confirmPasswordErrors")
    //- Student id field
    div.mt-6.d-flex.align-start
      v-icon.mr-5(size="36" color="#B5B5B5") mdi-card-account-details
      v-text-field.mt-0.pt-0(
        v-model="studentId"
        type="text"
        label="Student Id"
        rounded
        light
        single-line
        height="36"
        background-color="#B5B5B5"
        persistent-hint
        hint='You are required to provide your student ID only if you enrolled \"Program Design(1)\".'
        @keyup.enter.prevent="submitForm()")
    //- Sign up Button
    primary-button.mt-10.align-self-center(
      :isWaiting="isWaiting"
      @click="submitForm()") Sign up
</template>

<script>
import { mapActions } from 'vuex'
import { required, maxLength, email } from 'vuelidate/lib/validators'
import PrimaryButton from '@/components/buttons/PrimaryButton.vue'

export default {
  name:       'SignupForm',
  components: {
    PrimaryButton,
  },
  data () {
    return {
      isWaiting:       false,
      username:        '',
      email:           '',
      studentId:       '',
      password:        '',
      confirmPassword: '',
    }
  },
  validations: {
    username: {
      required,
      maxLength: maxLength(20),
    },
    email: {
      required,
      email,
    },
    password:        { required },
    confirmPassword: {
      required,
      // Ensure that password and confirmPassword are the same.
      confirmed (val) {
        return val === this.password
      },
    },
  },
  computed: {
    // User name field error message handling
    usernameErrors () {
      const errors = []
      if (!this.$v.username.$dirty) {
        return errors
      }
      !this.$v.username.maxLength && errors.push('User name must be at most 20 characters long.')
      !this.$v.username.required && errors.push('User name is required.')
      return errors
    },
    // E-mail field error message handling
    emailErrors () {
      const errors = []
      if (!this.$v.email.$dirty) {
        return errors
      }
      !this.$v.email.email && errors.push('E-mail pattern not matched.')
      !this.$v.email.required && errors.push('E-mail is required.')
      return errors
    },
    // Password field error message handling
    passwordErrors () {
      const errors = []
      if (!this.$v.password.$dirty) {
        return errors
      }
      !this.$v.password.required && errors.push('Password is required.')
      return errors
    },
    // Confirm password field error message handling
    confirmPasswordErrors () {
      const errors = []
      if (!this.$v.confirmPassword.$dirty) {
        return errors
      }
      !this.$v.confirmPassword.confirmed && errors.push('New password confirmation failed.')
      !this.$v.confirmPassword.required && errors.push('Please enter your new password again.')
      return errors
    },
  },
  methods: {
    ...mapActions('user', ['signUp']),
    async submitForm () {
      // Ensure there is no invalid field before submitting
      this.$v.$touch()
      if (!this.$v.$anyError) {
        // Disable sign up button
        this.isWaiting = true

        // Send sign up request
        await this.signUp({
          username:  this.username,
          password:  this.password,
          studentId: this.studentId,
          email:     this.email,
        })
          // If successed, raise success event with success message
          // Don't use message send from backend for future i18n feature
          .then(() => {
            this.$emit('success', 'Sign up success, please check your email to activate your account.')
          })
          // If there is any error, raise fail event
          .catch(error => {
            this.$emit('fail', error.response.data)
          })
          // Re-enable sign up button
          .finally(() => {
            this.isWaiting = false
          })
      }
    },
  },
}
</script>

<style lang="scss">
.v-messages__message {
  color: #B5B5B5;
  line-height: 16px;
}
</style>
