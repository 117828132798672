<template lang="pug">
two-columns-layout
  heading
    //- Search bar, filter discussion whose title includes the keyword
    search-bar(v-model="keyword")
    template(v-slot:action)
      //- Completion status filter, filter problems with specific completion
      //- including todo, attempted and solved
      list-filter(
        v-model="filter.value"
        :options="filter.options"
        @focus="filter.isOpen = true"
        @blur="filter.isOpen = false")
  //- Problems list
  //- TODO: pagination broken when length = 0
  div.problems
    v-overlay.ma-0(absolute :value="filter.isOpen")
    card-list(:items="visibleProblems")
      template(v-slot:default="{item}")
        problem-card(:problem="item" @search-tag="searchTag")
  //- Progress review
  template(v-slot:aside)
    //- Title
    h2.mb-10 Progress
    //- Progress ring
    progress-ring.mx-auto.mb-10(:progress="progress" :size="170")
    //- Count of problems in different status
    div.d-flex.justify-space-around
      //- Count of solved problems
      div.flex-center.flex-column
        span {{ solvedCount }} / {{ problemsCount }}
        span Solved
      //- Count of attempted problems
      div.flex-center.flex-column
        span {{ attemptedCount }} / {{ problemsCount }}
        span Attempted
      //- Count of to-do problems
      div.flex-center.flex-column
        span {{ todoCount }} / {{ problemsCount }}
        span To do
</template>

<script>
import { mapGetters } from 'vuex'
import TwoColumnsLayout from '@/layouts/TwoColumnsLayout.vue'
import Heading from '@/components/common/Heading.vue'
import SearchBar from '@/components/common/SearchBar.vue'
import ListFilter from '@/components/common/ListFilter.vue'
import CardList from '@/components/lists/CardList.vue'
import ProgressRing from '@/components/common/ProgressRing.vue'
import ProblemCard from '@/components/cards/ProblemCard.vue'

export default {
  components: {
    TwoColumnsLayout,
    Heading,
    SearchBar,
    ListFilter,
    CardList,
    ProblemCard,
    ProgressRing,
  },
  data () {
    return {
      keyword: '',
      filter:  {
        name:    'status',
        isOpen:  false,
        options: ['All', 'Todo', 'Attempted', 'Solved'],
        value:   '',
      },
    }
  },
  computed: {
    // All problems with user's score on them
    ...mapGetters('problem', {
      problems: 'userProblems',
    }),
    // Total number of problems
    problemsCount () {
      return this.problems.length
    },
    // Number of to-do problems
    todoCount () {
      return this.problems.filter(problem => problem.score === 0).length
    },
    // Number of attempted problems
    attemptedCount () {
      return this.problems.filter(problem => problem.score > 0 && problem.score < 100).length
    },
    // Number of solved problems
    solvedCount () {
      return this.problems.filter(problem => problem.score === 100).length
    },
    // Ratio of number of solved problems to total number of problems
    progress () {
      return (this.solvedCount / this.problemsCount) || 0
    },
    // Problems filtered by status and keyword
    visibleProblems () {
      let tmp = this.problems.slice()

      if (this.filter.value === 'Todo') {
        tmp = tmp.filter(problem => problem.score === 0)
      }
      else if (this.filter.value === 'Attempted') {
        tmp = tmp.filter(problem => problem.score > 0 && problem.score < 100)
      }
      else if (this.filter.value === 'Solved') {
        tmp = tmp.filter(problem => problem.score === 100)
      }

      if(this.keyword !== '') {
        if(this.keyword[0] === '#') {
          const filterTag = this.keyword.substr(1).toLowerCase()
          if(filterTag) {
            tmp = tmp.filter(problem => problem.tags.some(tag => tag.toLowerCase().indexOf(filterTag) > -1))
          }
        }
        else {
          tmp = tmp.filter(problem => {
            return problem.title.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1
          })
        }
      }

      return tmp
    },
  },
  methods: {
    searchTag (tag) {
      this.keyword = `#${tag}`
    },
  },
}
</script>

<style lang="scss" scoped>
.problems {
  // Add this attribute to make v-overlay's `absolute` prop work
  position: relative;
}
</style>
