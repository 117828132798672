<template lang="pug">
div
  heading Menu
  v-list.py-0.clickable-list(color="transparent" flat)
    v-list-item(to="/manage/chapter")
      span(:class="{ 'current': isCurrentRoute('manage-chapter') }") Chapter
    v-list-item(to="/manage/problems")
      span(:class="{ 'current': isCurrentRoute('manage-problems') }") Problem
    v-list-item(to="/manage/announcements")
      span(:class="{ 'current': isCurrentRoute('manage-announcements') }") Announcement
    v-list-item(to="/manage/discussion")
      span(:class="{ 'current': isCurrentRoute('manage-discussion') }") Discussion
    v-list-item(to="/manage/bonus")
      span(:class="{ 'current': isCurrentRoute('manage-bonus') }") Bonus
    v-list-item(to="/manage/faq")
      span(:class="{ 'current': isCurrentRoute('manage-faq') }") FAQ
    v-list-item(to="/manage/dashboard")
      span(:class="{ 'current': isCurrentRoute('manage-dashboard') }") Dashboard
</template>

<script>
import Heading from '@/components/common/Heading.vue'

export default {
  name:       'ManageMenu',
  components: {
    Heading,
  },
  methods: {
    isCurrentRoute (name) {
      return this.$route.name === name
    },
  },
}
</script>

<style lang="scss" scoped>
.v-list-item {
  font-size: 20px;
}

.current {
  color: #FFD06F;
}
</style>
