<template lang="pug">
router-link.floating-card.link-card(v-if="to" :to="to")
  slot
div.floating-card(v-else)
  slot
</template>

<script>
export default {
  name:  'FloatingCard',
  props: {
    to: {
      type:     String,
      required: false,
      default:  null,
    },
  },
}
</script>

<style lang="scss" scoped>
.floating-card {
  display: block;
  padding: 24px;
  background-color: #30333F;
  box-shadow: 10px 10px 10px #131416;
  border-radius: 10px;
}

.link-card {
  border: 2px solid #30333F;
  color: #F9F9F9;
  text-decoration: none;
  cursor: pointer;
  transition: all .2s;

  &:hover {
    border-color: #B5B5B5;
  }

  &:active {
    box-shadow: 10px 10px 6px #F9F9F933;
  }
}
</style>
