<template lang="pug">
v-navigation-drawer.top-nav.px-8(
  app
  v-model="enableNavDrawer"
  color="#30333F"
  right
  :disable-resize-watcher="$vuetify.breakpoint.mdAndUp")
  v-list.line-height
    v-list-item
      v-list-item-avatar
        img(v-if="avatar" :src="avatar" :alt="username")
        v-icon(v-else size="32") $profile
      v-list-item-content
        v-list-item-title {{ username }}
        v-list-item-subtitle {{ studentId }}
  v-divider
  v-list.text-center.clickable-list.line-height(flat)
    v-list-item(to="/chapters")
      v-list-item-title Chapters
    v-list-item(to="/problems")
      v-list-item-title Problems
    v-list-item(to="/discussion")
      v-list-item-title Discussion
    v-list-item(to="/bonus")
      v-list-item-title Bonus
    v-list-item(to="/faq")
      v-list-item-title FAQ
  v-divider
  v-list.text-center.clickable-list.line-height(flat)
    //- Link to profile page
    v-list-item(:to="`/profile/${username}`")
      v-list-item-title Profile
    //- Link to setting page
    v-list-item(to="/setting")
      v-list-item-title Setting
    //- Link to manage page, only available for admin user
    v-list-item(v-if="permission === 0" to="/manage")
      v-list-item-title Manage
    //- Button to sign out
    v-list-item(@click="signOutOnClick")
      v-list-item-title Sign out
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

export default {
  name:  'UserMobile',
  props: {
    value: Boolean,
  },
  computed: {
    ...mapState('user', ['username', 'studentId', 'avatar', 'permission']),
    ...mapGetters('user', ['score']),
    enableNavDrawer: {
      get () {
        return this.$vuetify.breakpoint.smAndDown && this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
        return newValue
      },
    },
  },
  methods: {
    ...mapActions('user', ['signOut']),
    ...mapMutations('feature', ['setNotification']),
    signOutOnClick () {
      this.signOut()
        .then(() => {
          this.setNotification({
            isOpen:  true,
            type:    'success',
            message: 'You are signed out',
          })
          this.$router.push('/about').catch(() => {})
        })
        .catch(err => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: err.response.data,
          })
        })
    },
  },
}
</script>

<style lang="scss">
.top-nav {
  z-index: 99;
}
.v-navigation-drawer__border {
  background-color: inherit !important;
}
.v-list-item .v-list-item__title, .v-list-item .v-list-item__subtitle .line-height {
  line-height: 1.5 !important;
}
</style>
