const state = {
  pageLoading:  true,
  notification: {
    isOpen:  false,
    timeout: 3000,
    message: '',
    // Available type = ['success', 'warning', 'error']
    type:    'success',
    color:   {
      success: '#52CC75',
      warning: '#E8A723',
      error:   '#FF6E6E',
    },
  },
  popup: {
    isOpen:  false,
    title:   '',
    content: '',
    // Available type = ['message', 'error']
    type:    'message',
    color:   {
      message: '#30333F',
      error:   '#FF6E6E',
    },
  },
}

const getters = {}

const mutations = {
  setPageLoading (state, isLoading) {
    state.pageLoading = isLoading
  },
  setNotification (state, option) {
    state.notification = {
      ...state.notification,
      ...option,
    }
  },
  setPopup (state, option) {
    state.popup = {
      ...state.popup,
      ...option,
    }
  },
}

const actions = {}

module.exports = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
