<template lang='pug'>
one-column-layout
  //- TODO: use heading to implement
  v-container.pa-0.mb-5(fluid v-if="errorType")
    v-row(dense align="center")
      v-col(cols="2" sm="auto")
        //- Error type
        v-chip(
          :color="submissionStatusOption[errorType].color") {{ $vuetify.breakpoint.mdAndUp ? errorType : submissionStatusOption[errorType].acronym }}
      v-col(cols="8" md="6")
        //- Title
        h2.text-h5.font-weight-bold.text-truncate {{ title }}
      v-spacer
      v-col.text-end(cols="2" sm="auto" order-lg="12")
        v-btn.ml-lg-8(icon @click="toggleNotification()")
          v-icon(v-if="isSubscribed") $bell
          v-icon(v-else color="#F9F9F9B3") $bell-off
      v-col(cols="12" lg="auto" order-lg="11")
        vote-buttons(
          :likeCount="likeCount(discussionId)"
          :dislikeCount="dislikeCount(discussionId)"
          :userVote="userVote(discussionId)"
          :isVoting="isVoting === discussionId"
          @vote="votePost(discussionId, $event)")
    v-row(dense)
      v-col
        v-divider
  floating-card.mb-10(v-if="posts[discussionId]")
    v-container.pa-0(fluid)
      v-row.flex-nowrap(align="center")
        v-col(cols="auto")
          router-link(:to="`/profile/${posts[discussionId].author}`")
            v-avatar(size="48")
              //- Display avatar if it is existing
              img(
                v-if="users[posts[discussionId].author].avatar"
                :src="users[posts[discussionId].author].avatar"
                :alt="posts[discussionId].author")
              //- Else, display account icon
              v-icon(v-else size="48" color="#B5B5B5") $profile
        v-col
          p.mb-1.text-h6.text-truncate
            router-link(:to="`/profile/${posts[discussionId].author}`") {{ posts[discussionId].author }}
          p.mb-0.text-truncate {{ dateToString(posts[discussionId].createdAt) }}
      v-row
        v-col
          v-divider
      v-row
        v-col
          div.rich-text(v-html="posts[discussionId].content")
      v-row(v-if="posts[discussionId].author === username")
        v-col(cols="auto")
          v-btn(text :to="`/discussion/${discussionId}/edit`")
            v-icon.mr-2 mdi-pencil
            span Edit
        v-col(cols="auto")
          v-btn(text color="#FF6E6E" @click="deleting.postId = discussionId")
            v-icon.mr-2 $trashcan
            span Delete
  div.mb-10
    heading Write an answer
    rich-text-editor(
      v-model="replying.content"
      @change="formDirty = true")
    primary-button(:isWaiting="replying.isLoading" @click="reply()") Post
  heading Answers
  v-container.pa-0(fluid)
    v-row(v-if="replyIds.length === 0")
      v-col
        p No answer yet :(
    v-row(
      v-else
      v-for="(replyId, index) in replyIds"
      :key="`reply-${replyId}`")
      v-col(cols="12")
        floating-card
          v-container.pa-0(fluid)
            v-row(align="center")
              v-col(cols="auto")
                span.text-h5 # {{ index + 1 }}
              v-col(cols="auto")
                router-link(:to="`/profile/${posts[replyId].author}`")
                  v-avatar(size="48")
                    //- Display avatar if it is existing
                    img(
                      v-if="users[posts[replyId].author].avatar"
                      :src="users[posts[replyId].author].avatar"
                      :alt="posts[replyId].author")
                    //- Else, display account icon
                    v-icon(v-else size="48" color="#B5B5B5") $profile
              v-col(cols="auto")
                p.mb-1.text-h6.text-truncate
                  router-link(:to="`/profile/${posts[replyId].author}`") {{ posts[replyId].author }}
                p.mb-0.text-truncate {{ dateToString(posts[replyId].createdAt) }}
              v-spacer
              v-col(cols="auto")
                vote-buttons(
                  :likeCount="likeCount(replyId)"
                  :dislikeCount="dislikeCount(replyId)"
                  :userVote="userVote(replyId)"
                  :isVoting="isVoting === replyId"
                  @vote="votePost(replyId, $event)")
            v-row
              v-col
                v-divider
            v-row(v-if="editing.postId === replyId")
              v-col(cols="12")
                rich-text-editor(
                  v-model="editing.content"
                  @change="formDirty = true")
              v-col(cols="auto")
                primary-button(:isWaiting="editing.isLoading" @click="updatePost()") Update
              v-col(cols="auto")
                primary-button(hollow :isWaiting="editing.isLoading" @click="editing.postId = ''") Cancel
            v-row(v-else)
              v-col
                div.rich-text(v-html="posts[replyId].content")
            v-row(v-if="posts[replyId].author === username && !editing.postId")
              v-col(cols="auto")
                v-btn(text @click="editPost(replyId)")
                  v-icon.mr-2(size="28") mdi-pencil
                  span Edit
              v-col(cols="auto")
                v-btn(text color="#FF6E6E" @click="deleting.postId = replyId")
                  v-icon.mr-2 $trashcan
                  span Delete
  //- Confirm delete modal
  modal(:isOpen="!!deleting.postId")
    template(v-slot:title)
      span Delete your post
    template(v-slot:content)
      p Do you really want to delete {{ deleting.postId === discussionId ? `"${title}"` : 'your reply' }}?
    template(v-slot:actions)
      modal-button.mr-5(
        :isLoading="deleting.isLoading"
        :disabled="deleting.isLoading"
        @click="removePost()") Delete
      modal-button(
        hollow
        :isLoading="deleting.isLoading"
        :disabled="deleting.isLoading"
        @click="deleting.postId = ''") Cancel
</template>

<script>
import axios from '@/plugins/axios.js'
import store from '@/store'
import { mapMutations, mapState } from 'vuex'
import OneColumnLayout from '@/layouts/OneColumnLayout.vue'
import Loading from '@/components/common/Loading.vue'
import Heading from '@/components/common/Heading.vue'
import VoteButtons from '../components/buttons/VoteButtons.vue'
import FloatingCard from '../components/cards/FloatingCard.vue'
import RichTextEditor from '@/components/form/RichTextEditor.vue'
import PrimaryButton from '@/components/buttons/PrimaryButton.vue'
import Modal from '@/components/common/Modal.vue'
import ModalButton from '@/components/buttons/ModalButton.vue'

export default {
  components: {
    OneColumnLayout,
    Heading,
    Loading,
    VoteButtons,
    FloatingCard,
    RichTextEditor,
    PrimaryButton,
    Modal,
    ModalButton,
  },
  data () {
    return {
      isSubscribed: true,
      isVoting:     '',
      discussionId: '',
      title:        '',
      chapter:      '',
      errorType:    '',
      errorMessage: '',
      replyIds:     [],
      posts:        {},
      users:        {},
      replying:       {
        content:   '',
        isLoading: false,
      },
      editing: {
        postId:    '',
        content:   '',
        isLoading: false,
      },
      deleting: {
        postId:    '',
        isLoading: false,
      },
      formDirty: false,
    }
  },
  computed: {
    ...mapState('user', ['username']),
    ...mapState('problem', ['submissionStatusOption']),
    likeCount () {
      return postId => this.posts[postId] ?
        this.posts[postId].votes.filter(vote => vote.voteType === 'like').length :
        0
    },
    dislikeCount () {
      return postId => this.posts[postId] ?
        this.posts[postId].votes.filter(vote => vote.voteType === 'dislike').length :
        0
    },
    userVote () {
      return postId => {
        if(this.posts[postId]) {
          const vote = this.posts[postId].votes
            .filter(vote => !!vote.voter)
            .find(vote => vote.voter.username === this.username)
          return vote ? vote.voteType : ''
        }
        return ''
      }
    },
  },
  methods: {
    ...mapMutations('feature', ['setPageLoading', 'setNotification']),
    setDiscussionPost (post, error) {
      if (error) {
        this.setNotification({
          isOpen:  true,
          type:    'error',
          message: error,
        })
        return
      }

      // Set up this discussion's basic information
      this.discussionId = post.discussion.id
      this.title = post.discussion.title
      this.chapter = post.discussion.chapter ? post.discussion.chapter.index : ''
      this.errorType = post.discussion.errorType
      this.errorMessage = post.discussion.errorMessage
      this.replyIds = post.discussion.replies.map(reply => reply.id)

      // Set page title
      this.setTitle(`${this.title} - Discussion`)

      // Set up posts (include discussion itself and its replies)
      const posts = {}
      posts[this.discussionId] = {
        author:    post.discussion.author.username,
        content:   post.discussion.content,
        votes:     post.discussion.votes,
        createdAt: new Date(post.discussion.createdAt),
      }
      post.discussion.replies.forEach(reply => {
        posts[reply.id] = {
          author:    reply.author.username,
          content:   reply.content,
          votes:     reply.votes,
          createdAt: new Date(reply.createdAt),
        }
      })
      this.posts = posts

      // Set up users information
      const users = {}
      post.users.forEach(user => {
        users[user.username] = user
      })
      this.users = users

      this.setPageLoading(false)

      axios.get(`/notification/issubscribed?topic=${post.discussion.id}`)
        .then(res => {
          this.isSubscribed = res.data
        })
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
        })
    },
    toggleNotification () {
      if(this.isSubscribed) {
        axios.post(`/notification/unsubscribe?topic=${this.discussionId}`)
          .then(() => {
            this.isSubscribed = false
            this.setNotification({
              isOpen:  true,
              type:    'success',
              message: 'Notification on this post is off',
            })
          })
          .catch(error => {
            this.setNotification({
              isOpen:  true,
              type:    'error',
              message: error.response.data,
            })
          })
      }
      else {
        axios.post(`/notification/subscribe?topic=${this.discussionId}`)
          .then(() => {
            this.setNotification({
              isOpen:  true,
              type:    'success',
              message: 'Notification on this post is on',
            })
            this.isSubscribed = true
          })
          .catch(error => {
            this.setNotification({
              isOpen:  true,
              type:    'error',
              message: error.response.data,
            })
          })
      }
    },
    reply () {
      this.replying.isLoading = true
      axios.post(`/discussions/${this.discussionId}/replies`, {
        content: this.replying.content,
      })
        .then(() => axios.get(`/discussions/${this.$route.params.id}`))
        .then(res => {
          const post = res.data
          this.setDiscussionPost(post, null)

          this.replying.content = ''
          this.formDirty = false

          this.setNotification({
            isOpen:  true,
            type:    'success',
            message: 'Your answer is posted!',
          })
        })
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
        })
        .finally(() => {
          this.replying.isLoading = false
        })
    },
    votePost (postId, voteType) {
      const route = (postId === this.discussionId) ?
        `/discussions/${this.discussionId}/votes` :
        `/discussions/${this.discussionId}/replies/${postId}/votes`

      this.isVoting = postId
      axios.patch(route, {
        voteType: this.userVote(postId) === voteType ? '' : voteType,
      })
        .then(res => {
          this.posts[postId].votes = res.data.votes
        })
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
        })
        .finally(() => {
          this.isVoting = ''
        })
    },
    editPost (postId) {
      this.editing.postId = postId
      this.editing.content = this.posts[postId].content
    },
    updatePost () {
      this.editing.isLoading = true
      axios.put(`/discussions/${this.discussionId}/replies/${this.editing.postId}`, {
        content: this.editing.content,
      })
        .then(res => {
          this.posts[this.editing.postId].content = res.data.content
          this.editing.postId = ''
          this.formDirty = false

          this.setNotification({
            isOpen:  true,
            type:    'success',
            message: 'Your post is updated',
          })
        })
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
        })
        .finally(() => {
          this.editing.isLoading = false
        })
    },
    removePost () {
      const route = (this.deleting.postId === this.discussionId) ?
        `/discussions/${this.discussionId}` :
        `/discussions/${this.discussionId}/replies/${this.deleting.postId}`

      this.deleting.isLoading = true
      axios.delete(route)
        .then(() => {
          if(this.deleting.postId === this.discussionId) {
            this.$router.push('/discussion').catch(()=>{})
          }
          else {
            const replyIndex = this.replyIds.findIndex(id => id === this.deleting.postId)
            this.replyIds.splice(replyIndex, 1)
            delete this.posts[this.deleting.postId]
          }
          this.deleting.postId = ''
          this.setNotification({
            isOpen:  true,
            type:    'success',
            message: 'Your post is deleted',
          })
        })
        .catch(error => {
          this.setNotification({
            isOpen:  true,
            type:    'error',
            message: error.response.data,
          })
        })
        .finally(() => {
          this.deleting.isLoading = false
        })
    },
  },
  beforeRouteEnter (to, _, next) {
    store.commit('feature/setPageLoading', true)
    axios.get(`/discussions/${to.params.id}`)
      .then(res => {
        const post = res.data

        to.meta.breadcrumbs = to.meta._breadcrumbs.concat([
          {
            text:     post.discussion.title,
            disabled: true,
          },
        ])
        next(vm => vm.setDiscussionPost(post, null))
      })
      .catch(error => {
        if(error.response.status === 404) {
          next('/notFound')
        }
        else {
          next(vm => vm.setDiscussionPost(null, error.response.data))
        }
      })
  },
  beforeRouteUpdate (to, _, next) {
    this.setPageLoading(true)
    axios.get(`/discussions/${to.params.id}`)
      .then(res => {
        const post = res.data
        to.meta.breadcrumbs = to.meta._breadcrumbs.concat([
          {
            text:     post.discussion.title,
            disabled: true,
          },
        ])
        this.setDiscussionPost(post, null)
        next()
      })
      .catch(error => {
        if(error.response.status === 404) {
          next('/notFound')
        }
        else {
          this.setDiscussionPost(null, error.response.data)
          next()
        }
      })
  },
  beforeRouteLeave (to, from, next) {
    if(this.formDirty && !this.confirmRouteChange()) {
      next(false)
    }
    else {
      next()
    }
  },
}
</script>
