<template lang="pug">
v-textarea.text-editor(
  outlined
  rows="7"
  :label="label"
  :value="value"
  :rules="activeRules"
  @input="change")
</template>

<script>
export default {
  name:  'TextEditor',
  props: {
    value: {
      type:     String,
      required: true,
      default:  '',
    },
    label: {
      type:     String,
      required: false,
      default:  '',
    },
    required: {
      type:     Boolean,
      required: false,
      default:  false,
    },
    rules: {
      type:     Array,
      required: false,
      default:  () => [],
    },
  },
  data () {
    return {
      validations: {
        required: value => !!value || 'Required',
      },
    }
  },
  computed: {
    activeRules () {
      let rules = []
      if(this.required) {
        rules.push(this.validations.required)
      }
      rules = rules.concat(this.rules)
      return rules
    },
  },
  methods: {
    change (value) {
      this.$emit('input', value)
    },
  },
}
</script>
