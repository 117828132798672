<template lang="pug">
v-btn.white-button.px-4.py-1.text-subtitle-1(
  rounded
  color="#F9F9F9"
  :to="to"
  :loading="isWaiting"
  :disabled="isWaiting"
  @click="$emit('click', $event)")
  slot
</template>

<script>
export default {
  name:  'WhiteButton',
  props: {
    isWaiting: {
      type:     Boolean,
      required: false,
      default:  false,
    },
    to: {
      type:     String,
      required: false,
      default:  null,
    },
  },
}
</script>

<style lang="scss" scoped>
.white-button {
  color: #30333F;
  font-size: 16px;
  text-transform: none !important;
}
</style>
