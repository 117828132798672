<template lang="pug">
v-text-field(
  outlined
  :label="label"
  :value="value"
  :type="password ? 'password' : 'text'"
  :rules="activeRules"
  @input="change")
</template>

<script>
export default {
  name:  'TextField',
  props: {
    value: {
      type:     [String, Number],
      required: true,
      default:  '',
    },
    label: {
      type:     String,
      required: false,
      default:  '',
    },
    password: {
      type:     Boolean,
      required: false,
      default:  false,
    },
    required: {
      type:     Boolean,
      required: false,
      default:  false,
    },
    maxLength: {
      type:     Number,
      required: false,
      default:  0,
    },
    rules: {
      type:     Array,
      required: false,
      default:  () => [],
    },
  },
  data () {
    return {
      validations: {
        required:  value => !!value || 'Required',
        maxLength: value => (value.length <= this.maxLength) || `Input text cannot exceed ${this.maxLength} characters`,
      },
    }
  },
  computed: {
    activeRules () {
      let rules = []
      if(this.required) {
        rules.push(this.validations.required)
      }
      if(this.maxLength) {
        rules.push(this.validations.maxLength)
      }
      rules = rules.concat(this.rules)
      return rules
    },
  },
  methods: {
    change (value) {
      this.$emit('input', value)
    },
  },
}
</script>
