<template lang="pug">
authentication-layout
  v-tabs(v-model="tab" centered slider-size="6" color="#FFD06F" background-color="#1F2327")
    v-tabs-slider.rounded
    v-tab.mr-7.mr-md-12.px-0.pb-4.text-h5.text-md-h4.font-weight-medium.text-capitalize(href="#signin" :ripple="false") Sign in
    v-tab.ml-7.ml-md-12.px-0.pb-4.text-h5.text-md-h4.font-weight-medium.text-capitalize(href="#signup" :ripple="false") Sign up
    v-tabs-items.full-height-tab(v-model="tab")
      v-tab-item(value="signin")
        signin-form
      v-tab-item(value="signup")
        signup-form(@success="onSignupSuccess" @fail="onSignupFail")
</template>

<script>
import { mapMutations } from 'vuex'
import AuthenticationLayout from '@/layouts/AuthenticationLayout.vue'
import SigninForm from '@/components/authentication/SigninForm.vue'
import SignupForm from '@/components/authentication/SignupForm.vue'

export default {
  components: {
    AuthenticationLayout,
    SigninForm,
    SignupForm,
  },
  data () {
    return {
      tab: 'signin',
    }
  },
  methods: {
    ...mapMutations('feature', ['setPopup']),
    onSignupSuccess (response) {
    // Show message popup to hint user to check e-mail
      this.setPopup({
        isOpen:  true,
        type:    'message',
        title:   'Sign up succeeded',
        content: response,
      })
      // Change current tab to sign in tab
      this.tab = 0
    },
    onSignupFail (error) {
      // Show message popup to display error message
      this.setPopup({
        isOpen:  true,
        type:    'error',
        title:   'Sign up fail',
        content: error,
      })
    },
  },
  mounted () {
    // Show hint message if user is come from activation link
    if(this.$route.query.activate) {
      this.setPopup({
        isOpen:  true,
        type:    'message',
        title:   'Account activated',
        content: 'Your account is activated successfully, please sign in now.',
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.v-tab::before {
  background-color: inherit !important;
}
.v-tabs-items.full-height-tab .v-window-item {
  height: 540px; //might need to change this in the future
}
.v-tabs-items.full-height-tab {
  background-color: #1F2327;
  max-width: 360px;
}
</style>
